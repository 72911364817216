import { Box, Modal, Button, Typography } from "@mui/material"
import { styled } from '@mui/material/styles';
import { IResponseCurrentUser } from 'interfaces';
import { formatUTCTime } from 'lib/common/date.helper'
import AvatarDefault from 'components/utils/AvatarDefault'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 10
};
const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center',
});

interface ModalShowUserProps {
    open: boolean
    data?: IResponseCurrentUser['data']
    setOpen: (e: boolean) => void
}
const ModalShowUser = ({ open, data, setOpen }: ModalShowUserProps) => {
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <BoxWrapper>
                    <AvatarDefault width={240} height={240} src={data?.image} text={data?.nickname?.charAt(0)} />
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                        ID{data?.id} {data?.nickname}
                    </Typography>
                    <Typography id="modal-modal-description" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                        権限：{data?.roles?.map(role => role === 'admin' ? '管理者' : role === 'normal' ? '一般ユーザー' : role === 'wash_bottle' ? '洗浄機' : '')?.join(', ')}
                    </Typography>
                    <Typography id="modal-modal-description" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                        登録日：{formatUTCTime(data?.createdAt || '')}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                        <Button size="large" variant='contained' onClick={() => {
                            setOpen(false)
                        }}>閉じる</Button>
                    </Box>
                </BoxWrapper>
            </Box>
        </Modal>)
}

export default ModalShowUser
import { ChangeEvent, useEffect, useState } from 'react'
import { Box, CardContent, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, Snackbar, Alert, AlertProps, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import { styled } from '@mui/material/styles';
import { IResponseRoles, IRole, SignUpParamsAdmin } from 'interfaces'
import { AppUrl } from 'components/utils/constants/AppUrl'
import { useNavigate } from "react-router-dom"
import { useAdminGetRoles, useAdminSignUp } from "lib/api/admin"

const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center',
    width: '100%',
    margin: '0px auto',
});
const SignSubmitButton = styled(Button)({
    marginTop: 5,
    flexGrow: 1,
    textTransform: "none"
});

const FormControlCustom = styled(FormControl)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: 'auto',
    },
}));


const UserManagementSignUp = () => {
    const navigate = useNavigate()
    const [signUpData, setSignUpData] = useState<SignUpParamsAdmin>({
        email: '',
        customer_id: '',
        password: '',
        nickname: '',
        imageUrl: '',
        passwordConfirmation: '',
        role: '',
    })
    const [showMessage, setShowMessage] = useState<{
        message: string
        isOpen: boolean
        type: AlertProps['severity']
    }>({
        message: '',
        isOpen: false,
        type: 'success'
    })
    const { data } = useAdminGetRoles()

    const [userProviderType, setUserProviderType] = useState<IResponseRoles['userProviderType']>()

    const handleSignUp = useAdminSignUp(() => {
        setShowMessage({ isOpen: true, message: 'アカウント登録が成功しました', type: 'success' })
        setUserProviderType(data?.data?.userProviderType || 'email')
        navigate(AppUrl.USER_MANAGEMENT)
    }, (err) => {
        setShowMessage({ isOpen: true, message: (err?.response?.data?.errors as any)?.fullMessages || 'エラー', type: 'error' })
    })
    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const customeData: SignUpParamsAdmin = {
            ...signUpData,
            email: userProviderType === 'email' ? signUpData.email : undefined,
            customer_id: userProviderType === 'customer_id' ? signUpData.customer_id : undefined,
        }
        handleSignUp.mutate(customeData)
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSignUpData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if(data?.data?.userProviderType){
            setUserProviderType(data?.data?.userProviderType)
        }
    }, [data])
    return (
        <Box>
            <Typography sx={{ fontSize: 20, color: '#1976D2', fontWeight: 'bold', textAlign: 'left' }}>
                ユーザー登録
            </Typography>
            <BoxWrapper>
                <CardContent>
                    <FormControlCustom>
                        <RadioGroup
                            value={userProviderType ?? ''}
                            name="userProviderType"
                            onChange={(e) => setUserProviderType(e.target.value as any)}
                            row
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <FormControlLabel 
                                value='email'
                                control={
                                    <Radio 
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 25,
                                            },
                                        }}
                                    />
                                }
                                label="メール"
                                componentsProps={{
                                    typography: {
                                        fontSize: 20
                                    }
                                }}
                            />
                            <FormControlLabel
                                value='customer_id'
                                control={
                                    <Radio 
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 25,
                                            },
                                        }}
                                    />
                                }
                                label="ログインID"
                                componentsProps={{
                                    typography: {
                                        fontSize: 20
                                    }
                                }}
                            />
                        </RadioGroup>
                    </FormControlCustom>
                    <FormControl fullWidth sx={{ marginTop: 1 }}>
                        <InputLabel id="role">権限</InputLabel>
                        <Select
                            labelId="role"
                            label='権限'
                            value={signUpData.role}
                            sx={{ width: '100%'}}
                            onChange={(e) => {
                                setSignUpData({ ...signUpData, role: e.target.value as SignUpParamsAdmin['role'] })
                            }}
                            required
                        >
                            {
                                data?.data?.roles?.map((role: IRole) => (
                                    <MenuItem value={role.code} key={role.id}>
                                        {role.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        name={userProviderType}
                        variant="outlined"
                        required
                        fullWidth
                        label={ userProviderType === 'email' ? 'メールアドレス' : userProviderType === 'customer_id' ? 'ログインID' : '' }
                        value={ userProviderType === 'email' ? signUpData.email : userProviderType === 'customer_id' ? signUpData.customer_id : ''}                        margin="dense"
                        onChange={handleChange}
                        defaultValue={data?.data?.userProviderType}
                    />
                    <TextField
                        name="password"
                        variant="outlined"
                        required
                        fullWidth
                        label="パスワード"
                        type="password"
                        value={signUpData.password}
                        margin="dense"
                        autoComplete="off"
                        onChange={handleChange}
                    />
                    <TextField
                        name="passwordConfirmation"
                        variant="outlined"
                        required
                        fullWidth
                        label="パスワード確認"
                        type="password"
                        value={signUpData.passwordConfirmation}
                        margin="dense"
                        autoComplete="off"
                        onChange={handleChange}
                    />
                    <TextField
                        name="nickname"
                        variant="outlined"
                        required
                        fullWidth
                        label="ニックネーム"
                        value={signUpData.nickname}
                        inputProps={{ maxLength: 10 }}
                        margin="dense"
                        onChange={handleChange}
                    />
                    <small>※ ニックネームはあとから変更することはできません</small>
                    <BoxWrapper>
                        <SignSubmitButton
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={handleSignUp.isPending || !signUpData.nickname || (userProviderType === 'customer_id' && !signUpData.customer_id) || (userProviderType === 'email' && !signUpData.email) || !signUpData.password || !signUpData.passwordConfirmation}
                            onClick={handleSubmit}
                        >
                            送信
                        </SignSubmitButton>
                    </BoxWrapper>
                </CardContent>
            </BoxWrapper>
            {
                showMessage.isOpen && (
                    <Snackbar
                        open={showMessage.isOpen}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        onClose={() => setShowMessage({
                            isOpen: false,
                            message: '',
                            type: 'success'
                        })}
                    >
                        <Alert 
                            onClose={() => setShowMessage({
                                isOpen: false,
                                message: '',
                                type: 'success'
                            })}
                            severity={showMessage.type}
                            sx={{
                                '& .MuiAlert-icon': {
                                    margin: 'auto',
                                    marginRight: 1
                                }
                            }}
                        >
                            {typeof showMessage.message === 'string' ? showMessage.message : (showMessage.message as string[])?.map((msg, index) => <p key={msg+index}>{msg}</p>)}
                        </Alert>
                    </Snackbar>
                )
            }
        </Box>
    )
}

export default UserManagementSignUp
import { Box, CircularProgress } from '@mui/material';

const CircularProgressBox = () => {
    return (
        <Box sx={{ flex: 1, display: 'grid', direction: 'column', justifyContent: 'center', alignItems: 'center', height: "100%" }}>
            <CircularProgress />
        </Box>
    )
}

export default CircularProgressBox
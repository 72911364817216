import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatNumber } from "lib/common/string.helper";
import CardLayoutComponent from "components/utils/CardLayoutComponent";
import { useGetCountUserAndCleanBottle } from "lib/api/dashboard";

export const BoxItemWrapper = styled(Box)({
	backgroundColor: "white",
	padding: 2,
	borderRadius: 15,
	display: "flex",
	alignItems: "center",
	flexDirection: "column",
	height: "100%",
});

const TypographyDescription = styled(Typography)({
	fontSize: 32,
	fontWeight: "bold",
});

const DashboardDataCount = () => {
	const { data } = useGetCountUserAndCleanBottle();
	return (
		<Box
			sx={{
				display: "grid",
				gap: 2,
				height: "100%",
			}}
		>
			<CardLayoutComponent title="総ユーザー数">
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#0A97D9",
						height: "100%",
						border: "1px black solid",
					}}
				>
					<TypographyDescription sx={{ color: "white" }}>
						{formatNumber(data?.usersCount || 0)}人
					</TypographyDescription>
				</Box>
			</CardLayoutComponent>
			<CardLayoutComponent title="総洗浄回数" secondary>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#21BECE",
						height: "100%",
						border: "1px black solid",
					}}
				>
					<TypographyDescription sx={{ color: "white" }}>
						{formatNumber(data?.washedCount || 0)}回
					</TypographyDescription>
				</Box>
			</CardLayoutComponent>
		</Box>
	);
};

export default DashboardDataCount;

import { useQuery } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import { BaseResponse, IAttributeDetail } from "interfaces";

export const useGetAttributeDetail = () => {
	return useQuery({
		queryKey: ["GET_ATTRIBUTE_DETAIL"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get("attributes/detail");
			return res as BaseResponse<IAttributeDetail>;
		},
	});
};
import { ChangeEvent } from 'react';

export const cleanEmpty = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj
      .map(item => cleanEmpty(item))
      .filter(item => item && (typeof item !== 'object' || Object.keys(item).length > 0));
  } else if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      obj[key] = cleanEmpty(obj[key]);
      if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          delete obj[key];
      }
    });
    return obj;
  }
  return obj;
};

export const handleChangeStateInput = (setState: React.Dispatch<React.SetStateAction<any>>) =>
  (e: ChangeEvent<HTMLInputElement>, customValue?: string | number) => {
    const { name, value: inputValue } = e.target;
    const value = customValue !== undefined ? customValue : inputValue;
    const nameParts = name.split('.');

    if (nameParts.length === 1) {
      setState((prevState: any) => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setState((prevState: any) => {
        const updatedState = { ...prevState };
        let currentLevel = updatedState;

        for (let i = 0; i < nameParts.length - 1; i++) {
          const part = nameParts[i];
          if (Array.isArray(currentLevel[part])) {
            const index = parseInt(nameParts[i + 1], 10);
            currentLevel = currentLevel[part][index] = { ...currentLevel[part][index] };
            i++;
          } else {
            currentLevel = currentLevel[part] = { ...currentLevel[part] };
          }
        }

        const lastPart = nameParts[nameParts.length - 1];
        currentLevel[lastPart] = value;
        return updatedState;
      });
    }
};

export const handleChangeStateSelect = (setState: React.Dispatch<React.SetStateAction<any>>) => 
  (name: string, value: string | number | undefined) => {
    const nameParts = name.split('.');
    if (nameParts.length === 1) {
      setState((prevState: any) => {
        const updatedState = { ...prevState };
      
        if (typeof name === 'object') {
          for (const key in name as any) {
            delete updatedState[key];
          }
        } else if (typeof name === 'string') {
          if (Array.isArray(updatedState[name])) {
            updatedState[name] = [];
          } else {
            if (value !== undefined) {
              updatedState[name] = value;
            } else {
              updatedState[name] = undefined;
            }
          }
        }
      
        return cleanEmpty(updatedState);
      });
    } else {
      setState((prevState: any) => {
        const updatedState = { ...prevState };
        let currentLevel = updatedState;
        for (let i = 0; i < nameParts.length - 1; i++) {
          const part = nameParts[i];
          if (Array.isArray(currentLevel[part])) {
            const index = parseInt(nameParts[i + 1], 10);
            currentLevel = currentLevel[part][index] = { ...currentLevel[part][index] };
            i++;
          } else {
            currentLevel = currentLevel[part] = { ...currentLevel[part] };
          }
        }

        const lastPart = nameParts[nameParts.length - 1];
        if (value !== undefined) {
          currentLevel[lastPart] = value;
        } else {
          delete currentLevel[lastPart];
        }

        return cleanEmpty(updatedState);
      });
    }
}
  
export const handleChangeStateImage = (setState: React.Dispatch<React.SetStateAction<any>>) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = function() {
        const nameParts = name.split('.');
        const baseName = nameParts.slice(0, nameParts.length - 1).join('.');
        const valueKey = `${baseName}.value`;
        const mimeKey = `${baseName}.mime`;
        const nameKey = `${baseName}.name`;

        setState((prevState: any) => {
          const updatedState = { ...prevState };
          const setNestedState = (state: any, keys: string[], value: any) => {
            const lastKey = keys.pop();
            let currentLevel = state;
            keys.forEach((key) => {
              if (Array.isArray(currentLevel[key])) {
                const index = parseInt(keys[1], 10);
                currentLevel = currentLevel[key][index] = { ...currentLevel[key][index] };
                keys.shift();
              } else {
                currentLevel = currentLevel[key] = { ...currentLevel[key] };
              }
            });

            if (lastKey) {
              currentLevel[lastKey] = value;
            }
          };

          setNestedState(updatedState, valueKey.split('.'), reader.result);
          setNestedState(updatedState, mimeKey.split('.'), file.type);
          setNestedState(updatedState, nameKey.split('.'), file.name);
          return updatedState;
        });
      };

      reader.readAsDataURL(file);
    } else {
      console.log("No image found");
    }
};

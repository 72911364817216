import { axiosInstanceAuth } from "./client"
import { useMutation, useQuery } from "@tanstack/react-query"
import { BaseResponse, User } from "interfaces"

export const useGetUserImage = (id: number) => {
    return useQuery({
      queryKey: ['GET IMAGE USER'],
      queryFn: async () => {
        const res = await axiosInstanceAuth.get(`/users/${id}`);
        return res as BaseResponse<User>;
      },
      enabled: !!id
    });
  };
  
  
export const useUpdateImageUser = (onOk?: Function, onErr?: Function) => {
  return useMutation({
    mutationKey: ['UPDATE IMAGE USER'],
    mutationFn: ({ id, imageUrl }: { id: number, imageUrl: string }) =>  axiosInstanceAuth.put(`/users/${id}`, {
      user: {
        imageUrl
      }
    }),
    onSuccess: (res) => {
      onOk && onOk(res)
    },
    onError: (err) => {
        onErr && onErr(err)
    }
  })
} 
export const useDeleteCurrentUser = (onOk?: Function, onErr?: Function) => {
  return useMutation({
    mutationKey: ['DELETE_CURRENT_USER'],
    mutationFn: (id: number) =>  axiosInstanceAuth.delete(`/users/${id}`),
    onSuccess: (res) => {
      onOk && onOk(res)
    },
    onError: (err) => {
        onErr && onErr(err)
    }
  })
}
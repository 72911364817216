import React, { SVGProps } from "react";

const GroupChattingIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			id="_レイヤー_1"
			data-name="レイヤー 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 48.54 48.54"
			{...props}
		>
			<defs>
				<style>
					{`.cls-1 {
            fill: none;
          }
          .cls-1, .cls-2 {
            stroke-width: 0px;
          }
          .cls-2 {
            fill: #ea5a74;
          }`}
				</style>
			</defs>
			<rect
				className="cls-1"
				x="0"
				width="48.54"
				height="48.54"
				rx="14.97"
				ry="14.97"
			/>
			<path
				className="cls-2"
				d="m24.27,16.24c-12.6-12.74-29.18,11.02,0,24.11,29.18-13.09,12.6-36.85,0-24.11Z"
			/>
		</svg>
	);
};

export default GroupChattingIcon;

import { useState } from "react";
import {
	Box,
	Modal,
	Button,
	Typography,
	IconButton,
	Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { IResponseCurrentUser } from "interfaces";
import { useChangePasswordUser } from "lib/api/admin";
import { formatUTCTime } from "lib/common/date.helper";
import AvatarDefault from "components/utils/AvatarDefault";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKey } from "./constants/QueryKey";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AlertMessage from "./AlertMessage";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 500,
	bgcolor: "background.paper",
	border: "1px solid #000",
	boxShadow: 24,
	p: 4,
	borderRadius: 10,
	maxWidth: "100%",
};
const BoxWrapper = styled(Box)({
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	gap: 5,
	alignItems: "center",
	margin: "0 20px",
});
interface ModalChangePasswordUserProps {
	open: boolean;
	data?: IResponseCurrentUser["data"];
	onCancel: () => void;
	onOk: Function;
}
const ModalChangePasswordUser = ({
	open,
	data,
	onCancel,
	onOk,
}: ModalChangePasswordUserProps) => {
	const queryClient = useQueryClient();
	const user = queryClient.getQueryData([
		QueryKey.GET_AUTH_USER,
	]) as IResponseCurrentUser;
	const [isCopied, setIsCopied] = useState(false);
	const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({ isOpen: false, message: "", type: "success" });

	const handleChangePassword = useChangePasswordUser(
		() => {},
		(err: any) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.errors || "エラー",
				type: "error",
			});
		}
	);
	const newPassword =
		(handleChangePassword?.data as { newPassword: string })?.newPassword || "";

	return (
		<Modal
			open={open}
			onClose={() => onCancel()}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<BoxWrapper>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
						sx={{ textAlign: "center" }}
					>
						{handleChangePassword?.isSuccess
							? "パスワードが再発行されました。"
							: "以下のユーザーのパスワードを再発行します。"}
					</Typography>
					<Typography variant="h6" component="h2" sx={{ textAlign: "center" }}>
						{handleChangePassword?.isSuccess
							? "ユーザーに通知してください。"
							: "現在のパスワードは利用できなくなります。"}
					</Typography>
					<AvatarDefault
						width={240}
						height={240}
						src={data?.image}
						text={data?.nickname?.charAt(0)}
					/>

					<Typography
						id="modal-modal-description"
						variant="h6"
						component="h2"
						sx={{ textAlign: "center" }}
					>
						ID:{" "}
						{user?.userProviderType === "email"
							? data?.email
							: data?.customerId}
					</Typography>
					<Typography variant="h6" component="h2" sx={{ textAlign: "center" }}>
						権限：
						{data?.roles
							?.map((role) =>
								role === "admin"
									? "管理者"
									: role === "normal"
									? "一般ユーザー"
									: role === "wash_bottle"
									? "洗浄機"
									: ""
							)
							?.join(", ")}
					</Typography>
					<Typography variant="h6" component="h2" sx={{ textAlign: "center" }}>
						登録日：{formatUTCTime(data?.createdAt || "")}
					</Typography>
					{newPassword && (
						<Typography
							variant="h6"
							component="h2"
							sx={{ textAlign: "center" }}
						>
							[ {newPassword} ]
							<Tooltip
								title={isCopied ? "クリップボードにコピーされました。" : "クリップボードにコピーする"}
								placement="right"
							>
								<IconButton
									aria-label="add"
									size="large"
									onClick={() => {
										navigator.clipboard.writeText(newPassword);
										setIsCopied(true);
									}}
									onMouseLeave={() => setIsCopied(false)}
								>
									<ContentCopyIcon
										color={isCopied ? "primary" : undefined}
										sx={{ width: 15, height: 15 }}
									/>
								</IconButton>
							</Tooltip>
						</Typography>
					)}
					<Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
						<Button
							size="large"
							variant="contained"
							onClick={() => {
								if (newPassword) {
                                    handleChangePassword.reset()
									onOk();
								} else data?.id && handleChangePassword.mutate(data?.id);
							}}
						>
							{newPassword ? "閉じる" : "再発行"}
						</Button>
					</Box>
				</BoxWrapper>
				<AlertMessage
					open={modalAlert.isOpen}
					setOpen={(open: boolean) =>
						setModalAlert({ isOpen: open, message: "", type: "warning" })
					}
					severity={modalAlert.type}
					message={modalAlert.message}
				/>
			</Box>
		</Modal>
	);
};

export default ModalChangePasswordUser;

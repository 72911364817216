import { useQuery } from '@tanstack/react-query';
import { BaseResponse, IEventHome, IListEvent, IEventDetail } from "interfaces"
import { axiosInstanceAuth } from "./client"

export const useGetEvents = (userId?: number, intervalSec?: number) => {
    let intervalMs = 0
    if (intervalSec) {
        intervalMs = intervalSec * 1000
    }
    return useQuery({
        queryKey: ['GET EVENTS', userId || ''],
        queryFn: async () => {
            const res = await axiosInstanceAuth.get(`/events`) as BaseResponse<IEventHome[]>
            return res
        },
        refetchInterval: intervalMs,
        staleTime: 2 * 6 * 1000,
        enabled: !!userId
    })
}

export const useGetListEvent = (params: { page: number; per: number }) => {
	return useQuery({
		queryKey: ["GET_LIST_EVENTS", params.page, params.per],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				`/events/filtering?page=${params.page}&per=${params.per}`
			);
			return res as BaseResponse<IListEvent>;
		},
		enabled: !!params.page && !!params.per,
	});
};

export const useGetDetailEvent = (id: number) => {
	return useQuery({
		queryKey: ["GET DETAIL EVENTS", id],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(`/events/${id}`);
			return res as BaseResponse<IEventDetail>;
		},
		enabled: !!id,
	});
};

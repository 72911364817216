import { Box, Typography, Card, CardHeader, CardContent, InputAdornment, FormControl, Select, MenuItem, Pagination } from '@mui/material'
import React from 'react'
import { AppUrl } from 'components/utils/constants/AppUrl'
import { useGetListCoupon } from '../../../lib/api/coupon';
import dayjs from 'dayjs'
import CircularProgressBox from 'components/utils/CircularProgressBox'

import { styled } from '@mui/material/styles';
import SafeArea from '../../layouts/SafeArea'
import { useNavigate, useSearchParams } from 'react-router-dom';

const BoxCoupon = styled(Box)({
    display: 'flex',
    gap: 20,
});
const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    gap: 20
});
const BoxCouponButton = styled(Box)({
    margin: 'auto',
    padding: 22,
    color: '#007fc6',
    border: '1px solid black',
    backgroundColor: '#fff034',
    fontSize: 26,
    fontWeight: 800,
    minWidth: 'max-content',
    borderLeft: '8px solid #007fc6'
})
const BoxCouponExpiredButton = styled(Box)({
    margin: 'auto',
    padding: 22,
    color: 'white',
    border: '1px solid black',
    backgroundColor: '#AFABAB',
    fontSize: 26,
    fontWeight: 800,
    minWidth: 'max-content'
})
const BoxCoupons = styled(Box)({
    display: "flex",
    flexDirection: 'column',
    gap: 5,
    width: '100%',
    margin: '10px 0px',
    backgroundColor: '#c1e2f6',
    padding: '1rem 1rem 0.4rem 1rem',
});

const Coupon: React.FC = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const page: number = Number(searchParams.get('page')) || 1;
    const per: number = Number(searchParams.get('per')) || 5;
    const { data, isLoading, isError } = useGetListCoupon({ page, per })

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString());
        setSearchParams(searchParams);
    };
    return (
        <>
            {
                isLoading ? (
                    <CircularProgressBox />
                ) : !isError ? (
                    <>
                        {
                            data?.data?.data?.length ? (
                                <>
                                    <Box display="flex" justifyContent="flex-end">
                                        <FormControl sx={{ width: "140px", marginLeft: "auto", backgroundColor: '#ffffff' }}>
                                            <Select
                                                value={per}
                                                onChange={(e) => {
                                                    searchParams.set("per", e.target.value.toString());
                                                    searchParams.set("page", '1');
                                                    setSearchParams(searchParams);
                                                }}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Typography variant="inherit" style={{ fontSize: '0.8rem' }}>
                                                            表示件数
                                                        </Typography>

                                                    </InputAdornment>
                                                }
                                            >
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={15}>15</MenuItem>
                                                <MenuItem value={25}>25</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <BoxWrapper>
                                        <BoxCoupons>
                                            {
                                                data?.data?.data?.map((coupon) => (
                                                    <BoxCoupon key={coupon.id}>
                                                        {
                                                            coupon.isUsed ? (
                                                                <BoxCouponExpiredButton>使用済​</BoxCouponExpiredButton>
                                                            ) : coupon.isExpired ? (
                                                                <BoxCouponExpiredButton>期限切​</BoxCouponExpiredButton>
                                                            ) : (
                                                                <BoxCouponButton>未使用​</BoxCouponButton>
                                                            )
                                                        }
                                                        <Box sx={{ flex: 1}}>
                                                            <Typography sx={{ fontSize: "0.8rem" }}>取得日：{dayjs(coupon.createdAt).format('YYYY/MM/DD')}​</Typography>
                                                            <Typography sx={{ fontSize: "0.8rem" }}>利用期限：{dayjs(coupon.expirationDate).format('YYYY/MM/DD')}​​</Typography>
                                                            <Typography sx={{
                                                                textDecoration: 'underline',
                                                                cursor: 'pointer'
                                                            }} onClick={() => {
                                                                navigate(AppUrl.COUPON + '/' + coupon.id)
                                                            }}>{coupon.description}​</Typography>
                                                        </Box>
                                                    </BoxCoupon>
                                                ))
                                            }
                                        </BoxCoupons>
                                        <Pagination page={Number(page)} count={data?.data?.totalPages} onChange={handleChangePage} siblingCount={1} boundaryCount={0}/>
                                    </BoxWrapper>
                                </>
                            ) : (
                                <Box sx={{ margin: '50px 0px' }}>
                                    <Typography sx={{ textAlign: 'center' }}>利用可能なクーポンはありません​</Typography>
                                </Box>
                            )
                        }

                    </>
                ) :
                    <>
                        <h1>Error</h1>
                    </>
            }
        </>
    )
}

export default Coupon
import { axiosInstanceAuth } from "./client"
import {BaseResponse } from "interfaces"
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosError } from "axios";
import { ErrorResponse } from "interfaces";

export const useGetUserTag = () => {
    return useQuery({
        queryKey: ['GET USER TAG'],
        queryFn: async () =>{
            const res = await axiosInstanceAuth.get("/tags")
            return res as BaseResponse<{
                code: string
            }>
        }
    })
}
export const useGetWashCount = () => {
    return useQuery({
        queryKey: ['GET USER WASHED COUNT'],
        queryFn: async () =>{
            const res = await axiosInstanceAuth.get("/user_asset_location_logs/washed_count")
            return res as BaseResponse<number>
        }
    })
}
export const useCreateUserTagMutation = (onOk?: Function , onErr?:Function) => {
    return useMutation({
        mutationKey: ['CREATE USER TAG'],
        mutationFn: (code: string) => axiosInstanceAuth.post("/tags" , {code}),
        onSuccess: (res) => {
            onOk && onOk(res)
        },
        onError: (err: AxiosError<ErrorResponse>) => {
            onErr && onErr(err)
        }
    })
}
export const useUpdateUserTagMutation = (onOk?: Function , onErr?:Function) => {
    return useMutation({
        mutationKey: ['UPDATE USER TAG'],
        mutationFn: (code: string) => axiosInstanceAuth.put("/tags" , {code}),
        onSuccess: (res) => {
            onOk && onOk(res)
        },
        onError: (err: AxiosError<ErrorResponse>) => {
            onErr && onErr(err)
        }
    })
}
import { Box, Grid, Typography } from "@mui/material";
import DashboardUserCount from "./DashboardDataCount";
import DashboardWashedHistory from "./DashboardWashedHistory";
import DashboardWeekCount from "./DashboardWeekCount";
import DashboardDataChart from "./DashboardDataChart";
import DashboardCoupon from "./DashboardCoupon";
import DashboardTimeCount from "./DashboardTimeCount";
import dayjs from "dayjs";

const Dashboard = () => {
	return (
		<Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
			<Box
				sx={{ display: "flex", justifyContent: "space-between", paddingTop: 2 }}
			>
				<Typography sx={{ fontSize: 28, fontWeight: "bold" }}>
					ダッシュボード
				</Typography>
				<Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
					{dayjs().subtract(30, 'day').format('YYYY/MM/DD')}～{dayjs().subtract(1, 'day').format('YYYY/MM/DD')}
				</Typography>
			</Box>
			<Grid container columnSpacing={2} alignItems="stretch">
				<Grid item xs={2}>
					<DashboardUserCount />
				</Grid>
				<Grid item xs={10}>
					<DashboardDataChart />
				</Grid>
			</Grid>
			<Grid container columnSpacing={2} alignItems="stretch">
				<Grid item xs={5}>
					<DashboardWeekCount />
				</Grid>
				<Grid item xs={7}>
					<DashboardTimeCount />
				</Grid>
			</Grid>
			<Grid container columnSpacing={2} alignItems="stretch">
				<Grid item xs={4}>
					<DashboardCoupon />
				</Grid>
				<Grid item xs={8}>
					<DashboardWashedHistory />
				</Grid>
			</Grid>
		</Box>
	);
};

export default Dashboard;

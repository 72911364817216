import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const StyledDialogContent = styled(DialogContent)({
  padding: 0,
  margin: 0,
  width: '100%',
  height: '100%',
  position: 'relative',
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.common.white,
}));

interface ModalShowImageProps {
  open: boolean
  onClose: Function
  imageSrc: string
  imageAlt: string
}

const ModalShowImage = ({ open, onClose, imageSrc, imageAlt }: ModalShowImageProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <StyledDialogContent>
        <StyledCloseButton onClick={() => onClose()}>
          <CloseIcon />
        </StyledCloseButton>
        <StyledImage src={imageSrc} alt={imageAlt} />
      </StyledDialogContent>
    </Dialog>
  );
};

export default ModalShowImage;

import { useQuery } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import { BaseResponse, IImage } from "interfaces";

export const useGetImageByType = (type: string) => {
	return useQuery({
		queryKey: ["GET IMAGE BY TYPE", type],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				`/images/show_by_type?type=${type}`
			);
			return res as BaseResponse<IImage>;
		},
		enabled: !!type,
	});
};

export const useGetImageByStage = ({ stage, sustainabilityId }: { stage: number, sustainabilityId: number}) => {
	return useQuery({
		queryKey: ["GET IMAGE BY STAGE", stage, "SUSTAINABILITY_ID", sustainabilityId],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				`/images/show_by_stage?stage=${stage}&sustainability_id=${sustainabilityId}`
			);
			return res as BaseResponse<IImage>;
		},
		enabled: stage !== null && !!sustainabilityId,
	});
};
import React, { useRef, useEffect, useState } from 'react'
import jsQR from 'jsqr'
import { Box, Button, Typography, CircularProgress, Modal, Alert, Snackbar } from "@mui/material"
import { styled } from '@mui/material/styles';
import { useGetUserByTag } from 'lib/api/clean-system';
import { BaseResponse, ErrorResponse, User } from 'interfaces';
import { AxiosError } from 'axios';

interface IModalCheckTagProps {
    onOk: (e: User) => void
    onClose: () => void
    step?: 0 | 1 | 2 | 3 | 4
    isOpen: boolean
    isLoading: boolean
    setOpenModalSignUpTag: React.Dispatch<React.SetStateAction<boolean>>
}
const BoxCamera = styled(Box)({
    position: 'relative',
    marginTop: 5,
    border: '1px solid black',
    borderRadius: 2,
    width: 300,
    height: 300
});
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
};

const CleanSystemCheckTag = ({ onOk, onClose, step, isOpen, isLoading, setOpenModalSignUpTag }: IModalCheckTagProps) =>{
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)
    const timeRecallAfterError = 2000;

    const [showMessage, setShowMessage] = useState<{
        open: boolean;
        message: string;
    }>({
        open: false,
        message: "",
    });
    const handleGetUserByTag = useGetUserByTag(
        (res: BaseResponse<User>) => {
          res?.data?.id && onOk(res?.data)
        },
        (err: AxiosError<ErrorResponse>) => {
          if(err.response?.data?.errorCode === "ERR_1125400112"){
            setOpenModalSignUpTag(true)
          } else {
            setShowMessage({
                open: true,
                message: err?.response?.data?.message || "エラー",
            });
          }
        }
    );
    useEffect(() => {
        const currentVideoRef = videoRef.current;
        const canvas = canvasRef.current
        let timeoutId: NodeJS.Timeout | null = null;

        if(isOpen){        
            const scanQrCode = () => {
                if (canvas && currentVideoRef) {
                    const ctx = canvas.getContext('2d')
                    if (ctx) {
                        // カメラの映像をcanvasに描画する
                        ctx.drawImage(currentVideoRef, 0, 0, canvas.width, canvas.height)
                        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    
                        // 画像データを取得
                        var data = imageData.data;
    
                        // シャープメソッド
                        const _data = data.slice();
                        const sharpedColor = (color: number, i: number) => {
                            // 係数
                            const sub = -1;
                            const main = 10;
    
                            const prevLine = i - (canvas.width * 4);
                            const nextLine = i + (canvas.width * 4);
    
                            const sumPrevLineColor = (_data[prevLine - 4 + color] * sub) + (_data[prevLine + color] * sub) + (_data[prevLine + 4 + color] * sub);
                            const sumCurrLineColor = (_data[i - 4 + color] * sub) + (_data[i + color] * main) + (_data[i + 4 + color] * sub);
                            const sumNextLineColor = (_data[nextLine - 4 + color] * sub) + (_data[nextLine + color] * sub) + (_data[nextLine + 4 + color] * sub);
    
                            return (sumPrevLineColor + sumCurrLineColor + sumNextLineColor) / 2
                        };
    
                        // 画像をシャープにする処理
                        for (var i = 0; i < data.length; i += 4) {
                            // 2列目〜n-1列目
                            if (i % (canvas.width * 4) === 0 || i % ((canvas.width * 4) + 300) === 0) {
                                // nop
                            } else {
                                data[i] = sharpedColor(0, i);
                                data[i + 1] = sharpedColor(1, i);
                                data[i + 2] = sharpedColor(2, i);
                            }
                        }
    
                        // 変更した画像データをCanvasに描画
                        ctx.putImageData(imageData, 0, 0);
    
                        // QRコードをスキャンする
                        const qrCodeData = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "attemptBoth" })
                        if (qrCodeData && qrCodeData.data) {
                            handleGetUserByTag.mutate(qrCodeData.data, {
                                onError: () => {
                                    timeoutId = setTimeout(scanQrCode, timeRecallAfterError)
                                }
                            });
                            return;
                        } else {
                            timeoutId = setTimeout(scanQrCode, 500)
                        }
                    }
                }
            }
            const constraints = {
                video: {
                    facingMode: "user",
                    width: { ideal: 300 },
                    height: { ideal: 300 },
                },
            }
    
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    if (currentVideoRef) {
                        currentVideoRef.srcObject = stream;
    
                        // loadedmetadata イベントを待つ関数
                        const onLoadedMetadata = () => {
                            // 再度確認してから再生
                            if (currentVideoRef) {
                                currentVideoRef.play()
                                    .then(() => {
                                        // 再生に成功したらQRコードのスキャンを開始
                                        scanQrCode();
                                    })
                                    .catch((playError) => {
                                        console.error('Error playing video:', playError);
                                    })
                                    .finally(() => {
                                        // イベントリスナーを削除
                                        currentVideoRef?.removeEventListener('loadedmetadata', onLoadedMetadata);
                                    });
                            } else {
                                console.error('Error not find video ref');
                            }
                        };
    
                        // loadedmetadata イベントリスナーを追加
                        currentVideoRef.addEventListener('loadedmetadata', onLoadedMetadata);
                    }
                })
                .catch((err) => console.error('Error accessing media devices:', err));

            // コンポーネントがアンマウントされたら、カメラのストリームを停止する
        }
        return () => {
            clearInterval(timeoutId as NodeJS.Timeout);
            if (currentVideoRef && currentVideoRef.srcObject) {
                const stream = currentVideoRef.srcObject as MediaStream
                const tracks = stream.getTracks()
                tracks.forEach((track) => track.stop())
            }
        }
    }, [isOpen])

    return (
        <Modal
            open={isOpen}
            disablePortal
        >
            <>
                <Box sx={style} tabIndex={-1}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 500 }}>{step === 1 ? 'マイボトルが自動検知出来ませんでした。' : 'マイボトルの手動認識を行います。'} </Typography>
                    <Typography sx={{ textAlign: 'center', fontWeight: 500, marginBottom: 1 }}>QRコードを読ませてください。</Typography>

                    <BoxCamera>
                        <Box display={(handleGetUserByTag.isPending || showMessage.open || isLoading) ? 'none' : 'block'}>
                            <video ref={videoRef} muted playsInline style={{ position: "absolute", left: 0, top: 0, zIndex: 50, height: "100%", width: "100%" }} />
                            <canvas ref={canvasRef} width='300' height='300' style={{ display: 'none' }} />
                        </Box>
                    </BoxCamera>
                    <Button disabled={handleGetUserByTag.isPending || showMessage.open || isLoading} size="large" sx={{ marginTop: 10, padding: '12px 24px' }} variant="contained" onClick={onClose}>
                        閉じる
                    </Button>
                    {
                        (handleGetUserByTag.isPending || showMessage.open || isLoading) && (
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px', position: 'absolute' }}>
                                <CircularProgress />
                            </Box>
                        )
                    }
                </Box>
                {
                    showMessage.open && (
                        <Snackbar
                            open={showMessage.open}
                            autoHideDuration={timeRecallAfterError}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            onClose={() =>
                                setShowMessage({
                                message: "",
                                open: false,
                                })
                            }
                        >
                            <Alert
                                onClose={() =>
                                setShowMessage({
                                    message: "",
                                    open: false,
                                })
                                }
                                severity='error'
                                sx={{
                                    "& .MuiAlert-icon": {
                                        margin: "auto",
                                        marginRight: 1,
                                    },
                                    width: '100%'
                                }}
                            >
                                <p>{showMessage.message}</p>
                            </Alert>
                        </Snackbar>
                    )
                }
            </>
        </Modal>
    )
};

export default CleanSystemCheckTag
import React, { SVGProps } from 'react'

const PlusIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="100px" height="100px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M9 12H15" stroke="#fff" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
            <path d="M12 9L12 15" stroke="#fff" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
            <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#fff" stroke-width="2" />
        </svg>
    )
}

export default PlusIcon
import { Box, Button, Typography } from "@mui/material";
import { ISurvey } from "interfaces";
import { styled } from "@mui/material/styles";
import BackIcon from "components/icon/BackIcon";
import CleanSystemFeedbackComponent from "components/utils/CleanSystemFeedbackComponent";
import { formatUTCTime } from "lib/common/date.helper";

const BoxBackHome = styled(Box)({
	position: "absolute",
	backgroundColor: "#1976D2",
	height: "70px",
	width: "100%",
	zIndex: 10,
	top: 0,
	left: 0,
	right: 0,
	display: "flex",
	alignItems: "center",
});

const BoxWrapper = styled(Box)({
	width: "100%",
	height: "100%",
	maxWidth: "900px",
	display: "flex",
	alignItems: "start",
	flexDirection: "column",
	gap: 3,
	justifyContent: "space-between",
	margin: "auto",
});

interface ICreateSurveyStep3Props {
	onSubmit: () => void;
	onCancel: () => void;
	surveyData?: ISurvey;
	isView?: boolean;
	isLoading?: boolean;
}

const CreateSurveyStep3 = ({
	surveyData,
	onSubmit,
	onCancel,
	isView,
	isLoading,
}: ICreateSurveyStep3Props) => {
	return (
		<Box>
			<Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
				「{surveyData?.name}」プレビュー
			</Typography>
			<Typography fontSize={18} align="right">
				認識コード：{surveyData?.code}、アンケート有効期限：
				{formatUTCTime(surveyData?.feedback_status_attributes?.start || "")}～
				{formatUTCTime(surveyData?.feedback_status_attributes?.end || "")}
			</Typography>
			<Box
				sx={{
					border: "1px solid black",
					borderRadius: 2,
					width: 1080,
					margin: "auto",
					marginTop: 5,
				}}
			>
				<Box
					sx={{
						borderRadius: 2,
						height: 790,
						width: 1080,
						margin: "auto",
						position: "relative",
						overflowY: "auto",
					}}
				>
					<BoxWrapper>
						<Box sx={{ width: "100%", height: "100%" }}>
							<BoxBackHome>
								<BackIcon style={{ marginLeft: 20 }} />
							</BoxBackHome>
							{surveyData && (
								<CleanSystemFeedbackComponent feedbackValue={surveyData} />
							)}
						</Box>
					</BoxWrapper>
				</Box>
			</Box>

			<Box
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
					gap: 10,
					marginTop: 4,
				}}
			>
				<Button
					variant="contained"
					sx={{
						width: 150,
						height: 70,
						fontSize: 24,
					}}
					onClick={(e) => {
						e.preventDefault();
						onCancel();
					}}
				>
					戻る
				</Button>
				{!isView && (
					<Button
						variant="contained"
						sx={{
							width: 150,
							height: 70,
							fontSize: 24,
						}}
						onClick={(e) => {
							e.preventDefault();
							onSubmit();
						}}
						disabled={isLoading || false}
					>
						送信
					</Button>
				)}
			</Box>
		</Box>
	);
};
export default CreateSurveyStep3;

import { Box, Modal, Button, Typography, Divider } from "@mui/material"
import { styled } from '@mui/material/styles';
import QRCode from "react-qr-code";
import { AppUrl, webUrl } from "./constants/AppUrl";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    py: 4,
    borderRadius: 10
};
const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center',
});

interface ModalSignUpTagProps {
    open: boolean
    onClose: Function
}
const ModalSignUpTag = ({ open, onClose }: ModalSignUpTagProps) => {
    return (
        <Modal
            open={open}
            onClose={() => onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <BoxWrapper>
                    <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ textAlign: 'center' }} color="error">
                        登録されていないタグです
                    </Typography>
                    <Divider sx={{ width: "100%", my:1 }}/>
                    <Typography id="modal-modal-description" variant="body1" component="h2" sx={{ textAlign: 'center' }}>
                        お手持ちのスマートフォンから下記のQRコードを読み込んで、<br/>アプリケーションのマイボトル機能でタグを登録してください。
                    </Typography>
                    <QRCode
                        size={256}
                        style={{ marginTop: 15 }}
                        value={webUrl + AppUrl.REGISTER_MY_BOTTLE}
                        viewBox={`0 0 256 256`}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                        <Button
                            sx={{ marginTop: 2, padding: '15px 28px' }}
                            variant="contained"
                            onClick={() => onClose()}
                        >
                            閉じる
                        </Button>
                    </Box>
                </BoxWrapper>
            </Box>
        </Modal>)
}

export default ModalSignUpTag
import React, { useContext } from "react"
import { useNavigate, Link } from "react-router-dom"
import Cookies from "js-cookie"

import { Box, ButtonProps, Menu, MenuItem } from '@mui/material';
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"

import { styled, createTheme } from '@mui/material/styles';

import { useSignOut } from "../../lib/api/auth"
import { AuthContext } from "App"
import { AppUrl } from 'components/utils/constants/AppUrl'
import { useQueryClient } from "@tanstack/react-query";
import { useGetImageByType } from "lib/api/images";

const theme = createTheme();

interface StyledTypographyProps extends ButtonProps {
  component?: React.ElementType;
  to?: string;
}

const HeaderTypography = styled(Typography)<StyledTypographyProps>({
  flexGrow: 1,
  textDecoration: "none",
  color: "inherit",
  variant: "h6"
});

const HeaderIconButton = styled(IconButton)({
  marginRight: theme.spacing(0),
});

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { isLoading, user, refetch } = useContext(AuthContext)
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data: image, isLoading: isLoadingLogo } = useGetImageByType('Logo')
  
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = useSignOut(() => {
    Cookies.remove("_access_token")
    Cookies.remove("_client")
    Cookies.remove("_uid")
    queryClient.clear()
    navigate(AppUrl.SIGN_IN)
    refetch()
  })

  const dummyUserWashedFeedbackTypeCode = 'clean-system'

  return (
    <>
      <AppBar position="static">
        {
          !isLoadingLogo && (
            <Toolbar>
              <Box
                component="img"
                src={image?.data?.value}
                sx={{ height: "35px", margin: "0 0.5rem 0 0", cursor: 'pointer' }}
                display="block"
                alt={image?.data?.name}
                onClick={() => {
                  navigate(AppUrl.HOME)
                }}
                loading="lazy"
              />
              <HeaderTypography
                component={Link}
                to={AppUrl.HOME}
              >
                My Bottle Challenge!
              </HeaderTypography>
              {
                !isLoading && user?.isLogin ? (
                  <div>
                    <HeaderIconButton
                      edge="start"
                      color="inherit"
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <MenuIcon />
                    </HeaderIconButton>
                    {
                      user?.data?.roles.includes('admin') ? (
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={() => {
                            navigate(AppUrl.NOTIFICATION)
                            handleClose()
                          }}>おしらせ</MenuItem>
                          <MenuItem onClick={() => {
                            navigate(AppUrl.MY_BOTTLE)
                            handleClose()
                          }}>マイボトル</MenuItem>
                          <MenuItem onClick={() => {
                            navigate(AppUrl.EVENT_MANAGEMENT)
                            handleClose()
                          }}>イベント一覧</MenuItem>
                          {
                            user?.cleanSystemStatusShow === 'available' && (
                              <MenuItem onClick={() => {
                                navigate(AppUrl.WASHING_MACHINE)
                                handleClose()
                              }}>マイボトル洗浄機</MenuItem>
                            )
                          }
                          {
                            user.couponShow === 'available' && (
                              <MenuItem onClick={() => {
                                navigate(AppUrl.COUPON)
                                handleClose()
                              }}>クーポン</MenuItem>
                            )
                          }
                          <MenuItem onClick={() => {
                            handleClose()
                            navigate(AppUrl.PROFILE)
                          }}>プロフィール</MenuItem>
                          <MenuItem onClick={() => {
                            handleClose()
                            navigate(AppUrl.USER_MANAGEMENT)
                          }}>ユーザー管理
                          </MenuItem>
                          <MenuItem onClick={() => {
                            handleClose()
                            navigate(AppUrl.DATA_MANAGEMENT)
                          }}>期間集計
                          </MenuItem>
                          <MenuItem onClick={() => {
                            handleClose()
                            navigate(AppUrl.NOTIFICATION_MANAGEMENT)
                          }}>おしらせ一覧
                          </MenuItem>
                          <MenuItem onClick={() => {
                            handleClose()
                            navigate(AppUrl.COUPON_MANAGEMENT)
                          }}>クーポン一覧
                          </MenuItem>
                          <MenuItem onClick={() => {
                            handleClose()
                            navigate(AppUrl.SURVEY_MANAGEMENT)
                          }}>アンケート一覧
                          </MenuItem>
                          <MenuItem onClick={() => {
                            handleClose()
                            navigate(AppUrl.SUSTAINABILITY_MANAGEMENT)
                          }}>サステナビリティ目標一覧
                          </MenuItem>
                          <MenuItem onClick={() => {
                            handleClose()
                            navigate(AppUrl.SETTING_SHOW)
                          }}>表示設定​
                          </MenuItem>
                          <MenuItem onClick={() => {
                            handleSignOut.mutate()
                            handleClose()
                          }}>
                            ログアウト
                          </MenuItem>

                        </Menu>
                      ) : user?.data?.roles.includes('normal') ? (
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={() => {
                            navigate(AppUrl.NOTIFICATION)
                            handleClose()
                          }}>おしらせ</MenuItem>
                          <MenuItem onClick={() => {
                            navigate(AppUrl.MY_BOTTLE)
                            handleClose()
                          }}>マイボトル</MenuItem>
                          {
                            user?.eventShow === 'available' && (
                              <MenuItem onClick={() => {
                                handleClose()
                                navigate(AppUrl.EVENT)
                              }}>イベント</MenuItem>
                            )
                          }
                          {
                            user?.cleanSystemStatusShow === 'available' && (
                              <MenuItem onClick={() => {
                                navigate(AppUrl.WASHING_MACHINE)
                                handleClose()
                              }}>マイボトル洗浄機</MenuItem>
                            )
                          }
                          {
                            user.couponShow === 'available' && (
                              <MenuItem onClick={() => {
                                navigate(AppUrl.COUPON)
                                handleClose()
                              }}>クーポン</MenuItem>
                            )
                          }
                          <MenuItem onClick={() => {
                            handleClose()
                            navigate(AppUrl.PROFILE)
                          }}>プロフィール</MenuItem>
                          <MenuItem onClick={() => {
                            handleClose()
                            navigate(AppUrl.USER_DELETE)
                          }}>退会</MenuItem>
                          <MenuItem onClick={() => {
                            handleSignOut.mutate()
                            handleClose()
                          }}>
                            ログアウト
                          </MenuItem>
                        </Menu>
                      ) : user?.data?.roles.includes('wash_bottle') ? (
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={() => {
                            navigate(AppUrl.CLEAN_SYSTEM + '?feedback_type_code=' + dummyUserWashedFeedbackTypeCode)
                            handleClose()
                          }}>洗浄機ステータス</MenuItem>
                          <MenuItem onClick={() => {
                            handleSignOut.mutate()
                            handleClose()
                          }}>
                            ログアウト
                          </MenuItem>
                        </Menu>
                      ) : (<></>)
                    }
                  </div>
                ) : <></>
              }
            </Toolbar>
          )
        }
      </AppBar >
    </>
  )
}

export default Header


import {
	useState,
	useRef,
	MouseEvent,
	TouchEvent,
	useCallback,
} from "react";
import {
	Box,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useGetImageByStage } from "lib/api/images";
import { BaseResponse, IEventDetail, IImage } from "interfaces";
import CircularProgressBox from "components/utils/CircularProgressBox";
import { useQueryClient } from "@tanstack/react-query";

interface CarouselProps {
	data?: IEventDetail;
	textPagination?: string;
}

const CarouselContainer = styled(Box)({
	display: "flex",
	overflow: "hidden",
	width: "100%",
	position: "relative",
	touchAction: "none",
});

const CarouselWrapper = styled(Box)({
	display: "flex",
	transition: "transform 0.5s ease-in-out",
	width: "100%",
});

const CarouselItem = styled(Box)({
	minWidth: "100%",
	boxSizing: "border-box",
});

const BoxAvatar = styled(Box)({
	width: "100%",
	aspectRatio: "1/1",
	backgroundPosition: "center",
	backgroundSize: "cover",
	margin: "0 auto",
});

const StageCarouselPagination: React.FC<CarouselProps> = ({
	data,
	textPagination,
}) => {
	const dataLength = Number(data?.stage) + 1
	const sustainabilityId = Number(data?.sustainabilityId)
	const [currentIndex, setCurrentIndex] = useState(dataLength - 1);
	const [startX, setStartX] = useState<number | null>(null);
	const [isSwiping, setIsSwiping] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	
  const queryClient = useQueryClient();
	const { isLoading: isLoadingImage } = useGetImageByStage({stage: currentIndex, sustainabilityId})

	const currentImageByIndex = useCallback((index: number) => {
		const dataImage = queryClient.getQueryData(["GET IMAGE BY STAGE", index, "SUSTAINABILITY_ID", sustainabilityId]) as BaseResponse<IImage>;
		return dataImage?.data
	}, [queryClient, sustainabilityId])

	const handlePrev = useCallback(() => {
		setCurrentIndex((prevIndex) =>
			prevIndex === 0 ? dataLength - 1 : prevIndex - 1
		);
	}, [dataLength]);

	const handleNext = useCallback(() => {
		setCurrentIndex((prevIndex) =>
			prevIndex === dataLength - 1 ? 0 : prevIndex + 1
		);
	}, [dataLength]);

	const handleTouchStart = useCallback(
		(e: TouchEvent) => {
			if (dataLength <= 1) return;

			setStartX(e.touches[0].clientX);
			setIsSwiping(true);
		},
		[dataLength]
	);

	const handleTouchMove = useCallback(
		(e: TouchEvent) => {
			if (dataLength <= 1) return;
			if (!isSwiping || startX === null) return;
			const currentX = e.touches[0].clientX;
			const diffX = startX - currentX;
			if (wrapperRef.current) {
				wrapperRef.current.style.transform = `translateX(${
					-currentIndex * 100 - (diffX / window.innerWidth) * 100
				}%)`;
			}
		},
		[currentIndex, isSwiping, dataLength, startX]
	);

	const handleTouchEnd = useCallback(
		(e: TouchEvent) => {
			if (dataLength <= 1) return;
			if (!isSwiping || startX === null) return;

			const endX = e.changedTouches[0].clientX;
			const diffX = startX - endX;
			if (Math.abs(diffX) > 50) {
				if (diffX > 0) {
					handleNext();
				} else {
					handlePrev();
				}
			} else {
				if (wrapperRef.current) {
					wrapperRef.current.style.transform = `translateX(-${
						currentIndex * 100
					}%)`;
				}
			}
			setIsSwiping(false);
			setStartX(null);
		},
		[currentIndex, handleNext, handlePrev, isSwiping, dataLength, startX]
	);
	const handleMouseDown = useCallback(
		(e: MouseEvent) => {
			if (dataLength <= 1 || isMobile) return;
			setStartX(e.clientX);
			setIsSwiping(true);
		},
		[isMobile, dataLength]
	);

	const handleMouseMove = useCallback(
		(e: MouseEvent) => {
			if (dataLength <= 1 || isMobile) return;
			if (!isSwiping || startX === null) return;
			const currentX = e.clientX;
			const diffX = startX - currentX;
			if (wrapperRef.current) {
				wrapperRef.current.style.transform = `translateX(${
					-currentIndex * 100 - (diffX / window.innerWidth) * 100
				}%)`;
			}
		},
		[currentIndex, isMobile, isSwiping, dataLength, startX]
	);

	const handleMouseUp = useCallback(
		(e: MouseEvent) => {
			if (dataLength <= 1 || isMobile) return;
			if (!isSwiping || startX === null) return;
			const endX = e.clientX;
			const diffX = startX - endX;
			if (Math.abs(diffX) > 100) {
				if (diffX > 0) {
					handleNext();
				} else {
					handlePrev();
				}
			} else {
				if (wrapperRef.current) {
					wrapperRef.current.style.transform = `translateX(-${
						currentIndex * 100
					}%)`;
				}
			}
			setIsSwiping(false);
			setStartX(null);
		},
		[
			currentIndex,
			handleNext,
			handlePrev,
			isMobile,
			isSwiping,
			dataLength,
			startX,
		]
	);

	const handleMouseLeave = useCallback(
		(e: MouseEvent) => {
			if (dataLength <= 1 || isMobile) return;
			if (isSwiping) {
				handleMouseUp(e);
			}
		},
		[handleMouseUp, isMobile, isSwiping, dataLength]
	);

	return (
		<>
			<CarouselContainer
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
				onMouseLeave={handleMouseLeave}
			>
				<CarouselWrapper
					style={{
						transform: `translateX(-${currentIndex * 100}%)`,
					}}
					ref={wrapperRef}
				>
					{
						Array.from(
							{ length: Number(data?.stage) + 1 },
							(_, index) => (
								<CarouselItem key={index}>
									<Box
										key={index}
										className="animation-change"
										sx={{ width: "100%", aspectRatio: '1/1' }}
									>
										{
											currentIndex === index && isLoadingImage ? (
												<CircularProgressBox />
											) : (
												<BoxAvatar
													style={{
														backgroundImage: `url(${currentImageByIndex(index)?.value})`,
													}}
													className="animation-change"
												/>
											)
										}
									</Box>
								</CarouselItem>
							)
						)
					}
				</CarouselWrapper>
			</CarouselContainer>
			<Box display="flex" gap={2} alignItems="center">
				<IconButton
					aria-label="prev"
					onClick={() => {
						handlePrev();
					}}
					disabled={dataLength <= 1}
				>
					<KeyboardDoubleArrowLeftIcon
						sx={{
							width: 25,
							height: 25,
						}}
					/>
				</IconButton>
				<Typography color="primary" fontWeight="bold" fontSize={22}>
					{textPagination} {currentIndex}
				</Typography>
				<IconButton
					aria-label="next"
					onClick={() => {
						handleNext();
					}}
					disabled={dataLength <= 1}
				>
					<KeyboardDoubleArrowRightIcon
						sx={{
							width: 25,
							height: 25,
						}}
					/>
				</IconButton>
			</Box>
		</>
	);
};

export default StageCarouselPagination;

import { Autocomplete, Box, Button, FormControl, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { IACreateSustainability } from "interfaces";
import React, { ChangeEvent, ChangeEventHandler, useState } from "react"
import { styled } from "@mui/material/styles";
import { AddCircleOutline } from "@mui/icons-material";
import ModalShowImage from "components/utils/ModalShowImage";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useAdminGetListCoupon } from "lib/api/coupon";

interface CreateSustainabilityStep2Props {
  data: IACreateSustainability
  onSubmit?: Function
  onChange?: (e:ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, customValue?: number | string) => void
  onBack?: Function
  onChangeSelect?: (name: string, value: number) => void
  onAddAchievement?: Function
  onChangeImage?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  isEdit: boolean
  isEditDetail?: boolean
  setModalAlert?: React.Dispatch<React.SetStateAction<{
    isOpen: boolean;
    message: string | string[];
    type: "error" | "success" | "info" | "warning";
}>>
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(even)": {
		backgroundColor: theme.palette.action.hover,
	},
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const BoxItem = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: 10,
	marginBottom: 1,
}));

const ContentTypography = styled(Typography)(({ theme }) => ({
	textAlign: "center",
  whiteSpace: "pre",
}));

interface renderInputProps {
  name: string
  placeholder: string
  value: string | number | undefined
  width?: number | string
  type?: React.HTMLInputTypeAttribute
  errorMsg?: string
  customData?: "normal" | "achievement"
}
interface renderSelectProps {
  name: string
  value: string | number | undefined
  width?: number | string
}

const SubmitButton = styled(Button)(({ theme }) => ({
	width: 180,
	height: 60,
	fontSize: 20,
	borderRadius: 10,
	margin: "10px auto",
}));

const Input = styled("input")({
  display: "none",
});


const CreateSustainabilityStep2 = ({data, onBack, onChange, onSubmit, onChangeSelect, onAddAchievement, onChangeImage, isEdit, isEditDetail, setModalAlert } : CreateSustainabilityStep2Props) => {
  const {data: coupons } = useAdminGetListCoupon()
  const [openModalShowImage, setOpenModalShowImage] = useState<{
    open: boolean
    imageSrc: string
    imageAlt: string
  }>({
    open: false,
    imageSrc: "",
    imageAlt: ""
  })

  const renderInput = ({name, placeholder, value, width, type, errorMsg, customData = "normal" }: renderInputProps) => {
    if(isEdit && !isEditDetail)
      return (
        <BoxItem>
          <TextField
            name={name}
            required
            placeholder={placeholder}
            value={customData === "achievement" ? parseFloat((Number(value) / Number(data?.mission?.factor)).toFixed(3)) : value}
            onChange={(e) => {
              if (Number.isInteger(Number(e.target.value))) {
                if (customData === "normal") {
                  onChange && onChange(e as any, undefined);
                } else if(customData === "achievement") {
                  onChange && onChange(e as any, parseFloat((Number(e.target.value) * data?.mission?.factor).toFixed(3)));
                }
              } else return;
            }}
            sx={{ width }}
            type={type ?? "string"}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            InputProps={{
              inputProps: { 
                  min: 0
              }
            }}
            error={!!errorMsg}
            helperText={errorMsg}
          />
        </BoxItem>
      )
    else return (
      <BoxItem> 
        <ContentTypography>{customData === "achievement" ? Math.round(Number(value) / Number(data?.mission?.factor)) : value}</ContentTypography>
      </BoxItem>
    )
  }

  const renderSelect = ({ name, value, width }: renderSelectProps) => {
    if(isEdit && !isEditDetail)
      return (
        <FormControl sx={{ width, minWidth: 300 }}>
          <Autocomplete
            options={coupons?.data || []}
            getOptionLabel={(option) => option.name}
            value={coupons?.data?.find(itemOption => value === itemOption?.id) || null as any}
            onChange={(event, newValue) => {
              onChangeSelect && onChangeSelect(name, newValue?.id ?? undefined)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ required: false }}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </FormControl>
      )
    else return (
      <BoxItem> 
        <ContentTypography>{coupons?.data?.find(item => item.id === value)?.name}</ContentTypography>
      </BoxItem>
    )
  }
  
  const handleShowImage = (imageSrc: string, imageAlt: string) => {
    setOpenModalShowImage({
      open: true,
      imageSrc,
      imageAlt
    })
  }
  const handleCloseImage = () => {
    setOpenModalShowImage({
      open: false,
      imageSrc: "",
      imageAlt: ""
    })
  }


  return (
    <Box
			display="flex"
			flexDirection="column"
			gap={3}
			component="form"
			onSubmit={(e) => {
				e.preventDefault();
        onSubmit && onSubmit()
			}}
		>
      {
        isEdit && !isEditDetail && (
          <Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
            サステナビリティ目標作成
          </Typography>
        )
      }
      <TableContainer component={Paper}>
        <Table aria-label="coupon data table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">ステージ</TableCell>
              <TableCell align="center">回数</TableCell>
              <TableCell align="center">しきい値</TableCell>
              <TableCell align="center">クーポン設定（任意）</TableCell>
              <TableCell align="center">画像</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.sustainability_successes_attributes?.map((item, index) => {
              let errorMsg = "";
              for (let i = 0; i < index; i++) {
                if (Math.abs(data?.sustainability_successes_attributes[i].achievement) > Math.abs(item.achievement)) {
                  errorMsg = "回数は、前のステージ値より下回らないでください！";
                    break;
                  } else errorMsg= ""
              }
              return (
                <React.Fragment key={item.stage}>
                  <StyledTableRow
                    sx={{ "td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{item.stage}</TableCell>
                    <TableCell align="center">
                      {
                        renderInput({
                          name: `sustainability_successes_attributes.${index}.achievement`,
                          placeholder: "回数",
                          value: item.achievement,
                          type: "number",
                          errorMsg,
                          customData: "achievement"
                        })
                      }
                    </TableCell>
                    <TableCell align="center">{data?.sustainability_successes_attributes[index].achievement}{data?.mission?.unit}</TableCell>

                    <TableCell align="center">
                      {
                        renderSelect({
                          name: `sustainability_successes_attributes.${index}.sustainability_success_coupons_attributes.0.coupon_id`,
                          width: "max-content",
                          value: item.sustainability_success_coupons_attributes?.[0]?.coupon_id || undefined,
                        })
                      }
                    </TableCell>

                    <TableCell align="center" sx={{ maxWidth: 100 }}>
                      {
                        item.image_attributes.name ? (
                          <>
                            <Box
                              component="img"
                              src={item.image_attributes.value}
                              sx={{
                                aspectRatio: "1/1",
                                width: "100%",
                                margin: "0 0.5rem 0 0",
                                cursor: "pointer",
                                border: "1px solid #ccc",
                              }}
                              display="block"
                              alt={item.image_attributes.name}
                              onClick={() => {
                                handleShowImage(item.image_attributes.value, item.image_attributes.name )
                              }}
                            />
                            {
                              (isEdit || isEditDetail) && (
                                <label htmlFor={`file-upload${index}`}>
                                  <Input
                                    accept={window.innerWidth >= 600 ? ".png, .jpg, .jpeg" : undefined}
                                    id={`file-upload${index}`}
                                    name={`sustainability_successes_attributes.${index}.image_attributes.value`}
                                    type="file"
                                    onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                                      if (e.target.files && e.target.files.length) {
                                        if (e.target.files[0]?.type !== 'image/png' && e.target.files[0]?.type !== 'image/jpeg' && e.target.files[0]?.type !== 'image/jpg') {
                                          setModalAlert && setModalAlert({
                                            isOpen: true,
                                            message: '不明な画像形式です',
                                            type: 'error'
                                          })
                                        } else onChangeImage && onChangeImage(e)
                                      }
                                    }}
                                    style={{ display: "none" }}
                                  />
                                  <Typography
                                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                                  >
                                    {item.image_attributes.name}
                                  </Typography>
                                  <input
                                    type="text"
                                    name={`sustainability_successes_attributes.${index}.image_attributes.value`}
                                    value={item.image_attributes.name}
                                    onChange={() => {}}
                                    required
                                    style={{
                                      position: "absolute",
                                      opacity: 0,
                                      height: 0,
                                      width: 0,
                                      pointerEvents: "none",
                                    }}
                                  />
                                </label>
                              )
                            }
                          </>
                        ) : (
                          <Button
                            variant="contained"
                            component="label"
                            startIcon={<UploadFileIcon />}
                          >
                            アップロード
                            <Input
                              type="file"
                              name={`sustainability_successes_attributes.${index}.image_attributes.value`}
                              accept={window.innerWidth >= 600 ? ".png, .jpg, .jpeg" : undefined}
                              onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                                if (e.target.files && e.target.files.length) {
                                  if (e.target.files[0]?.type !== 'image/png' && e.target.files[0]?.type !== 'image/jpeg' && e.target.files[0]?.type !== 'image/jpg') {
                                    setModalAlert && setModalAlert({
                                      isOpen: true,
                                      message: '不明な画像形式です',
                                      type: 'error'
                                    })
                                  } else onChangeImage && onChangeImage(e)
                                }
                              }}
                            />
                            <input
                              type="text"
                              name={`sustainability_successes_attributes.${index}.image_attributes.value`}
                              value={item.image_attributes.name}
                              onChange={() => {}}
                              required
                              style={{
                                position: "absolute",
                                opacity: 0,
                                height: 0,
                                width: 0,
                                pointerEvents: "none",
                              }}
                            />
                          </Button>
                        )
                      }
                    </TableCell>

                  </StyledTableRow>
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
        {
          isEdit && !isEditDetail && (
            <Box marginLeft={6}>
              <IconButton
                aria-label="add"
                size="large"
                onClick={() => onAddAchievement && onAddAchievement()}
              >
                <AddCircleOutline
                  color="primary"
                  sx={{ width: 30, height: 30}}
                />
              </IconButton>
            </Box>
          )
        }
      </TableContainer>
      {
        isEdit && !isEditDetail && (
          <Box display="flex" sx={{ width: "40%", margin: "0 auto" }}>
            <SubmitButton variant="outlined" onClick={() => {
              onBack && onBack()
            }}>
              戻る
            </SubmitButton>
            <SubmitButton variant="contained" type="submit">
              次へ
            </SubmitButton>
          </Box>
        )
      }
      <ModalShowImage open={openModalShowImage.open} onClose={handleCloseImage} imageSrc={openModalShowImage.imageSrc} imageAlt={openModalShowImage.imageAlt} />

    </Box>
  )
}

export default CreateSustainabilityStep2

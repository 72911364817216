import React, { useContext, useState, ChangeEvent } from 'react'
import { useGetUserImage, useUpdateImageUser } from 'lib/api/user';
import { AuthContext } from 'App';
import { fileToBase64 } from 'lib/common/convert-data'

import { Box, Typography, Button, Snackbar,Alert } from "@mui/material"
import { styled } from '@mui/material/styles';
import { BaseResponse, ErrorResponse } from 'interfaces';
import { AxiosError } from 'axios';
import CircularProgressBox from 'components/utils/CircularProgressBox'
import AvatarDefault from 'components/utils/AvatarDefault'

const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    gap: 5,
    alignItems: 'center'
});

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Profile: React.FC = () => {
    const { user, isLoading } = useContext(AuthContext)
    const [step, setStep] = useState<1 | 2>(1)
    
    const [showMessage, setShowMessage] = useState<{
        open: boolean
        message: string | string[]
        type: "error" | "success" | "info" | "warning"
    }>({
        open: false,
        message: '',
        type: 'success'
    })
    const [imageChange, setImageChange] = useState<string>('')

    const { data: imageUser, refetch, isError, isLoading: isLoadingImage, isSuccess } = useGetUserImage(Number(user?.data?.id))

    const handleUpdateImageUser = useUpdateImageUser((res: BaseResponse) => {
        setShowMessage({
            open: true,
            message: res?.message || '',
            type: 'success'
        })
        setStep(1)
        refetch()
        setImageChange('')
    }, (err: AxiosError<ErrorResponse>) => {
        setShowMessage({
            open: true,
            message: err?.response?.data?.errors || 'エーラ',
            type: 'error'
        })
    })
    return (
        <>
            {
                (isLoading || isLoadingImage) ? (
                    <CircularProgressBox />
                ) : isSuccess ? (
                    <>
                        <BoxWrapper sx={{ backgroundColor: '#c1e2f6', marginTop: '2rem', marginBottom: '1rem' }}>
                            <Box display="flex" alignItems="center" sx={{ margin: '2rem 0 2rem 0' }} >
                                <AvatarDefault width={75} height={75} src={imageChange || imageUser?.data?.image} text={user?.data?.nickname?.charAt(0)} />
                                <Box marginLeft={2}>
                                    <Typography variant='inherit' component="div">
                                        <Typography component="span" sx={{ fontSize: '1.2rem', fontWeight: 800 }}>{user?.data?.nickname}</Typography>
                                        <Typography component="span" sx={{ marginLeft: "0.5rem", fontSize: '0.8rem' }}>さん</Typography>
                                    </Typography>
                                </Box>
                            </Box>
                        </BoxWrapper>

                        <BoxWrapper>
                            {step === 1 && (
                                <>  
                                    <Box sx={{ display: 'flex', justifyContent: 'center', margin: "2rem 0 1rem 0" }}>
                                        <Button variant="outlined" onClick={() => setStep(2)}>プロフィールを変更する</Button>
                                    </Box>
                                </>
                            )}
                            {
                                step === 2 && (
                                    <>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            fullWidth
                                            color="primary"
                                            component="label"
                                            sx={{
                                                margin: "2rem 0 1rem 0",
                                                maxWidth: '400px',
                                            }}
                                        >
                                            アバターアップロード（任意）
                                            <VisuallyHiddenInput type="file" accept={window.innerWidth >= 600 ? ".png, .jpg, .jpeg" : undefined} onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                                                if (e.target.files && e.target.files.length) {
                                                    if (e.target.files[0]?.type !== 'image/png' && e.target.files[0]?.type !== 'image/jpeg' && e.target.files[0]?.type !== 'image/jpg') {
                                                      setShowMessage({
                                                        open: true,
                                                        message: '不明な画像形式です',
                                                        type: 'error'
                                                      })
                                                    } else if (e.target.files[0]?.size > 5000000) {
                                                        setShowMessage({
                                                          open: true,
                                                          message: '画像は5MB以下でアップロードしてください',
                                                          type: 'error'
                                                        })
                                                      } else {
                                                        const convertString = await fileToBase64(e.target.files[0]) || ''
                                                        setImageChange(convertString)
                                                    }
                                                  }
                                            }} />
                                        </Button>
                                        <Button sx={{ margin: '1rem 0' }} variant='contained' disabled={isLoading || handleUpdateImageUser.isPending} onClick={() => {
                                            if (!imageChange) {
                                                setShowMessage({
                                                    open: true,
                                                    message: 'イメージを選択してください',
                                                    type: 'error'
                                                })
                                                
                                            } else
                                                handleUpdateImageUser.mutate({ id: user?.data?.id as number, imageUrl: imageChange })
                                        }}>変更</Button>
                                    </>
                                )
                            }
                        </BoxWrapper>
                    </>
                ) : isError && (
                    <>
                        <h1>Error</h1>
                    </>
                )
            }
            {
                showMessage.open && (
                    <Snackbar
                        open={showMessage.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        onClose={() => setShowMessage({
                            open: false,
                            message: '',
                            type: 'success'
                        })}
                    >
                        <Alert onClose={() => setShowMessage({
                            open: false,
                            message: '',
                            type: 'success'
                        })} severity={showMessage.type} sx={{
                            '& .MuiAlert-icon': {
                                margin: 'auto', marginRight: 1
                            }
                        }}>
                            {
                                typeof showMessage.message === 'string' ? (
                                    <p>{showMessage.message}</p>
                                ) : (
                                    <>
                                        {
                                            showMessage.message.map((msg, index) => <p key={msg+ index}>{msg}</p>)
                                        }
                                    </>
                                )
                            }
                        </Alert>
                    </Snackbar>
                )
            }
        </>
    )
}

export default Profile
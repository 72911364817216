import { useMutation, useQueries } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import { BaseResponse, ErrorResponse, IAOptionEvent, ICreateSetting, IDataSetting } from "interfaces";
import { AxiosError } from "axios";

const getSettingShow = async () => {
	const res = await axiosInstanceAuth.get("/admin/statuses/bulk_show");
	return res as BaseResponse<IDataSetting>;
}
const getListEventsIndividual = async () => {
	const res = await axiosInstanceAuth.get("/admin/events/individual");
	return res as BaseResponse<IAOptionEvent[]>;
}
const getListEventsEveryone = async () => {
	const res = await axiosInstanceAuth.get("/admin/events/everyone");
	return res as BaseResponse<IAOptionEvent[]>;
}

export const useAdminGetSettingShow = () => {
  return useQueries({
    queries: [
      { queryKey: ['ADMIN_GET_SETTING_SHOW'], queryFn: getSettingShow },
      { queryKey: ['ADMIN_GET_LIST_EVENTS_INDIVIDUAL'], queryFn: getListEventsIndividual },
      { queryKey: ['ADMIN_GET_LIST_EVENTS_EVERYONE'], queryFn: getListEventsEveryone },
    ],
  });
};

export const useAdminUpdateSettingShow = (
	onOk?: (res: BaseResponse) => void,
	onErr?: (err: AxiosError<ErrorResponse>) => void
) => {
	return useMutation({
		mutationKey: ["ADMIN_UPDATE_SETTING_SHOW"],
		mutationFn: ( data : ICreateSetting) =>
			axiosInstanceAuth.patch("/admin/statuses/bulk_update", { data }),
		onSuccess: (res) => {
			onOk && onOk(res as BaseResponse);
		},
		onError: (err) => {
			onErr && onErr(err as AxiosError<ErrorResponse>);
		},
	});
};

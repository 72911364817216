import { useState, useContext, useRef, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { AppUrl } from 'components/utils/constants/AppUrl'
import jsQR from 'jsqr'
import { AlertContext } from "App";
import { BaseResponse, ErrorResponse } from "interfaces"

import { Box, Button, Typography, Modal } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import { useCreateUserTagMutation, useGetUserTag, useUpdateUserTagMutation } from 'lib/api/my-bottle'
import { AxiosError } from 'axios';

const BoxRegister = styled(Box)({
    textDecoration: "underline",
    display: 'flex',
    justifyContent: 'end',
    gap: 1,
    alignItems: 'center',
    marginTop: 2,
    cursor: 'pointer'
});
const BoxCamera = styled(Box)({
    position: 'relative',
    marginTop: 5,
    border: '1px solid black',
    borderRadius: 2,
    width: 300,
    height: 300
});
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const RegisterButton = styled(Button)({
    marginTop: 10,
    padding: '10px 30px',
    fontSize: 20
});

const RegisterMyBottle = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);

    const videoRef = useRef<HTMLVideoElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [result, setResult] = useState('')

    const { data } = useGetUserTag()
    const { setModalAlert } = useContext(AlertContext)

    useEffect(() => {
        const currentVideoRef = videoRef.current;
        const canvas = canvasRef.current
        let timeoutId: NodeJS.Timeout | null = null;

        const scanQrCode = () => {
            if (canvas && currentVideoRef) {
                const ctx = canvas.getContext('2d')
                if (ctx) {
                    // カメラの映像をcanvasに描画する
                    ctx.drawImage(currentVideoRef, 0, 0, canvas.width, canvas.height)
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    
                    // 画像データを取得
                    var data = imageData.data;
    
                    // シャープメソッド
                    const _data = data.slice();
                    const sharpedColor = (color: number, i: number) => {
                        // 係数
                        const sub = -1;
                        const main = 10;
    
                        const prevLine = i - (canvas.width * 4);
                        const nextLine = i + (canvas.width * 4);
    
                        const sumPrevLineColor = (_data[prevLine - 4 + color] * sub) + (_data[prevLine + color] * sub) + (_data[prevLine + 4 + color] * sub);
                        const sumCurrLineColor = (_data[i - 4 + color] * sub) + (_data[i + color] * main) + (_data[i + 4 + color] * sub);
                        const sumNextLineColor = (_data[nextLine - 4 + color] * sub) + (_data[nextLine + color] * sub) + (_data[nextLine + 4 + color] * sub);
    
                        return (sumPrevLineColor + sumCurrLineColor + sumNextLineColor) / 2
                    };
    
                    // 画像をシャープにする処理
                    for (var i = 0; i < data.length; i += 4) {
                        // 2列目〜n-1列目
                        if (i % (canvas.width * 4) === 0 || i % ((canvas.width * 4) + 300) === 0) {
                            // nop
                        } else {
                            data[i] = sharpedColor(0, i);
                            data[i + 1] = sharpedColor(1, i);
                            data[i + 2] = sharpedColor(2, i);
                        }
                    }
    
                    // 変更した画像データをCanvasに描画
                    ctx.putImageData(imageData, 0, 0);
    
                    // QRコードをスキャンする
                    const qrCodeData = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "attemptBoth" })

                    if (qrCodeData && qrCodeData.data) {
                        setResult(qrCodeData.data)
                        setOpen(true)
                        return
                    } else {
                        timeoutId = setTimeout(scanQrCode, 500)
                    }
                }
            }
        }
        const constraints = {
            video: {
                facingMode: "environment",
                width: { ideal: 300 },
                height: { ideal: 300 },
            },
        }

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;

                    // loadedmetadata イベントを待つ関数
                    const onLoadedMetadata = () => {
                        // 再度確認してから再生
                        if (videoRef.current) {
                            videoRef.current.play()
                                .then(() => {
                                    // 再生に成功したらQRコードのスキャンを開始
                                    scanQrCode();
                                })
                                .catch((playError) => {
                                    console.error('Error playing video:', playError);
                                })
                                .finally(() => {
                                    // イベントリスナーを削除
                                    videoRef.current?.removeEventListener('loadedmetadata', onLoadedMetadata);
                                });
                        }
                    };

                    // loadedmetadata イベントリスナーを追加
                    videoRef.current.addEventListener('loadedmetadata', onLoadedMetadata);
                }
            })
            .catch((err) => console.error('Error accessing media devices:', err));

        // コンポーネントがアンマウントされたら、カメラのストリームを停止する
        return () => {
            clearInterval(timeoutId as NodeJS.Timeout);
            if (currentVideoRef && currentVideoRef.srcObject) {
                const stream = currentVideoRef.srcObject as MediaStream
                const tracks = stream.getTracks()
                tracks.forEach((track) => track.stop())
            }
        }
    }, [open])

    const handleCreateUserTag = useCreateUserTagMutation((res: BaseResponse<any>) => {
        videoRef?.current && videoRef?.current?.pause()
        setOpen(false)
        setResult('')
        setModalAlert({ isOpen: true, message: res?.message, type: 'success' })
        navigate(AppUrl.MY_BOTTLE)
    }, (err: AxiosError<ErrorResponse<{
        id: number
        messages: string[]
        message: string
    }>>) => {
        setResult('')
        setModalAlert({ isOpen: true, message: err?.response?.data?.error?.message || err?.response?.data?.error?.messages || 'エラー', type: 'error' })
    })
    const handleUpdateUserTag = useUpdateUserTagMutation((res: BaseResponse<any>) => {
        videoRef?.current && videoRef?.current?.pause()
        setModalAlert({ isOpen: true, message: res?.message, type: 'success' })
        navigate(AppUrl.MY_BOTTLE)
    }, (err: AxiosError<ErrorResponse<{
        id: number
        message: string
        messages: string[]
    }>>) => {
        setResult('')
        setModalAlert({ isOpen: true, message: err?.response?.data?.error?.message || err?.response?.data?.error?.messages || 'エラー', type: 'error' })
    })
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box>
                <BoxCamera>
                    <video ref={videoRef} autoPlay playsInline style={{ position: "absolute", left: 0, top: 0, zIndex: 50, height: "100%", width: "100%" }} />
                    <canvas ref={canvasRef} width='300' height='300' style={{ display: 'none' }} />
                </BoxCamera>
                <BoxRegister>
                    <HelpOutlineIcon />
                    <span onClick={() => navigate(AppUrl.REGISTER_MANUALLY_MY_BOTTLE)}>手動で登録する</span>
                </BoxRegister>
            </Box>

            <Modal
                open={Boolean(open && result)}
                onClose={() => {
                    setOpen(false)
                    setResult('')
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                        以下のタグで登録しますか
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: 'center' }}>
                        {result}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <RegisterButton variant='contained' onClick={() => {
                            if (!data?.data?.code && !!result)
                                handleCreateUserTag.mutate(result)
                            else if(!!result) {
                                handleUpdateUserTag.mutate(result)
                            }
                        }} disabled={handleCreateUserTag.isPending || handleUpdateUserTag.isPending}>{data?.data?.code ? '再登録' : '登録'}</RegisterButton>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default RegisterMyBottle
import { useAdminGetDetailSustainability, useAdminGetDetailSustainabilitySuccesses, useAdminUpdateSustainability } from 'lib/api/admin-sustainability';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AlertMessage from 'components/utils/AlertMessage';
import { useContext, useEffect, useState } from 'react';
import { AppUrl } from 'components/utils/constants/AppUrl';
import { IACustomUpdateSustainability, IAUpdateSustainability } from 'interfaces';
import CircularProgressBox from 'components/utils/CircularProgressBox';
import { handleChangeStateImage, handleChangeStateInput, handleChangeStateSelect } from 'lib/common/handle-change-state';
import { AlertContext } from 'App';
import { Box, Button, Divider, InputBase, MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material';
import CreateSustainabilityStep1 from './CreateSustainability/Step1';
import { styled } from "@mui/material/styles";
import CreateSustainabilityStep2 from './CreateSustainability/Step2';
import SearchIcon from "@mui/icons-material/Search";
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'components/utils/constants/QueryKey';

const BoxRowPerPage = styled(Box)({
	display: "flex",
	justifyContent: "flex-end",
	gap: 5,
	width: "100%",
});

const Search = styled(Box)(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	width: "100%",
	border: "1px black solid",
	margin: "10px 0 10px",
	display: "flex",
}));

const SearchIconWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	right: 10,
	cursor: "pointer",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	width: "100%",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(2)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
	},
}));

const SubmitButton = styled(Button)(({ theme }) => ({
	width: 180,
	height: 60,
	fontSize: 20,
	borderRadius: 10,
	margin: "10px auto",
}));

const DetailSustainability = () => {
  const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const page: number = Number(searchParams.get("page")) || 1;
	const per: number = Number(searchParams.get("per")) || 5;
	const search: string = searchParams.get("search") || "";
	const [searchValue, setSearchValue] = useState(search);

	const { data, isLoading } = useAdminGetDetailSustainability(Number(id));
	const { data: dataSSA, isLoading: isLoadingSSA } = useAdminGetDetailSustainabilitySuccesses(Number(id),{
		page,
		per,
		search,
	});

  const [customData, setCustomData] = useState<IACustomUpdateSustainability | undefined>(undefined)
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({ isOpen: false, message: "", type: "success" });
  const { setModalAlert:setModalAlertGlobal } = useContext(AlertContext)

  useEffect(() => {
    const changedData = queryClient.getQueryData([QueryKey.CACHE_CHANGED_DATA_SUSTAINABILITY]) as IAUpdateSustainability["sustainability_successes_attributes"] || []
    if(data?.data && dataSSA?.data){
      const newCustomData = {
        code: data?.data?.code,
        name: data?.data?.name,
        attribute_id: data?.data?.attributeId,
        mission: {
          factor: data?.data?.mission?.factor,
          unit: data?.data?.mission?.unit,
          content: data?.data?.mission?.content?.map(item => ({
            title: item.title,
            unit: item.unit
          }))
        },
        sustainability_successes_attributes: dataSSA?.data?.data?.map(item => {
          const currentItemChanged = changedData?.find(i => i.id === item.id) as any;
          if (changedData?.map(it => it.id).includes(item.id)) {
            return ({
              ...currentItemChanged
            });
          } else {
            return ({
              id: item.id,
              achievement: item.achievement,
              stage: item.stage,
              sustainability_success_coupons_attributes: item.sustainabilitySuccessCouponsAttributes?.map(coupon => ({
                coupon_id: coupon?.couponId
              })),
              image_attributes: {
                id: item.imageAttributes.id,
                is_login: item.imageAttributes?.isLogin,
                mime: item.imageAttributes?.mime,
                name: item.imageAttributes?.name,
                value: item.imageAttributes?.value
              }
            });
          }
        })
      }
      setCustomData(newCustomData)
    }
  }, [data, dataSSA, queryClient])
  useEffect(() => {
    if(customData && dataSSA){
      const changedData = queryClient.getQueryData([QueryKey.CACHE_CHANGED_DATA_SUSTAINABILITY]) as IAUpdateSustainability["sustainability_successes_attributes"] || []
      const willChangedData: IAUpdateSustainability["sustainability_successes_attributes"] = [...changedData]
      customData?.sustainability_successes_attributes?.forEach((item) => {
        const findExistItemIndex = willChangedData?.findIndex(it => it.id === item.id)
        if(findExistItemIndex !== -1){
          willChangedData.splice(findExistItemIndex, 1, {
            ...item,
          })
        } else {
          dataSSA?.data?.data?.forEach(ssaItem => {
            if(ssaItem.id === item.id && (ssaItem.imageAttributes.value !== item.image_attributes.value))
              willChangedData.push({
                ...item,
              })
          })
        }
      })
      queryClient.setQueryData([QueryKey.CACHE_CHANGED_DATA_SUSTAINABILITY], willChangedData);
    }
  }, [customData, dataSSA, queryClient])

  const handleAdminUpdateSustainability = useAdminUpdateSustainability(
    (res) => {
			setModalAlertGlobal({ isOpen: true, message: res?.message, type: "success" });
			navigate(AppUrl.SUSTAINABILITY_MANAGEMENT);
		},
		(err) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.error?.messages || "エラー",
				type: "error",
			});
    }
  )

  const handleSubmit = () => {
    const changedDataCustom = queryClient.getQueryData([QueryKey.CACHE_CHANGED_DATA_SUSTAINABILITY]) as IAUpdateSustainability["sustainability_successes_attributes"] || []

    customData && handleAdminUpdateSustainability.mutate({
      id: Number(id),
      body: {
        sustainability_successes_attributes: changedDataCustom?.map(item => ({
          id: item.id,
          image_attributes: item.image_attributes
        }))
      }
    })
  }

  const handleBack = () => {
		navigate(AppUrl.SUSTAINABILITY_MANAGEMENT);
  }

  const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		searchParams.set("page", value.toString());
		setSearchParams(searchParams);
	};
  const handleChangePer = (e: SelectChangeEvent<any>) => {
    searchParams.set("per", e.target.value.toString());
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  }

  return(
    <Box>
      {
        isLoading ? (
          <CircularProgressBox />
        ) : data?.data && (
          <CreateSustainabilityStep1
            data={{
              attribute_id: data?.data?.attributeId,
              code: data?.data?.code,
              mission: data?.data?.mission,
              name: data?.data?.name,
              sustainability_successes_attributes: undefined as any
            }}
            isEdit={false}
            isEditDetail={true}
          />
        )
      }
      <Divider sx={{ marginBottom: 3 }}/>
      <BoxRowPerPage>
        <Select
          value={per}
          onChange={handleChangePer}
          sx={{ minWidth: 100 }}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
        </Select>
      </BoxRowPerPage>
      <Search>
        <StyledInputBase
          placeholder="ステージとかクーポン名で検索してください"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchParams.set("search", searchValue);
              searchParams.set("page", "1");
              setSearchParams(searchParams);
            }
          }}
        />
        <SearchIconWrapper>
          <SearchIcon
            onClick={() => {
              searchParams.set("search", searchValue);
              searchParams.set("page", "1");
              setSearchParams(searchParams);
            }}
          />
        </SearchIconWrapper>
      </Search>
      {
        isLoadingSSA ? (
          <CircularProgressBox />
        ) : customData && (
          <CreateSustainabilityStep2
            data={customData}
            isEdit={true}
            isEditDetail={true}
            onChange={(e, customValue) => handleChangeStateInput(setCustomData)(e as any, customValue)}
            onChangeSelect={handleChangeStateSelect(setCustomData)}
            onChangeImage={handleChangeStateImage(setCustomData)}
            setModalAlert={setModalAlert}
          />
        )
      }
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center"}}>
        <Pagination
          page={Number(page)}
          count={dataSSA?.data?.totalPages}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={0}
        />
      </Box>
      <Box display="flex" sx={{ width: "40%", margin: "0 auto" }}>
        <SubmitButton variant="outlined" onClick={() => {
          handleBack()
        }}>
          戻る
        </SubmitButton>
        <SubmitButton variant="contained" onClick={() => handleSubmit()} disabled={isLoading || isLoadingSSA || handleAdminUpdateSustainability.isPending}>
          確認
        </SubmitButton>
      </Box>
      <AlertMessage
        open={modalAlert.isOpen}
        setOpen={(open: boolean) =>
          setModalAlert({ isOpen: open, message: "", type: "warning" })
        }
        severity={modalAlert.type}
        message={modalAlert.message}
      />
    </Box>
  )
}

export default DetailSustainability

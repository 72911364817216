import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CardLayoutComponent from "components/utils/CardLayoutComponent";
import { IWashedHistory } from "interfaces/Dashboard";
import { useGetDataWashedHistory } from "lib/api/dashboard";
import { formatUTCDateTime } from "lib/common/date.helper";

export const BoxItemWrapper = styled(Box)({
	backgroundColor: "white",
	padding: 20,
	borderRadius: 15,
});

export const BoxListData = styled(Box)({
	border: "1px solid black",
	padding: "5px 10px",
});
export const BoxItem = styled(Box)({
	display: "flex",
	gap: 50,
	padding: 3,
});

const DashboardWashedHistory = () => {
	const { data } = useGetDataWashedHistory();
	return (
		<CardLayoutComponent title="マイボトル洗浄利用履歴">
			<BoxListData>
				<TableContainer
					component={Paper}
					sx={{
						maxHeight: 290,
					}}
				>
					<Table size="small" stickyHeader aria-label="history use table">
						<TableHead>
							<TableRow>
								<TableCell>＃</TableCell>
								<TableCell align="right">ニックネーム</TableCell>
								<TableCell align="right">種別</TableCell>
								<TableCell align="right">場所</TableCell>
								<TableCell align="right">日時</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(data?.data as IWashedHistory[])?.map((item, index) => (
								<TableRow
									key={index}
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
										backgroundColor: index % 2 === 1 ? "lightgray" : "white",
									}}
								>
									<TableCell component="th" scope="row">
										{index + 1}
									</TableCell>
									<TableCell align="right">{item.userNickname}</TableCell>
									<TableCell align="right">{item.attributeName}</TableCell>
									<TableCell align="right">{item.locationName}</TableCell>
									<TableCell align="right">
										{formatUTCDateTime(item.createdAt)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</BoxListData>
		</CardLayoutComponent>
	);
};

export default DashboardWashedHistory;

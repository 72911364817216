import React from 'react'
import { Box, CardContent, Card } from "@mui/material";
import Footer from "./Footer";
import { styled, createTheme } from '@mui/material/styles';

interface BlankLayoutProps {
    children: React.ReactElement;
}
const theme = createTheme();

const SignCard = styled(Card)({
    padding: theme.spacing(2),
    maxWidth: 400,
    margin: "0 1rem 0 1rem",
    backgroundColor: "#e9f6fe"
});
const BoxContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
});
  
const BlankLayout = ({children} : BlankLayoutProps) => {
  return (
    <BoxContainer>
        <Box component="main" sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", margin: "1rem 0" }}>
            <SignCard sx={{ padding: 0 }}>
                <CardContent sx={{ padding: 0 }}>
                    { children }
                </CardContent>
            </SignCard>
        </Box>

        <Footer />
    </BoxContainer>
  )
}

export default BlankLayout

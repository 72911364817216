import { useQueryClient } from "@tanstack/react-query";
import { QueryKey } from "components/utils/constants/QueryKey";
import { IResponseCurrentUser } from "interfaces";
import ForgotPasswordEmail from "./ForgotPasswordEmail";
import ForgotPasswordLoginId from "./ForgotPasswordLoginId";

const ForgotPassword = () => {
	const queryClient = useQueryClient();
	const data = queryClient.getQueryData([
		QueryKey.GET_AUTH_USER,
	]) as IResponseCurrentUser;

	if (data.userProviderType === "email") return <ForgotPasswordEmail />;
	else if (data.userProviderType === "customer_id")
		return <ForgotPasswordLoginId />;
	else return <></>;
};

export default ForgotPassword;

import { Box } from "@mui/material";
import CardLayoutComponent from "components/utils/CardLayoutComponent";
import { ICountUserWashedChart } from "interfaces/Dashboard";
import { useGetDataChartUserWashed } from "lib/api/dashboard";
import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";

const DashboardDataChart = () => {
	const { data } = useGetDataChartUserWashed();
	const sizeRef = useRef<HTMLDivElement>(null);
	const [chartWidth, setChartWidth] = useState(0);

	useEffect(() => {
		sizeRef?.current?.offsetWidth &&
			setChartWidth(sizeRef?.current?.offsetWidth);
	}, [sizeRef?.current?.offsetWidth]);

	const x = Object.keys(data || {}).map((item: string) => item.split("_")[2]);
	const y1 = (Object.values(data || {}) as ICountUserWashedChart[]).map(
		(item) => item.washedCount
	);
	const y2 = (Object.values(data || {}) as ICountUserWashedChart[]).map(
		(item) => item.userUsedCount
	);

	const y1Max = Math.max(...y1);
	const y1Len = Math.floor(y1Max).toString().length;
	const y1Pow10Divisor = Math.pow(10, y1Len - 1);
	const y1Firstdigit = Math.floor(y1Max / y1Pow10Divisor);
	const y1MaxBase = y1Pow10Divisor * y1Firstdigit;

	const y2Max = Math.max(...y2);
	const y2Len = Math.floor(y2Max).toString().length;
	const y2Pow10Divisor = Math.pow(10, y2Len - 1);
	const y2Firstdigit = Math.floor(y2Max / y2Pow10Divisor);
	const y2MaxBase = y2Pow10Divisor * y2Firstdigit;

	let dtickDivisor = 5;

	const y1Dtick = Math.ceil(y1MaxBase / dtickDivisor);
	const y2Dtick = Math.ceil(y2MaxBase / dtickDivisor);

	const y1DtickRatio = y1Max / y1Dtick;
	const y2DtickRatio = y2Max / y2Dtick;

	const globalDtickRatio = Math.ceil(Math.max(y1DtickRatio, y2DtickRatio));

	const y1RangeMax = Math.ceil(globalDtickRatio * y1Dtick);
	const y2RangeMax = Math.ceil(globalDtickRatio * y2Dtick);

	return (
		<CardLayoutComponent title="アクティブユーザーと洗浄回数の推移">
			<Box
				sx={{
					width: "100%",
					height: "100%",
				}}
				ref={sizeRef}
			>
				<Plot
					data={[
						{
							name: "洗浄回数の推移",
							x: x,
							y: y1,
							type: "bar",
							mode: "lines+markers",
							marker: { color: "#0A97D9" },
							line: { shape: "hvh" },
							xaxis: "x",
							yaxis: "y1",
						},
						{
							name: "アクティブユーザー数推移",
							x: x,
							y: y2,
							mode: "lines+markers",
							textposition: "auto",
							type: "scatter",
							marker: { color: "#F47D21", size: 8 },
							xaxis: "x",
							yaxis: "y2",
						},
					]}
					layout={{
						width: chartWidth,
						height: 300,
						showlegend: true,
						legend: {
							orientation: "h",
							y: 10,
							x: 0.5,
							xanchor: "center",
							yanchor: "bottom",
							font: {
								size: 20,
							},
						},
						xaxis: {
							type: "category",
						},
						yaxis: {
							title: "洗浄回数の推移",
							range: [0, y1RangeMax],
							dtick: y1Dtick,
						},
						yaxis2: {
							title: "アクティブユーザー数推移",
							rangemode: "tozero",
							showgrid: false,
							tickmode: "sync" as any,
							overlaying: "y",
							range: [0, y2RangeMax],
							dtick: y2Dtick,
							side: "right",
						},
						margin: {
							t: 0,
							l: 70,
							r: 70,
							b: 40,
						},
					}}
					config={{
						displaylogo: false,
					}}
					style={{
						display: "block",
					}}
				/>
			</Box>
		</CardLayoutComponent>
	);
};

export default DashboardDataChart;

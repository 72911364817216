import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CardLayoutComponent from "components/utils/CardLayoutComponent";
import { useGetDataHourPerDay } from "lib/api/dashboard";
import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";

export const BoxItemWrapper = styled(Box)({
	backgroundColor: "white",
	padding: 20,
	borderRadius: 15,
});

const DashboardTimeCount = () => {
	const { data } = useGetDataHourPerDay();

	const sizeRef = useRef<HTMLDivElement>(null);
	const [chartWidth, setchartWidth] = useState(0);

	useEffect(() => {
		sizeRef?.current?.offsetWidth &&
			setchartWidth(sizeRef?.current?.offsetWidth);
	}, [sizeRef?.current?.offsetWidth]);

	return (
		<CardLayoutComponent title="時間別の平均洗浄回数">
			<Box
				sx={{
					width: "100%",
					height: "100%",
				}}
				ref={sizeRef}
			>
				<Plot
					data={[
						{
							x: Object.keys(data || {}),
							y: (Object.values(data || {}) as number[]).map((item) =>
								item.toString()
							),
							text: (Object.values(data || {}) as number[]).map((item) =>
								item.toString()
							),
							type: "bar",
							mode: "lines+markers",
							marker: {
								color: "#0A97D9",
							},
							line: { shape: "hvh" },
						},
					]}
					layout={{
						width: chartWidth,
						height: 300,
						showlegend: false,
						margin: {
							t: 10,
							b: 30,
						},
						xaxis: {
							tickmode: "array",
							tickvals: Array.from({ length: 24 }, (_, i) => i.toString()),
							ticktext: Array.from({ length: 24 }, (_, i) => i.toString()),
						},
					}}
					config={{
						displaylogo: false,
					}}
				/>
			</Box>
		</CardLayoutComponent>
	);
};

export default DashboardTimeCount;

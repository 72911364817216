import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CardLayoutComponent from "components/utils/CardLayoutComponent";
import { useGetDataDashboardCoupon } from "lib/api/dashboard";
import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";

export const BoxItemWrapper = styled(Box)({
	backgroundColor: "white",
	padding: 20,
	borderRadius: 15,
});

const DashboardCoupon = () => {
	const { data } = useGetDataDashboardCoupon();
	const sizeRef = useRef<HTMLDivElement>(null);
	const [chartWidth, setChartWidth] = useState(0);

	useEffect(() => {
		sizeRef?.current?.offsetWidth &&
			setChartWidth(sizeRef?.current?.offsetWidth);
	}, [sizeRef?.current?.offsetWidth]);
	return (
		<CardLayoutComponent title="クーポン利用状況">
			<Box
				sx={{
					width: "100%",
					height: 300,
				}}
				ref={sizeRef}
			>
				{!!data?.couponCount ? (
					<Plot
						data={[
							{
								values: [
									data?.usedCoupon || 0,
									data?.unusedCoupon || 0,
									data?.expiredCoupon || 0,
								],
								labels: ["使用済み", "未使用", "期限切れ"],
								type: "pie",
								textinfo: "value",
								legendgrouptitle: {
									text: `総発行数：${data?.couponCount || 0}`,
									font: {
										size: 20,
									},
								},
								textfont: {
									size: 16,
								},
								hole: 0.2,
							},
						]}
						layout={{
							width: chartWidth,
							height: 300,
							legend: {
								orientation: "h",
								y: -0.1,
								x: 0.5,
								xanchor: "center",
								yanchor: "top",
								font: {
									size: 20,
								},
							},
							margin: {
								l: 0,
								r: 0,
								b: 0,
								t: 0,
							},
							xaxis: {
								fixedrange: true,
							},
							yaxis: {
								fixedrange: true,
							},
						}}
						config={{
							displaylogo: false,
						}}
					/>
				) : (
					<Typography sx={{ zIndex: 10 }}>
						現在クーポンの発行はございません
					</Typography>
				)}
			</Box>
		</CardLayoutComponent>
	);
};

export default DashboardCoupon;

import React, { useState, useContext } from "react"
import { useNavigate, Link as RouterLink } from "react-router-dom"

import { styled, createTheme } from '@mui/material/styles';
import { Typography, Button, Box, TextField, Link } from "@mui/material"

import { AuthContext } from "App"
import { useSignIn } from "lib/api/auth"
import { IResponseCurrentUser, SignInParams } from "interfaces"
import { AppUrl } from "components/utils/constants/AppUrl";
import { useGetImageByType } from "lib/api/images";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKey } from "components/utils/constants/QueryKey";

const theme = createTheme();

const SignBox = styled(Box)({
  marginTop: "1.5rem"
});

const SignSubmitButton = styled(Button)({
  marginTop: theme.spacing(2),
  flexGrow: 1,
  textTransform: "none",
});

// サインイン用ページ
const SignIn: React.FC = () => {
  const navigate = useNavigate()
  const { refetch } = useContext(AuthContext)
  
  const [userProvider, setUserProvider] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData([QueryKey.GET_AUTH_USER]) as IResponseCurrentUser;

  const handleSignIn = useSignIn((res) => {
    refetch()
    if (!res.hasBottle && res.roles.includes('normal')) {
      navigate(AppUrl.REGISTER_MY_BOTTLE)
    } else {
      navigate(AppUrl.HOME)
    }
  })
  const { data: image, isLoading } = useGetImageByType('SignIn')

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const params: SignInParams = {
      email: data?.userProviderType === 'email' ? userProvider : undefined,
      customer_id: data?.userProviderType === 'customer_id' ? userProvider : undefined,
      password,
    }
    handleSignIn.mutate(params)
  }

  return (
    <>
      {
        !isLoading && (
          <>
            <Box
              component="img"
              src={image?.data?.value}
              alt={image?.data?.name}
              sx={{ width: "100%", height: "100%", marginBottom: "2rem" }}
              display="block"
            />
            <Box sx={{ padding: '0 32px 16px' }}>
              <form noValidate autoComplete="off">
                <TextField
                  sx={{ backgroundColor: "#ffffff" }}
                  variant="outlined"
                  required
                  fullWidth
                  label={ data?.userProviderType === 'email' ? 'メールアドレス' : data?.userProviderType === 'customer_id' ? 'ログインID' : '' }
                  value={userProvider}
                  margin="dense"
                  onChange={event => setUserProvider(event.target.value)}
                />
                <TextField
                  sx={{ backgroundColor: "#ffffff" }}
                  variant="outlined"
                  required
                  fullWidth
                  label="パスワード"
                  type="password"
                  value={password}
                  margin="dense"
                  autoComplete="off"
                  onChange={event => setPassword(event.target.value)}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%', margin: 'auto' }}>
                  <SignSubmitButton
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    color="primary"
                    disabled={!userProvider || !password || handleSignIn.isPending} // 空欄があった場合はボタンを押せないように
                    onClick={handleSubmit}
                  >
                    ログイン
                  </SignSubmitButton>
                </Box>
                <SignBox textAlign="center">
                  {
                    data?.forgotPasswordType !== "not_available" && (
                    <Box>
                      <Link component={RouterLink} to={AppUrl.FORGOT_PASSWORD}>
                        <Typography variant="body2">
                          パスワードをお忘れですか？
                        </Typography>
                      </Link>
                    </Box>
                    )
                  }
                  {
                    data?.isSignup === 'available' && (
                      <Box sx={{ margin: '1.5rem 0 0 0' }}>
                        <Box>
                          <Typography variant="body2">
                            アカウントをお持ちではない場合は
                          </Typography>
                        </Box>
                        <Link component={RouterLink} to={AppUrl.SIGN_UP}>
                          <Typography variant="body2">
                            こちらから登録！
                          </Typography>
                        </Link>
                      </Box>
                    )
                  }
                </SignBox>
              </form>
            </Box>
          </>
        )
      }
    </>
  )
}

export default SignIn
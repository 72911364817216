import { Box, Button, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "1px solid #000",
	boxShadow: 24,
	p: 4,
	borderRadius: 10,
};
const BoxWrapper = styled(Box)({
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	gap: 5,
	alignItems: "center",
});

interface ModalStopSurveyProps {
	open: boolean;
	onClose: () => void;
	message: string;
}
const ModalStopSurvey = ({ open, onClose, message }: ModalStopSurveyProps) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<BoxWrapper>
					<Typography fontSize={18} textAlign="center">
						{message}
					</Typography>
					<Button
						variant="contained"
						size="large"
						sx={{
							borderRadius: 2,
							width: 100,
							height: 50,
							marginTop: 2,
						}}
						onClick={(e) => {
							e.preventDefault();
							onClose();
						}}
					>
						閉じる
					</Button>
				</BoxWrapper>
			</Box>
		</Modal>
	);
};

export default ModalStopSurvey;

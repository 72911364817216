import { useState, useEffect } from "react";
import { onlineManager } from "@tanstack/react-query";
import AlertMessage from "./AlertMessage";


const NetworkStatus = () => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		onlineManager.setEventListener((setOnline) => {
			const onlineHandler = () => {
				const online = navigator.onLine;
				setOnline(online);
				setIsOnline(online);
			};

			window.addEventListener("online", onlineHandler);
			window.addEventListener("offline", onlineHandler);

			return () => {
				window.removeEventListener("online", onlineHandler);
				window.removeEventListener("offline", onlineHandler);
			};
		});
	}, []);

	return (
    <AlertMessage
      open={!isOnline}
			autoHideDuration={undefined}
      setOpen={() => {}}
      severity="error"
      message="インターネット接続を確認してください。"
    />
	);
};

export default NetworkStatus;

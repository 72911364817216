import { useState, useContext } from 'react'
import { Box, Typography, Button, Select, MenuItem, Pagination, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, tableCellClasses } from "@mui/material"
import { styled } from '@mui/material/styles';
import { IResponseCurrentUser } from 'interfaces';
import ModalShowUser from 'components/utils/ModalShowUser';
import ModalDeleteUser from 'components/utils/ModalDeleteUser';
import { useSearchParams } from 'react-router-dom';
import { AppUrl } from 'components/utils/constants/AppUrl'
import { useNavigate } from "react-router-dom"
import { useOwnerGetListUser } from 'lib/api/admin';
import { formatUTCTime } from 'lib/common/date.helper'
import { AuthContext } from 'App';
import CircularProgressBox from 'components/utils/CircularProgressBox';
import ModalChangePasswordUser from 'components/utils/ModalChangePassword';

const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center',
    width: '100%',
    margin: '0px auto',
});

const BoxRowPerPage = styled(Box)({
    display: "flex",
    justifyContent: 'space-between',
    gap: 5,
    width: '100%',
});
const BoxUsers = styled(Box)({
    display: "flex",
    flexDirection: 'column',
    gap: 5,
    width: '100%',
    margin: '10px 0px',
});
const BoxCreateUsers = styled(Box)({
    display: "flex",
    flexDirection: 'column',
    alignItems: 'end',
    gap: 5,
    width: '100%',
    margin: '10px 0px',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const UserManagement = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page: number = Number(searchParams.get('page')) || 1;
    const per: number = Number(searchParams.get('per')) || 25;
    const navigate = useNavigate()
    const { data, refetch, isLoading } = useOwnerGetListUser({ page, per })
    const { user } = useContext(AuthContext)

    const [openModalViewUser, setOpenModalViewUser] = useState<{
        open: boolean
        data?: IResponseCurrentUser['data']
    }>({
        open: false,
        data: undefined
    })
    const [openModalDeleteUser, setOpenModalDeleteUser] = useState<{
        open: boolean
        data?: IResponseCurrentUser['data']
    }>({
        open: false,
        data: undefined
    })
    const [openModalChangePasswordUser, setOpenModalChangePasswordUser] = useState<{
        open: boolean
        data?: IResponseCurrentUser['data']
    }>({
        open: false,
        data: undefined
    })

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString());
        setSearchParams(searchParams);
    };

    return (
        <BoxWrapper>
            <BoxRowPerPage>
                <Typography sx={{ fontSize: 20, color: '#1976D2', fontWeight: 'bold' }}>
                    ユーザー一覧
                </Typography>
                <Select
                    value={per}
                    onChange={(e) => {
                        searchParams.set("per", e.target.value.toString());
                        searchParams.set("page", '1');
                        setSearchParams(searchParams);
                    }}
                    sx={{ minWidth: 100 }}
                >
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </BoxRowPerPage>
            {isLoading ? (
                <Box sx={{ width: "100%" }}>
                    <CircularProgressBox />
                </Box>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ width: '100%'}} aria-label="table user">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ fontSize: 16 }}>ニックネーム</StyledTableCell>
                                <StyledTableCell align='center' sx={{ fontSize: 16 }}>権限</StyledTableCell>
                                <StyledTableCell align='right' sx={{ fontSize: 16 }}>Control</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                data?.data?.data?.map((userData) => (
                                    <StyledTableRow key={userData.id}>
                                            <StyledTableCell>
                                                <Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setOpenModalViewUser({ open: true, data: userData })}>ID: {user?.userProviderType === "email" ? userData?.email : userData?.customerId}</Typography>
                                                <Typography sx={{ fontSize: 14 }}>登録日：{formatUTCTime(userData.createdAt)}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align='center'>
                                                <Typography>{userData?.roles?.map(role => role === 'admin' ? '管理者' : role === 'normal' ? '一般ユーザー' : role === 'wash_bottle' ? '洗浄機' : '')?.join(', ')}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align='right' sx={{ display: "flex", gap: 1}}>
                                                <Button variant="contained" disabled={userData?.id === user?.data?.id} onClick={() => setOpenModalDeleteUser({ open: true, data: userData })}>
                                                    削除
                                                </Button>
                                                <Button variant="contained" disabled={userData?.id === user?.data?.id} onClick={() => setOpenModalChangePasswordUser({ open: true, data: userData })}>
                                                    パスワード再発行
                                                </Button>
                                            </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <BoxUsers>
                
            </BoxUsers>
            <Pagination page={Number(page)} count={data?.data?.totalPages} onChange={handleChangePage} siblingCount={1} boundaryCount={0}/>
            <BoxCreateUsers>
                <Box sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    navigate(AppUrl.USER_MANAGEMENT_SIGN_UP)
                }}>
                    ユーザー登録
                </Box>
            </BoxCreateUsers>
            <ModalShowUser open={openModalViewUser.open} data={openModalViewUser.data} setOpen={(e) => setOpenModalViewUser({ open: e, data: undefined })} />
            <ModalDeleteUser open={openModalDeleteUser.open} data={openModalDeleteUser.data} onCancel={() => setOpenModalDeleteUser({ open: false, data: undefined })} onOk={() => {
                refetch()
                setOpenModalDeleteUser({ open: false, data: undefined })
            }} />
            <ModalChangePasswordUser open={openModalChangePasswordUser.open} data={openModalChangePasswordUser.data} onCancel={() => setOpenModalChangePasswordUser({ open: false, data: undefined })} onOk={() => {
                refetch()
                setOpenModalChangePasswordUser({ open: false, data: undefined })
            }} />
        </BoxWrapper>
    )
}

export default UserManagement
import { memo } from "react";
import { Box } from "@mui/material";
import { keyframes } from "@mui/system";

interface CleanSystemWelcomeProps {
  timeWait: number;
  timeZoomInZoomOut: number;
  timeFirstImage: number;
  timeSecondImage: number;
  timeThirdImage: number;
  timeFouthImage: number;
}

const CleanSystemSetting = memo(({
  timeWait,
  timeZoomInZoomOut,
  timeFirstImage,
  timeSecondImage,
  timeThirdImage,
  timeFouthImage,
}: CleanSystemWelcomeProps) => {
  const changeImageEffect1 = keyframes`
        0% {
            z-index: 1000;
            transform: scale(1);
        }
        ${(timeZoomInZoomOut / timeFirstImage) * 100}% {
            z-index: 1000;
            transform: scale(2);
        }
        ${100 - (timeZoomInZoomOut / timeFirstImage) * 100}% {
            z-index: 1000;
            transform: scale(2);
        }
        100% {
            z-index: 1000;
            transform: scale(1);
        }
    `;
  const changeImageEffect2 = keyframes`
        0% {
            z-index: 1000;
            transform: scale(1);
        }
        ${(timeZoomInZoomOut / timeSecondImage) * 100}% {
            z-index: 1000;
            transform: scale(2);
        }
        ${100 - (timeZoomInZoomOut / timeSecondImage) * 100}% {
            z-index: 1000;
            transform: scale(2);
        }
        100% {
            z-index: 1000;
            transform: scale(1);
        }
    `;
  const changeImageEffect3 = keyframes`
        0% {
            z-index: 1000;
            transform: scale(1);
        }
        ${(timeZoomInZoomOut / timeThirdImage) * 100}% {
            z-index: 1000;
            transform: scale(2);
        }
        ${100 - (timeZoomInZoomOut / timeThirdImage) * 100}% {
            z-index: 1000;
            transform: scale(2);
        }
        100% {
            z-index: 1000;
            transform: scale(1);
        }
    `;
  const changeImageEffect4 = keyframes`
        0% {
            z-index: 1000;
            transform: scale(1);
        }
        ${(timeZoomInZoomOut / timeFouthImage) * 100}% {
            z-index: 1000;
            transform: scale(2);
        }
        ${100 - (timeZoomInZoomOut / timeFouthImage) * 100}% {
            z-index: 1000;
            transform: scale(2);
        }
        100% {
            z-index: 1000;
            transform: scale(1);
        }
    `;
  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        marginTop: "70px",
      }}
      className="animation-change"
    >
      <Box
        component="img"
        sx={{
          height: "50%",
          width: "50%",
          objectFit: "cover",
          display: "inline-block",
          position: "relative",
          transformOrigin: "top left",
          animation: `${timeFirstImage}ms ${changeImageEffect1} ease-in-out ${timeWait}ms`,
        }}
        alt="Image Clean Setting 1"
        src="/clean_system/D23-00069-7A-14Ayoko-01.png"
      />
      <Box
        component="img"
        sx={{
          height: "50%",
          width: "50%",
          objectFit: "cover",
          display: "inline-block",
          position: "relative",
          transformOrigin: "top right",
          animation: `${timeSecondImage}ms ${changeImageEffect2} ease-in-out ${
            timeWait + timeFirstImage
          }ms`,
        }}
        alt="Image Clean Setting 2"
        src="/clean_system/D23-00069-7A-14Ayoko-02.png"
      />
      <Box
        component="img"
        sx={{
          height: "50%",
          width: "50%",
          objectFit: "cover",
          display: "inline-block",
          position: "relative",
          transformOrigin: "bottom left",
          animation: `${timeThirdImage}ms ${changeImageEffect3} ease-in-out ${
            timeWait + timeFirstImage + timeSecondImage
          }ms`,
        }}
        alt="Image Clean Setting 3"
        src="/clean_system/D23-00069-7A-14Ayoko-03.png"
      />
      <Box
        component="img"
        className="animation-change-image-4"
        sx={{
          height: "50%",
          width: "50%",
          objectFit: "cover",
          display: "inline-block",
          position: "relative",
          transformOrigin: "bottom right",
          animation: `${timeFouthImage}ms ${changeImageEffect4} ease-in-out ${
            timeWait + timeFirstImage + timeSecondImage + timeThirdImage
          }ms`,
        }}
        alt="Image Clean Setting 4"
        src="/clean_system/D23-00069-7A-14Ayoko-04.png"
      />
    </Box>
  );
});

export default CleanSystemSetting;

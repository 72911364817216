import React, { useState, useContext, ChangeEvent } from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "App"
import { AlertContext } from "App";

import { styled, createTheme } from '@mui/material/styles';
import { TextField, CardContent, Alert, Button, Box, Snackbar } from "@mui/material"

import { useSignUp } from "lib/api/auth"
import { fileToBase64 } from 'lib/common/convert-data'
import { IResponseCurrentUser, SignUpParams } from "interfaces";
import { AppUrl } from "components/utils/constants/AppUrl";
import AvatarDefault from 'components/utils/AvatarDefault'
import { QueryKey } from "components/utils/constants/QueryKey";
import { useQueryClient } from "@tanstack/react-query";

const theme = createTheme();

const SignSubmitButton = styled(Button)({
  marginTop: theme.spacing(2),
  flexGrow: 1,
  textTransform: "none"
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// サインアップ用ページ
const SignUp: React.FC = () => {
  const navigate = useNavigate()
  const { refetch } = useContext(AuthContext)
  const [signUpData, setSignUpData] = useState<SignUpParams>({
    email: '',
    customer_id: '',
    password: '',
    nickname: '',
    imageUrl: '',
    passwordConfirmation: ''
  })
  const [showMessage, setShowMessage] = useState<{
    open: boolean
    message: string | string[]
  }>({
    open: false,
    message: '',
  })
  const { setModalAlert } = useContext(AlertContext)
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData([QueryKey.GET_AUTH_USER]) as IResponseCurrentUser;


  const handleSignUp = useSignUp(() => {
    refetch()
    navigate(AppUrl.REGISTER_MY_BOTTLE)
  }, (err) => {
    setShowMessage({ open: true, message: (err?.response?.data?.errors as any)?.fullMessages || 'エラー' })
  })
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    for (let key in signUpData) {
      if (signUpData[key as keyof SignUpParams] === '') {
        delete signUpData[key as keyof SignUpParams];
      }
    }
    handleSignUp.mutate(signUpData)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSignUpData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  return (
    <form noValidate autoComplete="off" style={{ position: 'relative' }}>
      <CardContent>
        <TextField
          name={data?.userProviderType}
          sx={{ backgroundColor: "#ffffff" }}
          variant="outlined"
          required
          fullWidth
          margin="dense"
          autoComplete="off"
          label={ data?.userProviderType === 'email' ? 'メールアドレス' : data?.userProviderType === 'customer_id' ? 'ログインID' : '' }
          value={data?.userProviderType === 'email' ? signUpData.email : data?.userProviderType === 'customer_id' ? signUpData.customer_id : ''}
          onChange={handleChange}
          />
        <TextField
          name='password'
          sx={{ backgroundColor: "#ffffff" }}
          variant="outlined"
          required
          fullWidth
          margin="dense"
          autoComplete="off"
          type="password"
          label="パスワード"
          value={signUpData.password}
          onChange={handleChange}
        />
        <TextField
          name='passwordConfirmation'
          sx={{ backgroundColor: "#ffffff" }}
          variant="outlined"
          required
          fullWidth
          margin="dense"
          autoComplete="off"
          type="password"
          label="パスワード確認"
          value={signUpData.passwordConfirmation}
          onChange={handleChange}
        />
        <Box
          sx={{
            margin: '5px auto 0px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <AvatarDefault width={150} height={150} src={signUpData.imageUrl} text={signUpData?.nickname?.charAt(0)} />
        </Box>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          component="label"
          sx={{
            margin: '1rem 0px'
          }}
        >
          アバターアップロード（任意）
          <VisuallyHiddenInput type="file" accept={window.innerWidth >= 600 ? ".png, .jpg, .jpeg" : undefined} onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length) {
              if (e.target.files[0]?.type !== 'image/png' && e.target.files[0]?.type !== 'image/jpeg' && e.target.files[0]?.type !== 'image/jpg') {
                setModalAlert({ isOpen: true, message: '不明な画像形式です', type: 'error' })
              } else if (e.target.files[0]?.size > 5000000) {
                setShowMessage({
                  open: true,
                  message: '画像は5MB以下でアップロードしてください',
                })
              } else {
                const convertString = await fileToBase64(e.target.files[0]) || ''
                setSignUpData({ ...signUpData, imageUrl: convertString })
              }
            }
          }} />
        </Button>
        <TextField
          name='nickname'
          sx={{ backgroundColor: "#ffffff" }}
          variant="outlined"
          required
          fullWidth
          margin="dense"
          autoComplete="off"
          label="ニックネーム"
          inputProps={{ maxLength: 10 }}
          value={signUpData.nickname}
          onChange={handleChange}
        />
        <small className="">※ ニックネームはあとから変更することはできません
        </small>

        <SignSubmitButton
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          disabled={handleSignUp.isPending || !signUpData.nickname || (data?.userProviderType === 'customer_id' && !signUpData.customer_id) || (data?.userProviderType === 'email' && !signUpData.email) || !signUpData.password || !signUpData.passwordConfirmation}
          onClick={handleSubmit}
        >
          送信
        </SignSubmitButton>
      </CardContent>
      {
        showMessage.open && (
          <Snackbar
            open={showMessage.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => setShowMessage({
              open: false,
              message: '',
            })}
          >
            <Alert onClose={() => setShowMessage({
              open: false,
              message: '',
            })} severity='error' sx={{
              '& .MuiAlert-icon': {
                margin: 'auto', marginRight: 1
              }
            }}>
              {typeof showMessage.message === 'string' ? showMessage.message : (showMessage.message as string[])?.map((msg, index) => <p key={msg+index}>{msg}</p>)}

            </Alert>
          </Snackbar>
        )
      }
    </form>
  )
}

export default SignUp
import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import { BaseResponse, ErrorResponse, IACreateAndUpdateCoupon, IAListCoupon } from "interfaces";
import { AxiosError } from "axios";

export const useAdminGetListCoupon = (params: {
	page: number;
	per: number;
	name: string;
}) => {
	return useQuery({
		queryKey: ["ADMIN_GET_LIST_COUPON", params.page, params.per, params.name],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				`/admin/coupons/filtering?page=${params.page}&per=${params.per}&name=${params.name}`
			);
			return res as BaseResponse<IAListCoupon>;
		},
		enabled: !!params.page && !!params.per,
	});
};

export const useAdminGetDetailCoupon = (id: number) => {
	return useQuery({
		queryKey: ["ADMIN_GET_DETAIL_COUPON", id],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(`/admin/coupons/${id}`);
			return res as BaseResponse<IAListCoupon["data"][number]>;
		},
		enabled: !!id,
	});
};

export const useAdminCreateCoupon = (
	onOk?: (res: BaseResponse) => void,
	onErr?: (err: AxiosError<ErrorResponse>) => void
) => {
	return useMutation({
		mutationKey: ["ADMIN_CREATE_COUPON"],
		mutationFn: ( body : IACreateAndUpdateCoupon) =>
			axiosInstanceAuth.post("/admin/coupons", body),
		onSuccess: (res) => {
			onOk && onOk(res as BaseResponse);
		},
		onError: (err) => {
			onErr && onErr(err as AxiosError<ErrorResponse>);
		},
	});
};
export const useAdminUpdateCoupon = (
	onOk?: (res: BaseResponse) => void,
	onErr?: (err: AxiosError<ErrorResponse>) => void
) => {
	return useMutation({
		mutationKey: ["ADMIN_UPDATE_COUPON"],
		mutationFn: ({id, body}: { id: number, body : IACreateAndUpdateCoupon}) =>
			axiosInstanceAuth.patch(`/admin/coupons/${id}`, body),
		onSuccess: (res) => {
			onOk && onOk(res as BaseResponse);
		},
		onError: (err) => {
			onErr && onErr(err as AxiosError<ErrorResponse>);
		},
	});
};
import { useState, useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AlertContext } from "App";
import { ErrorResponse } from "interfaces";
import { AxiosError } from "axios";
import { useGetDataAll } from "lib/api/admin";

const BoxWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	gap: 5,
	width: "400px",
	margin: "0px auto",
});
const BoxCenter = styled(Box)({
	display: "flex",
	flexDirection: "column",
	gap: 3,
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
});
const DataManagement = () => {
	const [startDate, setStartDate] = useState<Dayjs | null>(null);
	const [endDate, setEndDate] = useState<Dayjs | null>(null);
	const { setModalAlert } = useContext(AlertContext);

	const handleGetData = useGetDataAll(
		(data: Blob) => {
			const url = URL.createObjectURL(data);
			const link = document.createElement("a");
			link.setAttribute("href", url);
			const fileName = `${dayjs().format("YYMMDD")}.zip`;
			link.setAttribute("download", fileName);
			link.click();
			setStartDate(null);
			setEndDate(null);
		},
		async (err: AxiosError<ErrorResponse>) => {
			const errText = await err?.response?.data?.text();
			setModalAlert({
				isOpen: true,
				message: JSON.parse(errText)?.message || "エラー",
				type: "error",
			});
		}
	);

	const handleSubmit = () => {
		if (!startDate) {
			setModalAlert({
				isOpen: true,
				message: "開始日を入力してください",
				type: "warning",
			});
		} else if (!endDate) {
			setModalAlert({
				isOpen: true,
				message: "終了日を入力してください",
				type: "warning",
			});
		} else {
			handleGetData.mutate({
				startDate: startDate?.format("YYYY-MM-DD"),
				endDate: endDate?.format("YYYY-MM-DD"),
			});
		}
	};

	return (
		<BoxWrapper>
			<Typography
				sx={{ fontSize: 20, fontWeight: "bold" }}
				color="primary"
			>
				期間集計
			</Typography>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoContainer components={["DatePicker"]}>
					<BoxCenter>
						<Typography
							sx={{
								textAlign: "left",
								width: "70%",
								marginBottom: 1,
							}}
						>
							開始日
						</Typography>
						<DatePicker
							value={startDate}
							onChange={(e: Dayjs | null, context) => {
								setStartDate(e);
							}}
							disableFuture={true}
						/>
						<Typography
							sx={{
								textAlign: "left",
								width: "70%",
								marginBottom: 1,
								marginTop: 1,
							}}
						>
							終了日
						</Typography>
						<DatePicker
							value={endDate}
							onChange={(e: Dayjs | null, context) => {
								setEndDate(e);
							}}
							disableFuture={true}
						/>
						<Button
							variant="contained"
							size="large"
							sx={{ marginTop: 5 }}
							onClick={handleSubmit}
							disabled={handleGetData.isPending}
						>
							ダウンロード
						</Button>
					</BoxCenter>
				</DemoContainer>
			</LocalizationProvider>
		</BoxWrapper>
	);
};

export default DataManagement;

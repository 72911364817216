import React, { useState } from "react"
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles';
import "./index.css"
import theme from 'components/style/global';
import App from "./App"
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TagManager from 'react-gtm-module'
import { gtmId } from "components/utils/constants/AppEnv";
import AlertMessage from "components/utils/AlertMessage";

const container = document.getElementById('root')!;
const root = createRoot(container);

if (gtmId) {
  const tagManagerArgs = {
    gtmId: gtmId,
    dataLayerName: 'sustSuccessCo2IndStage'
  }
  TagManager.initialize(tagManagerArgs)
}

function AppWrapper() {
  const [isError, setIsError] = useState(false);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        networkMode: 'online',
        retry: 5, // ５回
        retryDelay: 10000, //１０秒
      },
    },
    queryCache: new QueryCache({
      onError: () => {
        setIsError(true);
      },
      onSuccess: () => {
        setIsError(false);
      }
    }),
  });
  

  return (
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <App />
            <AlertMessage
              open={isError}
              autoHideDuration={undefined}
              setOpen={() => {}}
              severity="error"
              message="通信が不安定です。しばらくしてから再度アクセスしてください。"
            />
          </ThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

root.render(<AppWrapper />);
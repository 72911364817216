import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import { BaseResponse, ErrorResponse, IACreateEvent, IAEventDetail, IAListEvents,  } from "interfaces";
import { AxiosError } from "axios";

export const useAdminGetListEvents = (params: {
	page: number;
	per: number;
	name: string;
}) => {
	return useQuery({
		queryKey: ["ADMIN_GET_LIST_EVENTS", params.page, params.per, params.name],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				`/admin/events/filtering?page=${params.page}&per=${params.per}&name=${params.name}`
			);
			return res as BaseResponse<IAListEvents>;
		},
		enabled: !!params.page && !!params.per,
	});
};

export const useAdminGetDetailEvent = (id: number) => {
	return useQuery({
		queryKey: ["ADMIN_GET_DETAIL_EVENT", id],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(`/admin/events/${id}`);
			return res as BaseResponse<IAEventDetail>;
		},
		enabled: !!id,
	});
};

export const useAdminCreateEvent = (
	onOk?: (res: BaseResponse) => void,
	onErr?: (err: AxiosError<ErrorResponse>) => void
) => {
	return useMutation({
		mutationKey: ["ADMIN_CREATE_EVENT"],
		mutationFn: ( body : IACreateEvent) =>
			axiosInstanceAuth.post("/admin/events", body),
		onSuccess: (res) => {
			onOk && onOk(res as BaseResponse);
		},
		onError: (err) => {
			onErr && onErr(err as AxiosError<ErrorResponse>);
		},
	});
};

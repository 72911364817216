import React from "react";
import CreateSurveyStep3 from "./CreateSurvey/Step3";
import { useNavigate, useParams } from "react-router-dom";
import { AppUrl } from "components/utils/constants/AppUrl";
import { useAdminGetDetailSurvey } from "lib/api/admin-survey";
import CircularProgressBox from "components/utils/CircularProgressBox";

const SurveyDetail = () => {
	const { id } = useParams();
	const { data, isLoading } = useAdminGetDetailSurvey(Number(id));

	const navigate = useNavigate();
	return (
		<>
			{isLoading ? (
				<CircularProgressBox />
			) : (
				data?.data?.id && (
					<CreateSurveyStep3
						surveyData={{
							code: data?.data?.code,
							feedback_status_attributes: {
								start: data?.data?.feedbackStatus.start,
								end: data?.data?.feedbackStatus.end,
							},
							name: data?.data?.name,
							value: data?.data?.value,
						}}
						onCancel={() => {
							navigate(AppUrl.SURVEY_MANAGEMENT);
						}}
						onSubmit={() => {}}
						isView={true}
					/>
				)
			)}
		</>
	);
};

export default SurveyDetail;

import React from "react";
import { Container, Grid, Box, Card, CardHeader, CardContent, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import Header from "./Header";
import Footer from "./Footer";
import SafeArea from './SafeArea';

interface CardLayoutProps {
  children: React.ReactElement;
  noMarginTop?: boolean
  title: string
  center?: boolean
  noHeader?: boolean
}

const MainBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: "0%",
  display: 'grid',
  flexDirection: 'column',
  padding: '0.5rem 6px',
  height: "100%",
  [theme.breakpoints.up('sm')]: {
    padding: '0 2rem 0 2rem',
  },
  [theme.breakpoints.up('md')]: {
    padding: '2rem 4rem 2rem 4rem',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '3rem 6rem 3rem 6rem',

  },
})) as typeof Box;

const CardLayout = ({ children, noMarginTop, title, center, noHeader }: CardLayoutProps) => {

  const StyledContainer = styled(Container)({
    padding: 0,
    marginTop: noMarginTop ? 0 : '1rem',
    maxWidth: '100%',
    overflowX: 'hidden'
  });

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {
          !noHeader && (
            <Header />
          )
        }
        <MainBox component="main">
          <StyledContainer maxWidth="lg">
            <Grid container justifyContent="center" height="100%">
              <Grid item width="100%">
                <SafeArea>
                    <Card>
                        <CardHeader
                            titleTypographyProps={{ component: Typography, variant: 'inherit' }}
                            title={title}
                        />
                        <CardContent sx={{ textAlign: center ? 'center' : 'left'}}>
                            {children}
                        </CardContent>
                    </Card>
                </SafeArea>
              </Grid>
            </Grid>
          </StyledContainer>
        </MainBox>
        <Footer />
      </Box>
    </>
  );
};

export default CardLayout;
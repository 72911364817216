import { Box, Button, Divider } from '@mui/material'
import CreateSustainabilityStep1 from './Step1'
import CreateSustainabilityStep2 from './Step2'
import { IACreateSustainability } from 'interfaces'
import { ChangeEventHandler } from 'react';
import { styled } from "@mui/material/styles";

interface CreateSustainabilityStep3Props {
  data: IACreateSustainability
  onSubmit: Function
  onBack: Function
  isLoading?: boolean
  isEdit?: boolean
  isEditDetail?: boolean
  onChange?: (e:ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, customValue?: number | string) => void
  onChangeSelect?: (name: string, value: number) => void
  onChangeImage?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  totalPages?: number
}

const SubmitButton = styled(Button)(({ theme }) => ({
	width: 180,
	height: 60,
	fontSize: 20,
	borderRadius: 10,
	margin: "10px auto",
}));

const CreateSustainabilityStep3 = ({ data, onBack, onSubmit, isLoading }: CreateSustainabilityStep3Props) => {
  return (
    <Box>
      <CreateSustainabilityStep1
        data={data}
        isEdit={false}
      />
      <Divider sx={{ marginBottom: 3 }}/>
      <CreateSustainabilityStep2 
        data={data}
        isEdit={false}
      />
      <Box display="flex" sx={{ width: "40%", margin: "0 auto" }}>
        <SubmitButton variant="outlined" onClick={() => {
          onBack && onBack()
        }}>
          戻る
        </SubmitButton>
        <SubmitButton variant="contained" onClick={() => onSubmit()} disabled={isLoading}>
          送信
        </SubmitButton>
      </Box>
    </Box>
  )
}

export default CreateSustainabilityStep3

import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import { BaseResponse, ErrorResponse, IACreateSustainability, IAListSustainability, IAListSustainabilitySuccesses, IAUpdateSustainabilityImageAttr, IAUpdateSustainabilityInfo, IAAllSustainability } from "interfaces";
import { AxiosError } from "axios";

export const useAdminGetListSustainability = (params: {
	page: number;
	per: number;
	name: string;
}) => {
	return useQuery({
		queryKey: ["ADMIN_GET_LIST_SUSTAINABIITY", params.page, params.per, params.name],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				`/admin/sustainabilities/filtering?page=${params.page}&per=${params.per}&name=${params.name}`
			);
			return res as BaseResponse<IAListSustainability>;
		},
		enabled: !!params.page && !!params.per,
	});
};

export const useAdminGetDetailSustainability = (id: number) => {
	return useQuery({
		queryKey: ["ADMIN_GET_DETAIL_SUSTAINABIITY", id],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(`/admin/sustainabilities/${id}`);
			return res as BaseResponse<IAUpdateSustainabilityInfo>;
		},
		enabled: !!id,
		staleTime: Infinity
	});
};
export const useAdminGetDetailSustainabilitySuccesses = (id: number, params: {
	page: number;
	per: number;
	search: string;
}) => {
	return useQuery({
		queryKey: ["ADMIN_GET_DETAIL_SUSTAINABIITY_SUSCESSES_ATTRIBUTE", id, params],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(`/admin/sustainability_successes/filtering/?page=${params.page}&per=${params.per}&search=${params.search}&sustainability_id=${id}`);
			return res as BaseResponse<IAListSustainabilitySuccesses>;
		},
		enabled: !!id || !!params,
	});
};

export const useAdminCreateSustainability = (
	onOk?: (res: BaseResponse) => void,
	onErr?: (err: AxiosError<ErrorResponse>) => void
) => {
	return useMutation({
		mutationKey: ["ADMIN_CREATE_SUSTAINABIITY"],
		mutationFn: ( body : IACreateSustainability) =>
			axiosInstanceAuth.post("/admin/sustainabilities", body),
		onSuccess: (res) => {
			onOk && onOk(res as BaseResponse);
		},
		onError: (err) => {
			onErr && onErr(err as AxiosError<ErrorResponse>);
		},
	});
};
export const useAdminUpdateSustainability = (
	onOk?: (res: BaseResponse) => void,
	onErr?: (err: AxiosError<ErrorResponse>) => void
) => {
	return useMutation({
		mutationKey: ["ADMIN_UPDATE_SUSTAINABIITY"],
		mutationFn: ({id, body}: { id: number, body : { sustainability_successes_attributes : {
			id: number, 
			image_attributes : IAUpdateSustainabilityImageAttr
		}[] }}) =>
			axiosInstanceAuth.patch(`/admin/sustainabilities/${id}`, body),
		onSuccess: (res) => {
			onOk && onOk(res as BaseResponse);
		},
		onError: (err) => {
			onErr && onErr(err as AxiosError<ErrorResponse>);
		},
	});
};


export const useAdminGetAllSustainability = () => {
	return useQuery({
		queryKey: ["ADMIN_GET_ALL_SUSTAINABIITY"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get("/admin/sustainabilities");
			return res as BaseResponse<IAAllSustainability[]>;
		},
	});
};
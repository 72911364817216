import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "App"
import Cookies from "js-cookie"

import UserHome from "./UserHome"
import UserWashBottle from "./UserWashBottle"
import { Box } from "@mui/material"
import ModalShowPrivacy from "components/utils/ModalShowPrivacy";

// とりあえず認証済みユーザーの名前やメールアドレスを表示
const Home: React.FC = () => {
  const { user } = useContext(AuthContext)

  //プライバシーを表示するために最初のログインをチェックする
  const isFirstTime = Cookies.get("_is_first_time")
  const [showModalPrivacy, setShowModalPrivacy] = useState(false)
  useEffect(() => {
    if (!isFirstTime && user?.data?.id) {
      setShowModalPrivacy(true)
    }
  }, [isFirstTime, user])
  return (
    <>
      { 
        (user?.data?.roles.includes('normal')) ? (
          <Box sx={{ height: "100%" }}>
            <UserHome />
          </Box>
        ) : (user?.data?.roles.includes('wash_bottle')) ? (
          <Box sx={{ height: "100%" }}>
            <UserWashBottle />
          </Box>
        ) : <></>
      }
      <ModalShowPrivacy open={showModalPrivacy} setOpen={setShowModalPrivacy} />

    </>
  )
}

export default Home
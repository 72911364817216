import { useQuery } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import {
	IChartUserWashedData,
	ICountCoupon,
	ICountDayOfWeek,
	ICountHourPerDay,
	ICountUserWashed,
	IUserDistribute,
	IWashedHistory,
} from "interfaces/Dashboard";
import { BaseResponse } from "interfaces";

export const useGetCountUserAndCleanBottle = () => {
	return useQuery({
		queryKey: ["GET COUNT USER AND CLEAN BOTTLE"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				"admin/dashboards/user_and_washed_num"
			);
			return res as ICountUserWashed;
		},
	});
};

export const useGetDataChartUserWashed = () => {
	return useQuery({
		queryKey: ["GET DATA CHART USER WASHED"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get("admin/dashboards/washes_trend");
			return res as IChartUserWashedData;
		},
	});
};

export const useGetDataDashboardCoupon = () => {
	return useQuery({
		queryKey: ["GET DATA DASHBOARD COUPON"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				"admin/dashboards/coupon_statistical"
			);
			return res as ICountCoupon;
		},
	});
};

export const useGetDataWashedHistory = () => {
	return useQuery({
		queryKey: ["GET DATA WASHED HISTORY"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				"admin/dashboards/wm_usage_history"
			);
			return res as BaseResponse<IWashedHistory[]>;
		},
	});
};

export const useGetDataDayOfWeek = () => {
	return useQuery({
		queryKey: ["GET DATA DAY OF WEEK"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				"admin/dashboards/washed_count_day_of_week"
			);
			return res as ICountDayOfWeek;
		},
	});
};

export const useGetDataHourPerDay = () => {
	return useQuery({
		queryKey: ["GET DATA HOUR PER DAY"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				"admin/dashboards/washes_count_per_hour"
			);
			return res as ICountHourPerDay;
		},
	});
};

export const useGetDataUserDistribute = () => {
	return useQuery({
		queryKey: ["GET DATA USER DISTRIBUTE"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				"admin/dashboards/frequency_use_wm"
			);
			return res as IUserDistribute;
		},
	});
};

import { Box, Button, Typography, Modal } from '@mui/material'
import { styled } from '@mui/material/styles';
import Cookies from "js-cookie"

const BoxModal = styled(Box)(({ theme }) => ({
    width: 300,
    [theme.breakpoints.up('md')]: {
        width: 600,
    },
    [theme.breakpoints.up('lg')]: {
        width: 900,
    },
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 0%)',
    backgroundColor: 'white',
    border: '0.5px solid #999',
    borderRadius: 15,
    padding: '15px 10px',
    margin: 10,
}));
const TitleTypography = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
        fontSize: 16,
    },
}));

interface ModalShowPrivacyProps {
    open: boolean
    setOpen: (e: boolean) => void

}

const ModalShowPrivacy = ({ open, setOpen }: ModalShowPrivacyProps) => {
    return (
        <Modal
            open={open}
            aria-labelledby="modal-show-privacy"
            hideBackdrop
        >
            <BoxModal>
                <TitleTypography id="modal-show-privacy" variant="h6">
                    当ウェブサイトでは、サイトの利便性向上を目的に、Cookieを使用しております。詳細は
                    <span onClick={() => window.open("https://www.sato.co.jp/cookie/", "_blank")} style={{
                        textDecoration: 'underline',
                        cursor: 'pointer'
                    }}>Cookieポリシー</span>をご覧ください。
                </TitleTypography>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }} >
                    <Button 
                        variant='contained'
                        sx={{ padding: '15px 25px', fontSize: 20 }}
                        onClick={() => {
                            setOpen(false)
                            //expires day max is 731 day https://developer.chrome.com/blog/cookie-max-age-expires
                            Cookies.set("_is_first_time", 'true', {
                                expires: 731
                            })
                        }}
                    >
                        同意する
                    </Button>
                </Box>
            </BoxModal>
        </Modal>
    )
}

export default ModalShowPrivacy
import { useRef, useEffect, useState } from "react";
import jsQR from "jsqr";
import {
	Box,
	Button,
	Typography,
	CircularProgress,
	Modal,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AlertMessage from "./AlertMessage";

interface IModalCheckQrCodeProps {
	onOk: (e: string) => void;
	message: string;
	messageText: string;
	onClose: () => void;
	step?: 0 | 1 | 2 | 3 | 4;
	isOpen: boolean;
	isLoading: boolean;
}
const BoxCamera = styled(Box)({
	position: "relative",
	marginTop: 5,
	border: "1px solid black",
	borderRadius: 2,
	width: 300,
	height: 300,
});
const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: {
		sm: 400,
	},
	minWidth: {
		xs: "75%",
		sm: "auto",
	},
	bgcolor: "background.paper",
	border: "1px solid #000",
	boxShadow: 24,
	p: {
		xs: 3,
		sm: 4,
	},
	borderRadius: 5,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	flexDirection: "column",
};

const ModalCheckQrCode = ({
	onOk,
	onClose,
	isOpen,
	isLoading,
	message,
	messageText,
}: IModalCheckQrCodeProps) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);

	const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({ isOpen: false, message: "", type: "success" });

	useEffect(() => {
		const currentVideoRef = videoRef.current;
		const canvas = canvasRef.current;
		let timeoutId: NodeJS.Timeout | null = null;

		if (isOpen) {
			const scanQrCode = () => {
				if (canvas && currentVideoRef) {
					const ctx = canvas.getContext("2d");
					if (ctx) {
						// カメラの映像をcanvasに描画する
						ctx.drawImage(currentVideoRef, 0, 0, canvas.width, canvas.height);
						const imageData = ctx.getImageData(
							0,
							0,
							canvas.width,
							canvas.height
						);

						// 画像データを取得
						var data = imageData.data;

						// シャープメソッド
						const _data = data.slice();
						const sharpedColor = (color: number, i: number) => {
							// 係数
							const sub = -1;
							const main = 10;

							const prevLine = i - canvas.width * 4;
							const nextLine = i + canvas.width * 4;

							const sumPrevLineColor =
								_data[prevLine - 4 + color] * sub +
								_data[prevLine + color] * sub +
								_data[prevLine + 4 + color] * sub;
							const sumCurrLineColor =
								_data[i - 4 + color] * sub +
								_data[i + color] * main +
								_data[i + 4 + color] * sub;
							const sumNextLineColor =
								_data[nextLine - 4 + color] * sub +
								_data[nextLine + color] * sub +
								_data[nextLine + 4 + color] * sub;

							return (
								(sumPrevLineColor + sumCurrLineColor + sumNextLineColor) / 2
							);
						};

						// 画像をシャープにする処理
						for (var i = 0; i < data.length; i += 4) {
							// 2列目〜n-1列目
							if (
								i % (canvas.width * 4) === 0 ||
								i % (canvas.width * 4 + 300) === 0
							) {
								// nop
							} else {
								data[i] = sharpedColor(0, i);
								data[i + 1] = sharpedColor(1, i);
								data[i + 2] = sharpedColor(2, i);
							}
						}

						// 変更した画像データをCanvasに描画
						ctx.putImageData(imageData, 0, 0);

						// QRコードをスキャンする
						const qrCodeData = jsQR(
							imageData.data,
							imageData.width,
							imageData.height,
							{ inversionAttempts: "attemptBoth" }
						);
						if (qrCodeData && qrCodeData.data) {
							onOk(qrCodeData.data);
							return;
						} else {
							timeoutId = setTimeout(scanQrCode, 500);
						}
					}
				}
			};
			const constraints = {
				video: {
					facingMode: "environment",
					width: { ideal: 300 },
					height: { ideal: 300 },
				},
			};

			navigator.mediaDevices
				.getUserMedia(constraints)
				.then((stream) => {
					if (currentVideoRef) {
						currentVideoRef.srcObject = stream;

						// loadedmetadata イベントを待つ関数
						const onLoadedMetadata = () => {
							// 再度確認してから再生
							if (currentVideoRef) {
								currentVideoRef
									.play()
									.then(() => {
										// 再生に成功したらQRコードのスキャンを開始
										scanQrCode();
									})
									.catch((playError) => {
										console.error("Error playing video:", playError);
									})
									.finally(() => {
										// イベントリスナーを削除
										currentVideoRef?.removeEventListener(
											"loadedmetadata",
											onLoadedMetadata
										);
									});
							} else {
								console.error("Error not find video ref");
							}
						};

						// loadedmetadata イベントリスナーを追加
						currentVideoRef.addEventListener(
							"loadedmetadata",
							onLoadedMetadata
						);
					}
				})
				.catch((err) => console.error("Error accessing media devices:", err));

			// コンポーネントがアンマウントされたら、カメラのストリームを停止する
		}
		return () => {
			clearInterval(timeoutId as NodeJS.Timeout);
			if (currentVideoRef && currentVideoRef.srcObject) {
				const stream = currentVideoRef.srcObject as MediaStream;
				const tracks = stream.getTracks();
				tracks.forEach((track) => track.stop());
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const renderBoxCamera = () => {
		return (
			<BoxCamera>
				<Box display={modalAlert.isOpen || isLoading ? "none" : "block"}>
					<video
						ref={videoRef}
						muted
						playsInline
						style={{
							position: "absolute",
							left: 0,
							top: 0,
							zIndex: 50,
							height: "100%",
							width: "100%",
						}}
					/>
					<canvas
						ref={canvasRef}
						width="300"
						height="300"
						style={{ display: "none" }}
					/>
				</Box>
			</BoxCamera>
		);
	};

	return (
		<Modal open={isOpen} disablePortal onClose={() => onClose()}>
			<>
				<Box sx={style} tabIndex={-1}>
					<Typography
						sx={{ textAlign: "center", fontWeight: 500, marginBottom: 1 }}
					>
						{message}
					</Typography>

					{renderBoxCamera()}
					<Button
						disabled={modalAlert.isOpen || isLoading}
						size="large"
						sx={{ marginTop: 5, padding: "12px 24px" }}
						variant="contained"
						onClick={() => {
							onClose();
						}}
					>
						閉じる
					</Button>
					{(modalAlert.isOpen || isLoading) && (
						<Box
							sx={{
								width: "100%",
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								minHeight: "200px",
								position: "absolute",
							}}
						>
							<CircularProgress />
						</Box>
					)}
				</Box>
				{modalAlert.isOpen && (
					<AlertMessage
						open={modalAlert.isOpen}
						setOpen={(open: boolean) =>
							setModalAlert({ isOpen: open, message: "", type: "warning" })
						}
						severity={modalAlert.type}
						message={modalAlert.message}
						autoHideDuration={2000}
					/>
				)}
			</>
		</Modal>
	);
};

export default ModalCheckQrCode;

import { useState, useContext } from 'react'
import { Box, Typography, Button, Modal } from "@mui/material"
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom"
import { AppUrl } from 'components/utils/constants/AppUrl'
import { AuthContext } from "App"
import { AlertContext } from 'App';
import { useDeleteCurrentUser } from "lib/api/user";
import { BaseResponse } from 'interfaces';
import Cookies from 'js-cookie';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    height: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 5,
    width: '100%',
    margin: '0px auto',
});

const UserDelete = () => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const { user, refetch } = useContext(AuthContext)
    const navigate = useNavigate()
    const { setModalAlert } = useContext(AlertContext)
    const handleSignOut = async () => {
        Cookies.remove("_access_token")
        Cookies.remove("_client")
        Cookies.remove("_uid")
        refetch()
        navigate(AppUrl.HOME)
    }
    const handleDelete = useDeleteCurrentUser((res: BaseResponse) => {
        setOpenDeleteModal(false)
        setModalAlert({ isOpen: true, message: res?.message, type: 'success' })
        handleSignOut()
    })

    const showUserProviderType = () => {
        switch (user?.userProviderType) {
            case "email":
                return "メールアドレス"
            case "customer_id":
                return "ログインID"
            default:
                return "メールアドレス"
        }
    }

    return (
        <>
            <BoxWrapper>
                <Typography sx={{ fontSize: '0.8rem', textAlign: 'center', margin: '20px 0px' }}>
                    退会すると、サービスが利用できなくなり、<br />​同じ{showUserProviderType()}で登録することができません。
                </Typography>
                <Button sx={{ width: 100, margin: '1rem auto 1rem auto' }} variant="contained" onClick={() => setOpenDeleteModal(true)}>退会する​</Button>
                <Modal
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <BoxWrapper>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center', fontSize: 14 }}>
                                本当に退会しますか​<br />以下のボタンを押すと退会します
                            </Typography>
                            <Button sx={{ width: 100, margin: '30px auto' }} variant="contained" onClick={() => {
                                user?.data?.id && handleDelete.mutate(user?.data?.id)
                            }}>退会​</Button>
                            <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ textAlign: 'center', fontSize: 14 }}>
                                退会すると、サービスが利用できなくなり、​​<br />同じ{showUserProviderType()}で登録することができません。
                            </Typography>
                        </BoxWrapper>
                    </Box>
                </Modal>
            </BoxWrapper>
        </>
    )
}

export default UserDelete
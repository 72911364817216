import { Box, Button, Typography } from '@mui/material';
import { useAdminGetDetailEvent } from 'lib/api/admin-event';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { AppUrl } from 'components/utils/constants/AppUrl';
import CircularProgressBox from 'components/utils/CircularProgressBox';
import { formatUTCDateTime } from 'lib/common/date.helper';

interface renderDataProps {
  name: string
  value: string | number | undefined
}
interface renderDataDateTime {
  name: string
  value: string | undefined
}

const TitleTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
  whiteSpace: 'pre',
}));

const ContentTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
  fontSize: 24,
  whiteSpace: 'pre',
}));

const BoxItem = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: 10,
	marginBottom: 1,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
	width: 180,
	height: 60,
	fontSize: 20,
	borderRadius: 10,
	margin: "10px auto",
}));

const AdminEventDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate()

  const { data, isLoading } = useAdminGetDetailEvent(Number(id))

  const renderData = ({ name, value }: renderDataProps) => {
    return (
      <BoxItem> 
        <TitleTypography>{name}</TitleTypography>
        <ContentTypography>{value}</ContentTypography>
      </BoxItem>
    )
  }
  const renderDataDateTime = ({ name, value }: renderDataDateTime) => {
    return (
      <BoxItem> 
        <TitleTypography>{name}</TitleTypography>
        <ContentTypography>{value && formatUTCDateTime(value)}</ContentTypography>
      </BoxItem>
    )
  }
  const handleBack = () => {
    navigate(AppUrl.EVENT_MANAGEMENT)
  }

  return (
    <Box
			display="flex"
			flexDirection="column"
			gap={3}
		>
			<Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
        イベント内容
			</Typography>
      {
        isLoading ? (
          <CircularProgressBox />
        ) : (
          <>
            <Box 
              display="flex"
              gap={3}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "row",
                }
              }}
            >
              {renderData({
                name: "認識Code",
                value: data?.data?.code
              })}
              {renderData({
                name: "イベント名",
                value: data?.data?.name
              })}
              {renderDataDateTime({
                name: "開始日",
                value: data?.data?.start
              })}
              {renderDataDateTime({
                name: "終了日",
                value: data?.data?.end
              })}
            </Box>
            {renderData({
              name: "イベントステータス",
              value: data?.data?.statusI18n
            })}
            {renderData({
              name: "グループリミット ※0の場合は全員参加",
              value: data?.data?.groupLimit
            })}
            {renderData({
              name: " サステナビリティ目標",
              value: data?.data?.sustainabilityCodeName
            })}
            <Box display="flex" sx={{ width: "40%", margin: "0 auto" }}>
              <SubmitButton variant="outlined" onClick={() => {
                handleBack()
              }}>
                戻る
              </SubmitButton>
            </Box>
          </>
        )
      }
    </Box>
  )
}

export default AdminEventDetail

import { FormEvent, useCallback, useContext, useState } from "react";
import { useCheckCustomerIdTag, useResetPassword } from "lib/api/auth";
import { AppUrl } from "components/utils/constants/AppUrl";
import { useNavigate } from "react-router-dom";

import { styled, createTheme } from "@mui/material/styles";
import { TextField, CardContent, Button, Typography, Box } from "@mui/material";
import { AlertContext } from "App";
import { AxiosError } from "axios";
import { ErrorResponse, IForgotPasswordLoginId } from "interfaces";
import { handleChangeStateInput } from "lib/common/handle-change-state";
import ModalCheckQrCode from "components/utils/ModalCheckQrCode";
import { useGetAttributeDetail } from "lib/api/attribute";
import AlertMessage from "components/utils/AlertMessage";

const theme = createTheme();

const SignSubmitButton = styled(Button)({
	flexGrow: 1,
	textTransform: "none",
	minWidth: 150,
});

const QrCodeButton = styled(Button)({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(3),
	flexGrow: 1,
	textTransform: "none",
});
const QrCodeTypography = styled(Typography)({
	flexGrow: 1,
	variant: "h2",
	marginTop: theme.spacing(1),
});
const BackButton = styled(Button)(({ theme }) => ({
	flexGrow: 1,
	textTransform: "none",
}));
const TextTitle = styled(Typography)({
	marginTop: "1rem",
	fontWeight: 400,
	fontSize: "1.2rem",
});

const ForgotPasswordLoginId = () => {
	const { data } = useGetAttributeDetail();
	const [stepForgotPassword, setStepForgotPassword] = useState<1 | 2>(1);
	const [forgotPasswordData, setForgotPasswordData] =
		useState<IForgotPasswordLoginId>({
			code: "",
			password_confirmation: "",
			customer_id: "",
			password: "",
		});
	const { setModalAlert: setModalAlertGlobal } = useContext(AlertContext);
	const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({ isOpen: false, message: "", type: "success" });
	const navigate = useNavigate();
	const [openModalCheckTag, setOpenModalCheckTag] = useState(false);

	const handleOpenModalQr = () => {
		setOpenModalCheckTag(true);
	};
	const handleCloseCheckTag = () => {
		setOpenModalCheckTag(false);
	};
	const handleCheckQrCode = useCallback(
		(tag: string) => {
			setForgotPasswordData({
				...forgotPasswordData,
				code: tag,
			});
			setOpenModalCheckTag(false);
		},
		[forgotPasswordData]
	);
	const handleCheckCusromerIdTag = useCheckCustomerIdTag(
		() => {
			setStepForgotPassword(2);
		},
		(err: AxiosError<ErrorResponse>) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.message || "エラー",
				type: "error",
			});
		}
	);
	const handleResetPassword = useResetPassword(
		(res) => {
			setModalAlertGlobal({
				isOpen: true,
				message: res?.message,
				type: "success",
			});
			navigate(AppUrl.SIGN_IN);
		},
		(err: AxiosError<ErrorResponse>) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.error?.messages || "エラー",
				type: "error",
			});
		}
	);

	const handleSubmit = async (
		e: FormEvent<HTMLFormElement>
	) => {
		e.preventDefault();
		if (stepForgotPassword === 1) {
			handleCheckCusromerIdTag.mutate({
				code: forgotPasswordData.code,
				customer_id: forgotPasswordData.customer_id,
			});
		} else if (stepForgotPassword === 2) {
			handleResetPassword.mutate(forgotPasswordData);
		}
	};

	return (
		<form noValidate autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
			{stepForgotPassword === 1 ? (
				<CardContent>
					<TextField
						sx={{ backgroundColor: "#ffffff" }}
						name="customer_id"
						variant="outlined"
						required
						fullWidth
						label="ログインID"
						value={forgotPasswordData.customer_id}
						margin="dense"
						onChange={handleChangeStateInput(setForgotPasswordData)}
					/>
					{forgotPasswordData.code && (
						<>
							<TextTitle>タグ情報</TextTitle>
							<QrCodeTypography>{forgotPasswordData.code}</QrCodeTypography>
						</>
					)}
					<QrCodeButton
						variant="contained"
						size="large"
						fullWidth
						color="primary"
						disabled={handleCheckCusromerIdTag.isPending}
						onClick={handleOpenModalQr}
					>
						{forgotPasswordData.code ? "コード再読取り" : `${data?.data?.name}読み込み`}
					</QrCodeButton>

					<SignSubmitButton
						type="submit"
						variant="contained"
						size="large"
						color="primary"
						disabled={
							!forgotPasswordData.customer_id ||
							!forgotPasswordData.code ||
							handleCheckCusromerIdTag.isPending
						}
						sx={{ marginTop: 3 }}
					>
						認証
					</SignSubmitButton>
				</CardContent>
			) : (
				<CardContent>
					<TextField
						sx={{ backgroundColor: "#ffffff" }}
						name="password"
						variant="outlined"
						required
						fullWidth
						label="再設定用パスワード"
						type="password"
						value={forgotPasswordData.password}
						margin="dense"
						onChange={handleChangeStateInput(setForgotPasswordData)}
					/>
					<TextField
						sx={{ backgroundColor: "#ffffff" }}
						name="password_confirmation"
						variant="outlined"
						required
						fullWidth
						label="再設定用パスワード確認"
						type="password"
						value={forgotPasswordData.password_confirmation}
						margin="dense"
						onChange={handleChangeStateInput(setForgotPasswordData)}
					/>
					<Box
						display="flex"
						gap={3}
						marginTop={3}
						justifyContent="space-between"
					>
						<BackButton
							variant="outlined"
							onClick={() => {
								setStepForgotPassword(1);
							}}
						>
							戻る
						</BackButton>
						<SignSubmitButton
							type="submit"
							variant="contained"
							size="large"
							color="primary"
							disabled={
								!forgotPasswordData.password ||
								!forgotPasswordData.password_confirmation ||
								handleResetPassword.isPending ||
								handleCheckCusromerIdTag.isPending
							}
						>
							再設定
						</SignSubmitButton>
					</Box>
				</CardContent>
			)}
			<ModalCheckQrCode
				isOpen={openModalCheckTag}
				message={`${data?.data?.name}コードを読ませてください。`}
				messageText={`${data?.data?.name}コードを入力てください。`}
				onClose={handleCloseCheckTag}
				onOk={handleCheckQrCode}
				isLoading={handleCheckCusromerIdTag.isPending}
			/>
			<AlertMessage
				open={modalAlert.isOpen}
				setOpen={(open: boolean) =>
					setModalAlert({ isOpen: open, message: "", type: "warning" })
				}
				severity={modalAlert.type}
				message={modalAlert.message}
			/>
		</form>
	);
};

export default ForgotPasswordLoginId;

import { useState, useCallback, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import { Box, Button, Avatar, IconButton } from "@mui/material"
import { styled } from '@mui/material/styles';
import PlusIcon from "components/icon/PlusIcon";
import DeleteIcon from "components/icon/DeleteIcon";

const style = {
    position: 'absolute' as 'absolute',
    top: '54%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
};
const BoxCamera = styled(Box)({
    marginTop: 5,
    border: '1px solid black',
    borderRadius: 2,
    width: 400,
    height: 400
});
const BoxListImage = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 15,
});
const AvatarDefault = styled(Avatar)({
    height: 160,
    width: 160,
    '& .MuiAvatar-img': {
        objectFit: 'contain',
    }
});
interface CleanSystemScreenShotProps {
    onOk: Function
    setListImage: (e: string[]) => void
    listImage: string[]
}

const CleanSystemScreenShot = ({ onOk,setListImage, listImage }: CleanSystemScreenShotProps) => {
    const numberOfImage = 4
    const [deletedImage, setDeletedImage] = useState('')
    const [openScreenShot, setOpenScreenShot] = useState<{ open : boolean, image : string, index: number}>({
        open: false,
        image: '',
        index: -1
    });
    const webcamRef = useRef<Webcam>(null);
    const videoConstraints = {
        width: 400,
        height: 400,
        facingMode: "user",
    };
    useEffect(() => {
        const webcamRefCurrent = webcamRef?.current
        return () => {
            if (webcamRef && webcamRefCurrent?.video) {
                const stream = webcamRefCurrent?.video.srcObject as MediaStream
                const tracks = stream.getTracks()
                tracks.forEach((track) => track.stop())
            }
        }
    }, [])
    const capture = useCallback(
        () => {
            if (webcamRef?.current) {
                const imageSrc = webcamRef?.current?.getScreenshot() || ''
                setOpenScreenShot({ open: true, image: imageSrc, index: openScreenShot.index})
            }
        },
        [webcamRef, openScreenShot]
    );
    return (
        <Box sx={style} className="animation-change">
            {
                (openScreenShot.open && openScreenShot.image) ? (
                    <img src={openScreenShot.image} alt={openScreenShot.image} width={400} height={400}/>
                ) : (openScreenShot.open && !openScreenShot.image) ? (
                    <BoxCamera className="animation-change">
                        <Webcam
                            audio={false}
                            height={400}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={400}
                            videoConstraints={videoConstraints}
                        />
                    </BoxCamera>
                ) : (
                    <BoxListImage className="animation-change">
                        {
                            Array.from({ length: numberOfImage }, (src, index) => (
                                <Box sx={{ display: 'flex', alignItems: 'center'}} className="animation-change">
                                    <Button 
                                        onClick={() => {
                                            if(listImage[index])
                                                setOpenScreenShot({open: true, image: listImage[index], index})
                                            else 
                                                setOpenScreenShot({open: true, image: '', index: -1})
                                        }}
                                        className={`${listImage[index] === deletedImage && 'animation-delete'}`}
                                    >
                                        <AvatarDefault
                                            alt={`Image ${index}`}
                                            variant="square"
                                            src={listImage[index]}
                                            key={index}
                                        >
                                            <PlusIcon/>
                                        </AvatarDefault>
                                    </Button>
                                    {
                                        listImage[index] && listImage[index] !== deletedImage && (
                                            <IconButton aria-label="delete" onClick={() => {
                                                setDeletedImage(listImage[index])
                                                setTimeout(() => {
                                                    setListImage(listImage.filter((item => item !== listImage[index])))
                                                }, 300)
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )
                                    }
                                </Box>
                            ))
                        }
                    </BoxListImage>
                )
            }
            {
                (openScreenShot.open && !openScreenShot.image) ? (
                    <Button variant="contained" onClick={capture} sx={{ marginTop: 5, padding: '20px 40px', fontSize: 18 }}>写真を撮る</Button>
                ) : (openScreenShot.open && openScreenShot.image) ? (
                    <>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setOpenScreenShot({ open: true, image: '', index: openScreenShot.index })
                            }}
                            sx={{ marginTop: 5, padding: '14px 28px', fontSize: 14 }}
                        >
                            変更したい
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if(openScreenShot.index === -1)
                                    setListImage([...listImage, openScreenShot.image])
                                else {
                                    const newArr = [...listImage]
                                    newArr.splice(openScreenShot.index, 1, openScreenShot.image)
                                    setListImage([...newArr])
                                }
                                setOpenScreenShot({ open: false, image: '', index: openScreenShot.index})
                            }}
                            sx={{ marginTop: 2, padding: '20px 40px', fontSize: 18 }}
                        >
                            送信する
                        </Button>
                    </>
                ) : (
                    <Button
                        variant="contained"
                        onClick={() => {
                            if(listImage.length){
                                onOk()
                                setOpenScreenShot({ open: false, image: '', index: -1})
                            }
                        }}
                        disabled={!listImage.length}
                        sx={{ marginTop: 2, padding: '20px 40px', fontSize: 18 }}
                    >
                        確認する
                    </Button>
                )
            }
        </Box>
    )
}

export default CleanSystemScreenShot
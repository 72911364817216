import { styled } from '@mui/material/styles';
import { Box, TextField, MenuItem, Select, Button } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select';
import { useCreateUserTagMutation, useGetUserTag, useUpdateUserTagMutation } from 'lib/api/my-bottle'
import { AlertContext } from "App";
import { BaseResponse, ErrorResponse } from "interfaces"
import { useNavigate } from "react-router-dom"
import { AppUrl } from 'components/utils/constants/AppUrl'
import { useState, useContext } from 'react'
import { AxiosError } from 'axios';
import { QueryClient } from "@tanstack/react-query";
import { bottleTag } from 'components/utils/constants/AppEnv';

const BoxItem = styled(Box)({
    textDecoration: "underline",
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    marginTop: 2,
    cursor: 'pointer'
});

const RegisterButton = styled(Button)({
    marginTop: 10,
    padding: '10px 30px',
    fontSize: 20
});

const RegisterManuallyMyBottle = () => {
    const listTags: string[] = typeof(bottleTag) === "string" ? JSON.parse(bottleTag || '') : (bottleTag || []) as string[] 
    const [tag, setTag] = useState(listTags[0])
    const [result, setResult] = useState('')
    const { data } = useGetUserTag()
    const navigate = useNavigate()
    const { setModalAlert } = useContext(AlertContext)
    const queryClient = new QueryClient()

    const handleChange = (event: SelectChangeEvent) => {
        setTag(event.target.value)
    };
    const handleCreateUserTag = useCreateUserTagMutation((res: BaseResponse<any>) => {
        queryClient.invalidateQueries({ queryKey: ['GET USER TAG'] })
        setModalAlert({ isOpen: true, message: res?.message, type: 'success' })
        navigate(AppUrl.MY_BOTTLE)
    }, (err: AxiosError<ErrorResponse<{
        id: number
        message: string
        messages: string[]
    }>>) => {
        setModalAlert({ isOpen: true, message: err?.response?.data?.error?.message || err?.response?.data?.error?.messages || 'エラー', type: 'error' })
    })
    const handleUpdateUserTag = useUpdateUserTagMutation((res: BaseResponse<any>) => {
        queryClient.invalidateQueries({ queryKey: ['GET USER TAG'] })
        setModalAlert({ isOpen: true, message: res?.message, type: 'success' })
        navigate(AppUrl.MY_BOTTLE)
    }, (err: AxiosError<ErrorResponse<{
        id: number
        messages: string[]
        message: string
    }>>) => {
        setModalAlert({ isOpen: true, message: err?.response?.data?.error?.message || err?.response?.data?.error?.messages || 'エラー', type: 'error' })
    })
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h4>マイボトルのQRの下に記載されている4桁コードを入力してください</h4>
            <BoxItem>
                <Select
                    value={tag}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleChange}
                    autoWidth
                    sx={{ minWidth: 200, textDecoration: 'none' }}
                >
                    {
                        listTags.map((tag) => (
                            <MenuItem value={tag} key={tag}>{tag}</MenuItem>
                        ))
                    }
                </Select>
                <TextField
                    inputProps={{ maxLength: 4, minLength: 4 }}
                    value={result} onChange={(e) => e.target.value.length <= 4 && setResult(e.target.value)}
                    type="number"
                />
            </BoxItem>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <RegisterButton variant='contained' disabled={handleCreateUserTag.isPending || handleUpdateUserTag.isPending} onClick={() => {
                    if (!tag) {
                        setModalAlert({ isOpen: true, message: '最初の部分を選択してください', type: 'warning' })
                    } else
                        if (!result) {
                            setModalAlert({ isOpen: true, message: '空白を埋めてください', type: 'warning' })
                        } else if (result.length < 4) {
                            setModalAlert({ isOpen: true, message: '完全なコードを入力してください', type: 'warning' })
                        } else
                            if (!data?.data?.code)
                                handleCreateUserTag.mutate(tag + result)
                            else handleUpdateUserTag.mutate(tag + result)
                }}>{data?.data?.code ? '再登録' : '登録'}</RegisterButton>
            </Box>
        </Box>
    )
}

export default RegisterManuallyMyBottle
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { IFeedbackType } from "interfaces";
import { useAdminGetFeedbackType } from "lib/api/admin-survey";
import { useState } from "react";

interface ICreateSurveyStep1Props {
	onSubmit: (feedbackType: IFeedbackType) => void;
}

const CreateSurveyStep1 = ({ onSubmit }: ICreateSurveyStep1Props) => {
	const { data: feedbackTypes } = useAdminGetFeedbackType();
	const [id, setId] = useState<number | undefined>(undefined);
	return (
		<Box>
			<Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
				アンケート作成
			</Typography>
			<Box
				alignItems="center"
				display="flex"
				flexDirection="column"
				gap={5}
				component="form"
				onSubmit={(e) => {
					e.preventDefault();
					const currentData = feedbackTypes?.data?.find(
						(item) => item.id === id
					);
					if (currentData) onSubmit(currentData);
				}}
			>
				<Typography sx={{ fontSize: 32, marginTop: 12 }} align="center">
					アンケートの生成タイプを選択してください
				</Typography>
				<Select
					value={id ?? ""}
					sx={{ width: 300 }}
					onChange={(e) => {
						setId(Number(e.target.value));
					}}
					required
				>
					{feedbackTypes?.data?.map((fb_type) => (
						<MenuItem value={fb_type.id} key={fb_type.id}>
							{fb_type.name}
						</MenuItem>
					))}
				</Select>
				<Button
					variant="contained"
					sx={{ width: 160, height: 80, fontSize: 32 }}
					type="submit"
				>
					作成
				</Button>
			</Box>
		</Box>
	);
};

export default CreateSurveyStep1;

import CircularProgressBox from "components/utils/CircularProgressBox";
import { useGetDetailEvent } from "lib/api/event";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { IResponseCurrentUser } from "interfaces";
import StageCarouselPagination from "components/pages/Event/StageCarouselPaginate";

const BoxWrapper = styled(Box)({
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	width: "100%",
	alignItems: "center",
	gap: 10,
});

const TypographyTitle = styled(Typography)({
	fontSize: 20,
	textAlign: "center",
});
const TypographyDescription = styled(Typography)({
	fontSize: 18,
	textAlign: "center",
});

const BoxWrapperCarousel = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	gap: 5,
	alignItems: "center",
	margin: "0 auto",
	width: "100%",
	[theme.breakpoints.up("lg")]: {
		width: 500,
	},
	overflow: "hidden",
}));

const BackButton = styled(Button)({
	marginTop: 10,
	padding: "5px 20px",
	fontSize: 18,
});

const EventDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data, isLoading } = useGetDetailEvent(Number(id));
  const queryClient = useQueryClient();
	const dataUser = queryClient.getQueryData(['GET AUTH USER']) as IResponseCurrentUser;

	return (
		<Box>
			{isLoading ? (
				<CircularProgressBox />
			) : (
				data?.data?.id && (
					<BoxWrapper>
						<TypographyTitle>{data?.data?.sustainabilityName}</TypographyTitle>
						<TypographyDescription>
							{data?.data?.value}
							{data?.data?.unit}
						</TypographyDescription>
						{data?.data?.issuedCoupon && dataUser.couponShow === 'available' && (
							<>
								<TypographyTitle>発行済クーポン​</TypographyTitle>
								<TypographyDescription>
									{data?.data?.issuedCoupon}
								</TypographyDescription>
							</>
						)}
						<BoxWrapperCarousel>
							<StageCarouselPagination
								textPagination="Stage. "
								data={data?.data}
							/>
						</BoxWrapperCarousel>
						<BackButton
							variant="contained"
							onClick={(e) => {
								e.preventDefault();
								navigate(-1);
							}}
						>
							戻る
						</BackButton>
					</BoxWrapper>
				)
			)}
		</Box>
	);
};

export default EventDetail;

import {
	Autocomplete,
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { ICreateSetting } from "interfaces";
import { handleChangeStateSelect } from "lib/common/handle-change-state";
import { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
	useAdminGetSettingShow,
	useAdminUpdateSettingShow,
} from "lib/api/admin-setting";
import CircularProgressBox from "components/utils/CircularProgressBox";
import AlertMessage from "components/utils/AlertMessage";

const TitleTypography = styled(Typography)(() => ({
	textAlign: "left",
	whiteSpace: "pre",
	fontSize: 24,
}));
const ContentTypography = styled(Typography)(() => ({
	textAlign: "left",
	fontSize: 26,
	marginLeft: 16,
	whiteSpace: "pre",
}));
const ContentChildTypography = styled(Typography)(() => ({
	textAlign: "left",
	fontSize: 18,
	marginLeft: 16,
	whiteSpace: "pre",
}));
const BoxItem = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	gap: 10,
	marginBottom: 1,
}));
const SubmitButton = styled(Button)(() => ({
	width: 180,
	height: 60,
	fontSize: 20,
	borderRadius: 10,
	margin: "10px auto",
}));

interface RenderFormRadioGroupProps {
	id: string;
	title: string;
	value: number | string;
	name: string;
	items: {
		key: number | string;
		label: string;
	}[];
}
interface RenderFormSelectGroupProps {
	title: string;
	items: {
		id: string;
		name: string;
		value: number | string;
		label: string;
		options: {
			code: string;
			name: string;
		}[];
	}[];
}

const radioOptionTrueFalse = [
	{
		label: "表示",
		key: 1,
	},
	{
		label: "非表示​",
		key: 0,
	},
];

const SettingShow = () => {
	const [dataSetting, setDataSetting] = useState<ICreateSetting>({
		coupon_show: {
			status: 0,
		},
		event_show: {
			status: 0,
		},
		clean_system_status_show: {
			status: 0,
		},
		top_events: {
			value: {
				all: "",
				first: "",
			},
		},
	});
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({ isOpen: false, message: "", type: "success" });

	const [data, listEventsIndividual, listEventsEveryone] =
		useAdminGetSettingShow();

	useEffect(() => {
		if (data?.data?.data) {
			setDataSetting({
				clean_system_status_show: data?.data?.data?.cleanSystemStatusShow,
				coupon_show: data?.data?.data?.couponShow,
				event_show: data?.data?.data?.eventShow,
				top_events: data?.data?.data?.topEvents,
			});
			setIsConfirmed(false);
		}
	}, [data?.data]);

	const renderFormRadioGroup = ({
		id,
		title,
		items,
		name,
		value,
	}: RenderFormRadioGroupProps) => {
		if (!isConfirmed)
			return (
				<FormControl>
					<FormLabel
						sx={{
							fontSize: 24,
							color: "black",
							"&.Mui-focused": {
								color: "black",
							},
						}}
					>
						{title}
					</FormLabel>
					<RadioGroup
						aria-labelledby={id}
						value={value}
						name={name}
						row
						sx={{
							marginLeft: 2,
						}}
						onChange={(e) => {
							handleChangeStateSelect(setDataSetting)(
								name,
								Number(e.target.value)
							);
						}}
					>
						{items?.map((item) => (
							<FormControlLabel
								key={`RadioID${id}-Key` + item.key}
								value={item.key}
								control={
									<Radio
										sx={{
											"& .MuiSvgIcon-root": {
												fontSize: 30,
											},
										}}
									/>
								}
								label={item.label}
								sx={{
									"& .MuiTypography-root": {
										fontSize: 24,
									},
								}}
							/>
						))}
					</RadioGroup>
				</FormControl>
			);
		else
			return (
				<BoxItem>
					<TitleTypography>{title}</TitleTypography>
					<ContentTypography>
						{items?.find((item) => item.key === value)?.label}
					</ContentTypography>
				</BoxItem>
			);
	};

	const renderFormSelectGroup = ({
		title,
		items,
	}: RenderFormSelectGroupProps) => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
				}}
			>
				<Typography sx={{ fontSize: 24, color: "black" }}>{title}</Typography>
				<Box
					sx={{
						display: "flex",
						gap: 3,
						flexWrap: "wrap",
						maxWidth: "100%",
					}}
				>
					{items?.map((item) => {
						if (!isConfirmed)
							return (
								<FormControl
									fullWidth
									sx={{ width: "auto", marginLeft: 2 }}
									key={item.id}
								>
									<Typography
										sx={{ fontSize: 20, color: "black", marginBottom: 1 }}
									>
										{item.label}
									</Typography>
									<Autocomplete
										options={item?.options || []}
										getOptionLabel={(option) => option.name}
										value={item?.options?.find(itemOption => item.value === itemOption?.code) || null as any}
										onChange={(event, newValue) => {
											handleChangeStateSelect(setDataSetting)(
												item.name,
												newValue?.code ?? null
											)
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												InputLabelProps={{ required: false }}
												sx={{ minWidth: 300 }}
											/>
										)}
										isOptionEqualToValue={(option, value) => option.code === value.code}
									/>
								</FormControl>
							);
						else
							return (
								<BoxItem key={item.id}>
									<ContentChildTypography>{item.label}</ContentChildTypography>
									<ContentTypography>
										{item?.options?.find((it) => it.code === item.value)?.name}
									</ContentTypography>
								</BoxItem>
							);
					})}
				</Box>
			</Box>
		);
	};

	const handleUpdateSettingShow = useAdminUpdateSettingShow(
		(res) => {
			setModalAlert({ isOpen: true, message: res?.message, type: "success" });
			data?.refetch();
		},
		(err) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.error?.messages || "エラー",
				type: "error",
			});
		}
	);

	const handleSubmit = () => {
		if (!isConfirmed) {
			setIsConfirmed(true);
		} else {
			handleUpdateSettingShow.mutate(dataSetting);
		}
	};

	const isSameOrigin = useCallback(() => {
		const responseData = data?.data?.data;
		if (responseData)
			if (
				responseData.cleanSystemStatusShow ===
					dataSetting.clean_system_status_show &&
				responseData.couponShow === dataSetting.coupon_show &&
				responseData.eventShow === dataSetting.event_show &&
				responseData.topEvents?.value?.first === dataSetting.top_events?.value?.first &&
				responseData.topEvents?.value?.all === dataSetting.top_events?.value?.all
			)
				return true;
		return false;
	}, [data?.data?.data, dataSetting]);
	const isAllLoading: boolean =
		data?.isPending ||
		listEventsIndividual?.isLoading ||
		listEventsEveryone?.isLoading ||
		handleUpdateSettingShow.isPending ||
		isSameOrigin();

	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={3}
			component="form"
			onSubmit={(e) => {
				e.preventDefault();
				handleSubmit();
			}}
		>
			<Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
				表示設定​
			</Typography>
			{data?.isLoading ? (
				<Box sx={{ width: "100%" }}>
					<CircularProgressBox />
				</Box>
			) : (
				<Box
					sx={{
						padding: "10px 20px",
						display: "flex",
						flexDirection: "column",
						gap: 3,
					}}
				>
					{renderFormRadioGroup({
						id: "radio-group-coupon",
						name: "coupon_show.status",
						value: Number(dataSetting.coupon_show.status),
						title: "クーポン",
						items: radioOptionTrueFalse,
					})}
					{renderFormRadioGroup({
						id: "radio-group-event",
						name: "event_show.status",
						value: dataSetting.event_show.status,
						title: "イベント​",
						items: radioOptionTrueFalse,
					})}
					{renderFormSelectGroup({
						title: "トップイベント​",
						items: [
							{
								id: "select-top-event-individual",
								name: "top_events.value.first",
								label: "個人​",
								options: listEventsIndividual?.data?.data || [],
								value: dataSetting.top_events?.value?.first || "",
							},
							{
								id: "select-top-event-everyone",
								name: "top_events.value.all",
								label: "全員​",
								options: listEventsEveryone?.data?.data || [],
								value: dataSetting.top_events?.value?.all|| "",
							},
						],
					})}
					{renderFormRadioGroup({
						id: "radio-group-machine-status",
						name: "clean_system_status_show.status",
						value: dataSetting.clean_system_status_show.status,
						title: "洗浄機ステータス​",
						items: radioOptionTrueFalse,
					})}
				</Box>
			)}
			<Box display="flex" sx={{ width: "40%", margin: "0 auto" }}>
				{isConfirmed && (
					<SubmitButton
						variant="outlined"
						onClick={() => {
							setIsConfirmed(false);
						}}
					>
						戻る
					</SubmitButton>
				)}
				<SubmitButton variant="contained" type="submit" disabled={isAllLoading}>
					{isConfirmed ? "送信" : "確認"}
				</SubmitButton>
			</Box>
			<AlertMessage
				open={modalAlert.isOpen}
				setOpen={(open: boolean) =>
					setModalAlert({ isOpen: open, message: "", type: "warning" })
				}
				severity={modalAlert.type}
				message={modalAlert.message}
			/>
		</Box>
	);
};

export default SettingShow;

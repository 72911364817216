import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, Button, Modal } from '@mui/material'
import { styled } from '@mui/material/styles';
import { AppUrl } from 'components/utils/constants/AppUrl';
import { useGetDetailCoupon, useUseCouponMutation } from '../../../lib/api/coupon';
import dayjs from 'dayjs';
import { BaseResponse, ErrorResponse } from "interfaces"
import { AxiosError } from 'axios';
import { AlertContext } from 'App';
import CircularProgressBox from 'components/utils/CircularProgressBox'

const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    gap: 20
});
const UseCouponButton = styled(Button)({
    marginTop: 10,
    padding: '10px 30px',
    fontSize: 20
});
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 10
};

const CouponDetail: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [openModalUseConupon, setOpenModalUseConupon] = useState(false)
    const { setModalAlert } = useContext(AlertContext)

    const { data, isLoading } = useGetDetailCoupon(Number(id))
    const handleUseCoupon = useUseCouponMutation((res: BaseResponse<any>) => {
        setModalAlert({ isOpen: true, message: res?.message, type: 'info' })
        navigate(AppUrl.COUPON)
    }, (err: AxiosError<ErrorResponse>) => {
        setModalAlert({ isOpen: true, message: err?.response?.data?.error?.messages || 'エラー', type: 'error' })
    })

    return (
        <div>
            {
                isLoading ? (
                    <CircularProgressBox />
                ) : data?.data?.id && (
                    <BoxWrapper>
                        {
                            (data?.data?.isUsed || data?.data?.isExpired) && (
                                <UseCouponButton variant='contained' disabled={true}>{data?.data?.isUsed ? '使用済​' : data?.data?.isExpired ? '期限切​' : ''}​</UseCouponButton>
                            )
                        }
                        <Typography sx={{
                            fontSize: 20,
                            textAlign: 'center',
                        }}>取得日：{dayjs(data?.data?.createdAt).format('YYYY/MM/DD')}​</Typography>
                        <Typography sx={{
                            fontSize: 24,
                            textAlign: 'center',
                        }}>{data?.data?.name}​​</Typography>
                        {
                            !data?.data?.isUsed && !data?.data?.isExpired && (
                                <>
                                    <Typography sx={{
                                        fontSize: 16,
                                        textAlign: 'center',
                                    }}>{data?.data?.description}​​</Typography>
                                    <UseCouponButton variant='contained' onClick={() => setOpenModalUseConupon(true)}>使用する​</UseCouponButton>
                                </>
                            )
                        }
                        {
                            !data?.data?.isUsed && !data?.data?.isExpired && (
                                <Box>
                                    <Typography sx={{
                                        marginTop: 10,
                                        fontSize: 14,
                                    }}>※ 会計時に使用するボタンを押してください​</Typography>
                                    <Typography sx={{
                                        fontSize: 14,
                                    }}>※ 使用するボタンを複数回押さないでください​​</Typography>
                                </Box>
                            )
                        }
                    </BoxWrapper>
                )
            }
            <Modal
                open={openModalUseConupon}
                onClose={() => setOpenModalUseConupon(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center', margin: "0 20px" }}>
                        {data?.data?.name}​​
                    </Typography>
                    <Box id="modal-modal-description" sx={{ mt: 2, textAlign: 'center', margin: "0 20px" }}>
                        <Typography sx={{ textAlign: 'center', fontSize: 13 }}>
                            {data?.data?.description}
                        </Typography>
                        <br />
                        <Typography sx={{ textAlign: 'center' }}>
                            クーポンが使用されました​会計で提示して
                        </Typography>
                        <Typography sx={{ textAlign: 'center' }}>
                            店員の確認後に確認ボタンを​押してください
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <UseCouponButton disabled={handleUseCoupon.isPending} variant='contained' onClick={() => {
                            data?.data?.id &&
                                handleUseCoupon.mutate(data?.data?.id)
                        }}>確認​</UseCouponButton>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default CouponDetail
import React, { SVGProps } from "react";

const WattingToChatIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			id="_レイヤー_1"
			data-name="レイヤー 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 48.54 48.54"
			{...props}
		>
			<defs>
				<style>
					{`.cls-1 {
            fill: none;
          }
          .cls-1, .cls-2 {
            stroke-width: 0px;
          }
          .cls-2 {
            fill: #77cbf1;
          }`}
				</style>
			</defs>
			<rect
				className="cls-1"
				width="48.54"
				height="48.54"
				rx="14.97"
				ry="14.97"
			/>
			<path
				className="cls-2"
				d="m13.65,30.44c7.51-3.31,12.96-7.2,15.47-11.53,2.12-3.68.89-8.37-2.76-10.47-3.65-2.11-8.33-.82-10.46,2.85-2.51,4.33-3.13,11.13-2.26,19.15Z"
			/>
			<path
				className="cls-2"
				d="m16.75,33.76c5.15,3.77,9.9,5.93,13.8,5.93,3.31,0,6-2.66,6-5.93s-2.69-5.93-6-5.93c-3.9,0-8.72,2.23-13.8,5.93Z"
			/>
		</svg>
	);
};

export default WattingToChatIcon;

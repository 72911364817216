import {
	Box,
	FormControl,
	InputAdornment,
	MenuItem,
	Pagination,
	Select,
	Typography,
} from "@mui/material";
import CircularProgressBox from "components/utils/CircularProgressBox";
import { useGetListEvent } from "lib/api/event";
import { useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { AppUrl } from "components/utils/constants/AppUrl";

const BoxWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	gap: 20,
	alignItems: "center",
	width: "100%",
}));

const BoxEvents = styled(Box)({
	display: "flex",
	flexDirection: "column",
	gap: 10,
	width: "100%",
	margin: "10px 0px",
	backgroundColor: "#c1e2f6",
	padding: "1rem 1rem 0.4rem 1rem",
});

const BoxEventExpired = styled(Box)({
	margin: "auto",
	padding: 22,
	color: "white",
	border: "1px solid black",
	backgroundColor: "#AFABAB",
	fontSize: 26,
	fontWeight: 800,
	minWidth: "max-content",
	width: 80,
	textAlign: "center",
});
const BoxEventNotStart = styled(Box)({
	margin: "auto",
	padding: 22,
	color: "white",
	border: "1px solid black",
	backgroundColor: "#00B050",
	fontSize: 26,
	fontWeight: 800,
	minWidth: "max-content",
	width: 80,
	textAlign: "center",
});
const BoxEventRunning = styled(Box)({
	margin: "auto",
	padding: 22,
	color: "white",
	border: "1px solid black",
	backgroundColor: "#ED7D31",
	fontSize: 26,
	fontWeight: 800,
	minWidth: "max-content",
	width: 80,
	textAlign: "center",
});

const Event = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const page: number = Number(searchParams.get("page")) || 1;
	const per: number = Number(searchParams.get("per")) || 25;
	const { data, isLoading } = useGetListEvent({ page, per });
	const navigate = useNavigate();

	const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		searchParams.set("page", value.toString());
		setSearchParams(searchParams);
	};
	return (
		<>
			{isLoading ? (
				<CircularProgressBox />
			) : (
				<>
					{data?.data?.data?.length ? (
						<>
							<Box display="flex" justifyContent="flex-end">
								<FormControl
									sx={{
										width: "140px",
										marginLeft: "auto",
										backgroundColor: "#ffffff",
									}}
								>
									<Select
										value={per}
										onChange={(e) => {
											searchParams.set("per", e.target.value.toString());
											searchParams.set("page", "1");
											setSearchParams(searchParams);
										}}
										startAdornment={
											<InputAdornment position="start">
												<Typography
													variant="inherit"
													style={{ fontSize: "0.8rem" }}
												>
													表示件数
												</Typography>
											</InputAdornment>
										}
									>
										<MenuItem value={25}>25</MenuItem>
										<MenuItem value={50}>50</MenuItem>
										<MenuItem value={100}>100</MenuItem>
									</Select>
								</FormControl>
							</Box>

							<BoxWrapper>
								<BoxEvents>
									{data?.data?.data?.map((event) => (
										<Box key={event.id} display="flex" gap={5}>
											{event.isExpired ? (
												<BoxEventExpired>終了</BoxEventExpired>
											) : event.beforeEvent ? (
												<BoxEventNotStart>開催前</BoxEventNotStart>
											) : (
												<BoxEventRunning>開催中</BoxEventRunning>
											)}
											<Box
												sx={{
													display: "flex",
													gap: 1,
													flex: 1,
													flexDirection: "column",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												{event.isExpired ? (
													<Typography sx={{ fontSize: "0.8rem" }}>
														終了日：{dayjs(event.end).format("YYYY/MM/DD")}
													</Typography>
												) : event.beforeEvent ? (
													<>
														<Typography sx={{ fontSize: "0.8rem" }}>
															開催予定：
															{dayjs(event.start).format("YYYY/MM/DD")}
														</Typography>
														{event.end && (
															<Typography sx={{ fontSize: "0.8rem" }}>
																募集期限：
																{dayjs(event.end).format("YYYY/MM/DD")}
															</Typography>
														)}
													</>
												) : (
													event.end && (
														<Typography sx={{ fontSize: "0.8rem" }}>
															終了予定：{dayjs(event.end).format("YYYY/MM/DD")}
														</Typography>
													)
												)}

												<Typography
													sx={{
														textDecoration: "underline",
														cursor: "pointer",
														fontSize: 18,
													}}
													onClick={() => {
														navigate(AppUrl.EVENT + "/" + event.id);
													}}
												>
													{event.name}​
												</Typography>
											</Box>
										</Box>
									))}
								</BoxEvents>
								<Pagination
									page={Number(page)}
									count={data?.data?.totalPages}
									onChange={handleChangePage}
									siblingCount={1}
									boundaryCount={0}
								/>
							</BoxWrapper>
						</>
					) : (
						<Box sx={{ margin: "50px 0px" }}>
							<Typography sx={{ textAlign: "center" }}>
								新しいイベントはありません
							</Typography>
						</Box>
					)}
				</>
			)}
		</>
	);
};

export default Event;

import applyCaseMiddleware from "axios-case-converter"
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import Cookies from "js-cookie"
import { apiUrl, AppUrl } from "components/utils/constants/AppUrl"

// applyCaseMiddleware:
// axiosで受け取ったレスポンスの値をスネークケース→キャメルケースに変換
// または送信するリクエストの値をキャメルケース→スネークケースに変換してくれるライブラリ

// ヘッダーに関してはケバブケースのままで良いので適用を無視するオプションを追加
const options = {
  ignoreHeaders: true
}

const client = applyCaseMiddleware(axios.create({
  baseURL: apiUrl + "/api/v1"
}), options)

const shouldRedirectToLogin = true;
 
class Axios {
  private api: AxiosInstance;
 
  constructor(baseURL: string, noAuth: boolean) {
    this.api = applyCaseMiddleware(axios.create({
      baseURL,
    }));
    if (!noAuth) {
      this.api.interceptors.request.use(
        config => {
          const accessToken = this.getAccessToken();
          const client = this.getCLient();
          const uid = this.getUid();
          if (accessToken) {
            config.headers['access-token'] = `${accessToken}`;
            config.headers['client'] = `${client}`;
            config.headers['uid'] = `${uid}`;
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        },
      );
    } else 
    this.handleNoAuthsponse(this.api);
  }
 
  handleResponse(axios: AxiosInstance) {
    axios.interceptors.response.use(
      async (response: AxiosResponse) => {
        return response;
      },
      async error => {
        if (error.response.status === 403 && typeof window !== 'undefined') {
          //here show error when role of user not true

        }
        if (error.response.status === 401 && typeof window !== 'undefined') {
          console.log('Your session expired!');

          client.delete("auth/sign_out", { headers: {
            "access-token": Cookies.get("_access_token"),
            "client": Cookies.get("_client"),
            "uid": Cookies.get("_uid")
          }})
          if (shouldRedirectToLogin) {
            window.location.href = AppUrl.HOME;
          }
        }
        return Promise.reject(error);
      },
    );
  }
  handleNoAuthsponse(axios: AxiosInstance) {
    axios.interceptors.response.use(
      async (response: AxiosResponse) => {
        return response;
      },
      async error => {
        return Promise.reject(error);
      },
    );
  }
  async get<T>(url: string, configs?: AxiosRequestConfig): Promise<T> {
    try {
      const response = await this.api.get<T>(url, configs);
      return response.data;
    } catch (error) {
      console.error('GET request failed:', error);
      throw error;
    }
  }

  async post<T>(url: string, data: any, configs?: AxiosRequestConfig): Promise<T> {
    try {
      const response = await this.api.post<T>(url, data, configs);
 
      return response.data;
    } catch (error) {
      console.error('POST request failed:', error);
      throw error;
    }
  }
 
  async put<T>(url: string, data?: any, configs?: AxiosRequestConfig): Promise<T> {
    try {
      const response = await this.api.put<T>(url, data, configs);
      return response.data;
    } catch (error) {
      console.error('PUT request failed:', error);
      throw error;
    }
  }

  async patch<T>(url: string, data?: any, configs?: AxiosRequestConfig): Promise<T> {
    try {
      const response = await this.api.patch<T>(url, data, configs);
      return response.data;
    } catch (error) {
      console.error('PATCH request failed:', error);
      throw error;
    }
  }
 
  async delete<T>(url: string, configs?: AxiosRequestConfig): Promise<T> {
    try {
      const response = await this.api.delete<T>(url, configs);
      return response.data;
    } catch (error) {
      console.error('DELETE request failed:', error);
      throw error;
    }
  }
  private getAccessToken(): string {
    return  Cookies.get("_access_token") as string;
  }
  private getCLient(): string {
    return  Cookies.get("_client") as string;
  }
  private getUid(): string {
    return  Cookies.get("_uid") as string;
  }
}
 
export const axiosInstanceAuth = new Axios(apiUrl + "/api/v1" || 'http://localhost:8000', false);
export const axiosInstanceNoAuth = new Axios(apiUrl + "/api/v1" || 'http://localhost:8000', true);

export default client

import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { IACreateNotification } from 'interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from 'dayjs';
import { formatUTCDateTimeNotSecond } from 'lib/common/date.helper';
import { useNavigate, useParams } from 'react-router-dom';
import { AppUrl } from 'components/utils/constants/AppUrl';
import { useAdminCreateNotification, useAdminGetDetailNotification, useAdminUpdateNotification } from 'lib/api/admin-notification';
import { AlertContext } from 'App';
import AlertMessage from 'components/utils/AlertMessage';
import { handleChangeStateInput, handleChangeStateSelect } from 'lib/common/handle-change-state';
const TitleTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
  whiteSpace: 'pre',
}));
const ContentTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
  fontSize: 24,
  whiteSpace: 'pre',
}));
const BoxItem = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: 10,
	marginBottom: 1,
}));
const SubmitButton = styled(Button)(({ theme }) => ({
	width: 180,
	height: 60,
	fontSize: 20,
	borderRadius: 10,
	margin: "10px auto",
}));
interface renderInputProps {
  name: string
  placeholder: string
  value: string | number | undefined
  width?: number | string
  type?: React.HTMLInputTypeAttribute
  require?: boolean
}

interface renderDateTimePickerProps {
  name: string
  placeholder: string
  value: Dayjs | null | undefined
}
const CreateAndUpdateNotification = () => {
  const { id } = useParams();
  const isEdit = !!id

  const [dataCreate, setDataCreate] = useState<IACreateNotification>({
    value: "",
    link: "",
    publish_date: "",
  })
  const [isConfirmed, setIsConfirmed] = useState(false)
	const navigate = useNavigate();
  const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({ isOpen: false, message: "", type: "success" });
  const { setModalAlert:setModalAlertGlobal } = useContext(AlertContext)

  const { data, isLoading } = useAdminGetDetailNotification(Number(id))

  useEffect(() => {
    if(isEdit && data?.data){
      setDataCreate({
        link: data?.data?.link,
        publish_date: data?.data?.publishDate,
        value: data?.data?.value
      })
    }
  }, [isEdit, data])

  const handleCreateNotification = useAdminCreateNotification(
    (res) => {
			setModalAlertGlobal({ isOpen: true, message: res?.message, type: "success" });
			navigate(AppUrl.NOTIFICATION_MANAGEMENT);
		},
		(err) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.error?.messages || "エラー",
				type: "error",
			});
    }
  )
  const handleUpdateNotification = useAdminUpdateNotification(
    (res) => {
			setModalAlertGlobal({ isOpen: true, message: res?.message, type: "success" });
			navigate(AppUrl.NOTIFICATION_MANAGEMENT);
		},
		(err) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.error?.messages || "エラー",
				type: "error",
			});
    }
  )

  const renderInput = ({name, placeholder, value, width, type, require=true }: renderInputProps) => {
    if(!isConfirmed)
      return (
        <BoxItem>
          <TitleTypography>{placeholder}</TitleTypography>
          <TextField
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={handleChangeStateInput(setDataCreate)}
            sx={{ width }}
            type={type ?? "string"}
            InputProps={{
              inputProps: { 
                  min: 0,
                  step: "any"
              }
            }}
            required={require}
          />
        </BoxItem>
      )
    else return (
      <BoxItem> 
        <TitleTypography>{placeholder}</TitleTypography>
        <ContentTypography>{value}</ContentTypography>
      </BoxItem>
    )
  }

  const renderDateTimePicker = ({name, placeholder, value }: renderDateTimePickerProps) => {
    if(!isConfirmed)
      return (
        <BoxItem>
          <TitleTypography>{placeholder}</TitleTypography>
          <FormControl>
            <DateTimePicker 
              name={name}
              value={value}
              onChange={(e: Dayjs | null) => {
                e && handleChangeStateSelect(setDataCreate)(name, formatUTCDateTimeNotSecond(e))
              }}
              format="YYYY/MM/DD HH:mm"
              sx={{ maxWidth: "100%", width: 250 }}
              ampm={false}
            />
            <input
              type="text"
              name={name}
              value={value ? formatUTCDateTimeNotSecond(value) : ""}
              onChange={() => {}}
              required
              style={{
                position: 'absolute',
                opacity: 0,
                height: 0,
                width: 0,
                left: 0,
                top: "50%",
                pointerEvents: 'none',
              }}
            />
          </FormControl>
        </BoxItem>
      )
    else return (
      <BoxItem> 
        <TitleTypography>{placeholder}</TitleTypography>
        <ContentTypography>{value && formatUTCDateTimeNotSecond(value)}</ContentTypography>
      </BoxItem>
    )
  }
  const handleSubmit = () => {
    if(isConfirmed){
      handleCreateNotification.mutate(dataCreate)
    } else {
      if(isEdit) {
        handleUpdateNotification.mutate({ id: Number(id), body: dataCreate})
      } else setIsConfirmed(true)
    }
  }
  const handleBack = () => {
    if(!isConfirmed){
      navigate(AppUrl.NOTIFICATION_MANAGEMENT)
    } else {
      setIsConfirmed(false)
    }
  }
  return (
    <Box
			display="flex"
			flexDirection="column"
			gap={3}
			component="form"
			onSubmit={(e) => {
				e.preventDefault();
        handleSubmit()
			}}
		>
			<Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
        おしらせ{isEdit ? '編集' : '作成'}
			</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
          <Box 
            display="flex"
            gap={3}
            flexDirection="column"
          >
            {renderInput({
              name: "value",
              placeholder: "おしらせ​",
              value: dataCreate.value,
              width: "100%",
            })}
            {renderInput({
              name: "link",
              placeholder: "リンク​",
              value: dataCreate.link,
              width: "100%",
              require: false
            })}
            {renderDateTimePicker({
              name: "publish_date",
              placeholder: "公開日​",
              value: dataCreate.publish_date ? dayjs(dataCreate.publish_date): null
            })}
          </Box>
        </DemoContainer>
      </LocalizationProvider>
      <Box display="flex" sx={{ width: "40%", margin: "0 auto" }}>
        <SubmitButton variant="outlined" onClick={() => {
          handleBack()
        }}>
          戻る
        </SubmitButton>
        <SubmitButton variant="contained" type="submit" disabled={handleCreateNotification.isPending || handleUpdateNotification.isPending || isLoading}>
          {(isConfirmed && !isEdit) ? "送信" : "確認"}
        </SubmitButton>
      </Box>
      <AlertMessage
				open={modalAlert.isOpen}
				setOpen={(open: boolean) =>
					setModalAlert({ isOpen: open, message: "", type: "warning" })
				}
				severity={modalAlert.type}
				message={modalAlert.message}
			/>
    </Box>
  )
}
export default CreateAndUpdateNotification
import { useQuery } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import { BaseResponse, IAAttribute } from "interfaces";

export const useAdminGetListAttribute = () => {
	return useQuery({
		queryKey: ["ADMIN_GET_LIST_ATTRIBUTE"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get("admin/attributes");
			return res as BaseResponse<IAAttribute[]>;
		},
	});
};
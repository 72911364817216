import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import {
	BaseResponse,
	ErrorResponse,
	IFeedbackType,
	IListSurvey,
	ISurvey,
} from "interfaces";
import { AxiosError } from "axios";

export const useAdminGetFeedbackType = () => {
	return useQuery({
		queryKey: ["ADMIN_GET_FEEDBACK_TYPE"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get("/admin/feedback_types");
			return res as BaseResponse<IFeedbackType[]>;
		},
	});
};

export const useAdminGetListSurvey = (params: {
	page: number;
	per: number;
	name: string;
}) => {
	return useQuery({
		queryKey: ["ADMIN_GET_LIST_SURVEY", params.page, params.per, params.name],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				`/admin/feedbacks/filtering?page=${params.page}&per=${params.per}&name=${params.name}`
			);
			return res as BaseResponse<IListSurvey>;
		},
		enabled: !!params.page && !!params.per,
	});
};

export const useAdminGetDetailSurvey = (id: number) => {
	return useQuery({
		queryKey: ["ADMIN_GET_DETAIL_SURVEY", id],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(`/admin/feedbacks/${id}`);
			return res as BaseResponse<IListSurvey["data"][number]>;
		},
		enabled: !!id,
	});
};

export const useAdminCreateSurvey = (
	onOk?: (res: BaseResponse) => void,
	onErr?: (err: AxiosError<ErrorResponse>) => void
) => {
	return useMutation({
		mutationKey: ["ADMIN_CREATE_SURVEY"],
		mutationFn: ({
			feedback,
			feedbackTypeId,
		}: {
			feedback: ISurvey;
			feedbackTypeId: number;
		}) =>
			axiosInstanceAuth.post("/admin/feedbacks", {
				feedback,
				feedback_type_id: feedbackTypeId,
			}),
		onSuccess: (res) => {
			onOk && onOk(res as BaseResponse);
		},
		onError: (err) => {
			onErr && onErr(err as AxiosError<ErrorResponse>);
		},
	});
};

export const useAdminUpdateStatusSurvey = (
	onOk?: (res: BaseResponse) => void,
	onErr?: (err: AxiosError<ErrorResponse>) => void
) => {
	return useMutation({
		mutationKey: ["ADMIN_UPDATE_STATUS_SURVEY"],
		mutationFn: ({ id, status }: { id: number; status: 1 | 2 }) =>
			axiosInstanceAuth.put(`/admin/feedbacks/${id}/change_status`, {
				feedback: {
					feedback_status_attributes: {
						status,
					},
				},
			}),
		onSuccess: (res) => {
			onOk && onOk(res as BaseResponse);
		},
		onError: (err) => {
			onErr && onErr(err as AxiosError<ErrorResponse>);
		},
	});
};

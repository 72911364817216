import { Autocomplete, Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { IACreateEvent } from 'interfaces';
import { useAdminGetAllSustainability } from 'lib/api/admin-sustainability';
import { handleChangeStateInput, handleChangeStateSelect } from 'lib/common/handle-change-state';
import React, { useContext, useState } from 'react';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from 'dayjs';
import { formatUTCDateTime } from 'lib/common/date.helper';
import { useNavigate } from 'react-router-dom';
import { AppUrl } from 'components/utils/constants/AppUrl';
import { useAdminCreateEvent } from 'lib/api/admin-event';
import { AlertContext } from 'App';
import AlertMessage from 'components/utils/AlertMessage';

const TitleTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
  whiteSpace: 'pre',
}));

const ContentTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
  fontSize: 24,
  whiteSpace: 'pre',
}));

const BoxItem = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: 10,
	marginBottom: 1,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
	width: 180,
	height: 60,
	fontSize: 20,
	borderRadius: 10,
	margin: "10px auto",
}));

interface renderInputProps {
  name: string
  placeholder: string
  value: string | number | undefined
  width?: number | string
  type?: React.HTMLInputTypeAttribute
}
interface renderSelectProps {
  name: string
  placeholder: string
  value: string | number | undefined
  width?: number | string
  type?: React.HTMLInputTypeAttribute
  options?: {
    key: string | number
    label: string | number
  }[]
}
interface renderDateTimePickerProps {
  name: string
  placeholder: string
  value: Dayjs | null | undefined
}

const CreateEvent = () => {
  const [dataCreate, setDataCreate] = useState<IACreateEvent>({
    code: "",
    name: "",
    status: undefined,
    group_limit: undefined,
    sustainability_id: undefined,
    start: "",
    end: ""
  })
  const [isConfirmed, setIsConfirmed] = useState(false)
	const navigate = useNavigate();
  const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({ isOpen: false, message: "", type: "success" });
  const { setModalAlert:setModalAlertGlobal } = useContext(AlertContext)
  const {data: sustainabilities, isLoading } = useAdminGetAllSustainability()

  const handleCreateEvent = useAdminCreateEvent(
    (res) => {
			setModalAlertGlobal({ isOpen: true, message: res?.message, type: "success" });
			navigate(AppUrl.EVENT_MANAGEMENT);
		},
		(err) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.error?.messages || "エラー",
				type: "error",
			});
    }
  )

  const listGroupLimit = [
    {
      key: 0,
      label: 0,
    },
    {
      key: 1,
      label: 1,
    }
  ]
  const listStatus = [
    {
      key: 0,
      label: "無効",
    },
    {
      key: 1,
      label: "通常イベント",
    },
    {
      key: 2,
      label: "エントリーイベント",
    }
  ]
  
  const renderInput = ({name, placeholder, value, width, type }: renderInputProps) => {
    if(!isConfirmed)
      return (
        <BoxItem>
          <TitleTypography>{placeholder}</TitleTypography>
          <TextField
            name={name}
            required
            placeholder={placeholder}
            value={value}
            onChange={handleChangeStateInput(setDataCreate)}
            sx={{ width }}
            type={type ?? "string"}
            InputProps={{
              inputProps: { 
                  min: 0,
                  step: "any"
              }
            }}
          />
        </BoxItem>
      )
    else return (
      <BoxItem> 
        <TitleTypography>{placeholder}</TitleTypography>
        <ContentTypography>{value}</ContentTypography>
      </BoxItem>
    )
  }
  const renderSelect = ({name, placeholder, value, width, options }: renderSelectProps) => {
    if(!isConfirmed)
      return (
        <BoxItem>
          <TitleTypography>{placeholder}</TitleTypography>
          <FormControl sx={{ width: width, minWidth: 300 }}>
            <Autocomplete
              options={options || []}
              value={options?.find(itemOption => value === itemOption?.key) || null as any}
              onChange={(event, newValue) => {
                handleChangeStateSelect(setDataCreate)(name, newValue?.key)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  InputLabelProps={{ required: false }}
                />
              )}
              sx={{ width, maxWidth: "100%" }}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              disableClearable
              loading={isLoading}
              getOptionLabel={(option) => String(option.label)} 
            />
            <input
              type="text"
              name={name}
              value={value ?? ''}
              onChange={() => {}}
              required
              style={{
                position: 'absolute',
                opacity: 0,
                height: 0,
                width: 0,
                pointerEvents: 'none',
              }}
            />
          </FormControl>
        </BoxItem>
      )
    else return (
      <BoxItem> 
        <TitleTypography>{placeholder}</TitleTypography>
        <ContentTypography>{options?.find(item => item.key === value)?.label}</ContentTypography>
      </BoxItem>
    )
  }
  const renderDateTimePicker = ({name, placeholder, value }: renderDateTimePickerProps) => {
    if(!isConfirmed)
      return (
        <BoxItem>
          <TitleTypography>{placeholder}</TitleTypography>
          <FormControl>
            <DateTimePicker 
              name={name}
              value={value}
              onChange={(e: Dayjs | null) => {
                e && handleChangeStateSelect(setDataCreate)(name, formatUTCDateTime(e))
              }}
              format="YYYY/MM/DD HH:mm:ss"
              sx={{ maxWidth: "100%" }}
              ampm={false}
            />
            <input
              type="text"
              name={name}
              value={value ? formatUTCDateTime(value) : ""}
              onChange={() => {}}
              required
              style={{
                position: 'absolute',
                opacity: 0,
                height: 0,
                width: 0,
                right: 20,
                top: "50%",
                pointerEvents: 'none',
              }}
            />
          </FormControl>
        </BoxItem>
      )
    else return (
      <BoxItem> 
        <TitleTypography>{placeholder}</TitleTypography>
        <ContentTypography>{value && formatUTCDateTime(value)}</ContentTypography>
      </BoxItem>
    )
  }

  const handleSubmit = () => {
    if(isConfirmed){
      handleCreateEvent.mutate(dataCreate)
    } else {
      setIsConfirmed(true)
    }
  }
  const handleBack = () => {
    if(!isConfirmed){
      navigate(AppUrl.EVENT_MANAGEMENT)
    } else {
      setIsConfirmed(false)
    }
  }

  return (
    <Box
			display="flex"
			flexDirection="column"
			gap={3}
			component="form"
			onSubmit={(e) => {
				e.preventDefault();
        handleSubmit()
			}}
		>
			<Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
        イベント作成
			</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
          <Box 
            display="flex"
            gap={3}
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              }
            }}
          >
            {renderInput({
              name: "code",
              placeholder: "認識Code",
              value: dataCreate.code
            })}
            {renderInput({
              name: "name",
              placeholder: "イベント名",
              value: dataCreate.name,
              width: 350,
            })}
            {renderDateTimePicker({
              name: "start",
              placeholder: "開始日",
              value: dataCreate.start ? dayjs(dataCreate.start): null
            })}
            {renderDateTimePicker({
              name: "end",
              placeholder: "終了日",
              value: dataCreate.end ? dayjs(dataCreate.end) : null
            })}
          </Box>
        </DemoContainer>
      </LocalizationProvider>
      {
        renderSelect({
          name: "status",
          placeholder: "イベントステータス",
          width: 350,
          value: dataCreate.status,
          options: listStatus
        })
      }
      {
        renderSelect({
          name: "group_limit",
          placeholder: "グループリミット ※0の場合は全員参加",
          width: 350,
          value: dataCreate.group_limit,
          options: listGroupLimit
        })
      }
      {
        renderSelect({
          name: "sustainability_id",
          placeholder: "サステナビリティ目標",
          width: 350,
          value: dataCreate.sustainability_id,
          options: sustainabilities?.data?.map(item => ({
            key: item.id,
            label: item.codeName
          }))
        })
      }

      <Box display="flex" sx={{ width: "40%", margin: "0 auto" }}>
        <SubmitButton variant="outlined" onClick={() => {
          handleBack()
        }}>
          戻る
        </SubmitButton>
        <SubmitButton variant="contained" type="submit" disabled={isLoading || handleCreateEvent.isPending}>
          {isConfirmed ? "送信" : "確認"}
        </SubmitButton>
      </Box>
      <AlertMessage
				open={modalAlert.isOpen}
				setOpen={(open: boolean) =>
					setModalAlert({ isOpen: open, message: "", type: "warning" })
				}
				severity={modalAlert.type}
				message={modalAlert.message}
			/>
    </Box>
  )
}

export default CreateEvent

import { Box } from "@mui/material";
import { useState } from "react";
import { IFeedbackType, ISurvey } from "interfaces";
import CreateSurveyStep1 from "./Step1";
import CreateSurveyStep2 from "./Step2";
import CreateSurveyStep3 from "./Step3";
import { useAdminCreateSurvey } from "lib/api/admin-survey";
import AlertMessage from "components/utils/AlertMessage";
import { useNavigate } from "react-router-dom";
import { AppUrl } from "components/utils/constants/AppUrl";

const CreateSurvey = () => {
	const [step, setStep] = useState<1 | 2 | 3>(1);
	const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({ isOpen: false, message: "", type: "success" });
	const [surveyData, setSurveyData] = useState<ISurvey>({
		code: "",
		name: "",
		value: [],
		feedback_status_attributes: {
			end: "",
			start: "",
			feedback_type_id: undefined,
		},
	});
	const [feedbackType, setFeedbackType] = useState<IFeedbackType | undefined>(
		undefined
	);
	const navigate = useNavigate();

	const handleCreateSurvey = useAdminCreateSurvey(
		(res) => {
			setModalAlert({ isOpen: true, message: res?.message, type: "success" });
			navigate(AppUrl.SURVEY_MANAGEMENT);
		},
		(err) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.error?.messages || "エラー",
				type: "error",
			});
		}
	);
	const handleSubmit = () => {
		feedbackType?.id &&
			handleCreateSurvey.mutate({
				feedback: surveyData,
				feedbackTypeId: feedbackType?.id,
			});
	};
	return (
		<Box>
			{step === 1 ? (
				<CreateSurveyStep1
					onSubmit={(fbType) => {
						setSurveyData({
							...surveyData,
							name: fbType.name,
						});
						setFeedbackType(fbType);
						setStep(2);
					}}
				/>
			) : step === 2 ? (
				<CreateSurveyStep2
					onSubmit={(surveyDataForm) => {
						setSurveyData(surveyDataForm);
						setStep(3);
					}}
					surveyData={surveyData}
					feedbackType={feedbackType}
				/>
			) : step === 3 ? (
				<CreateSurveyStep3
					onSubmit={handleSubmit}
					surveyData={surveyData}
					onCancel={() => {
						setStep(2);
					}}
					isLoading={handleCreateSurvey.isPending}
				/>
			) : (
				<></>
			)}
			<AlertMessage
				open={modalAlert.isOpen}
				setOpen={(open: boolean) =>
					setModalAlert({ isOpen: open, message: "", type: "warning" })
				}
				severity={modalAlert.type}
				message={modalAlert.message}
			/>
		</Box>
	);
};

export default CreateSurvey;

import {
	Box,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import GroupChattingIcon from "components/icon/GroupChattingIcon";
import WattingToChatIcon from "components/icon/WattingToChatIcon";
import CircularProgressBox from "components/utils/CircularProgressBox";
import { useGetMachineStatus } from "lib/api/status";

const TextName = styled(Typography)({
	marginTop: "1rem",
	fontWeight: 400,
	fontSize: "1.2rem",
});

const TextTitle = styled(Typography)({
	marginTop: "1rem",
	fontWeight: 400,
	fontSize: "1.4rem",
});

const TextDescription = styled(Typography)({
	marginTop: "0",
	fontWeight: 700,
	fontSize: "2rem",
});

const WashingMachineStatus = () => {
	const statusCode: string = 'washing_machine01';
	const statusName: string = 'マイボトル洗浄機01';

	const { data, isLoading } = useGetMachineStatus(statusCode);
	const BoxImageStatus = styled(Box)(() => ({
		width: "100%",
		height: "200px",
		margin: "15px auto 0",
		backgroundImage: `url(${data?.data?.image?.value})`,
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	}));

	return (
		<Box>
			{isLoading ? (
				<Box marginTop={2}>
					<CircularProgressBox />
				</Box>
			) : data?.data?.id ? (
				<>
					<TextName align="left">{statusName}</TextName>

					<TextTitle>洗浄機の利用状況</TextTitle>
					<TextDescription>{data?.data?.statusI18n}</TextDescription>
					<TextTitle>ただいまの状況</TextTitle>
					<Box display="flex" alignItems="center" justifyContent="center">
						<TextDescription>{data?.data?.situation}</TextDescription>
						{data?.data?.situation === "憩い待ち" ? (
							<WattingToChatIcon width={48.54} height={48.54} />
						) : data?.data?.situation === "大勢憩い中" ? (
							<GroupChattingIcon width={48.54} height={48.54} />
						) : (
							<></>
						)}
					</Box>
					<BoxImageStatus />
				</>
			) : (
				<></>
			)}
		</Box>
	);
};

export default WashingMachineStatus;

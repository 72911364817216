export const AppUrl= {
  HOME: '/',
  SIGN_UP: '/signup',
  SIGN_IN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  MY_BOTTLE: "/my-bottle",
  REGISTER_MY_BOTTLE: '/my-bottle/register',
  REGISTER_MANUALLY_MY_BOTTLE: '/my-bottle/register-manually',
  COUPON : '/coupon',
  COUPON_DETAIL : '/coupon/:id',
  PROFILE: '/profile',
  USER_MANAGEMENT: '/user-management',
  USER_MANAGEMENT_SIGN_UP: '/user-management/sign-up',
  DATA_MANAGEMENT: "/data-management",
  CLEAN_SYSTEM: '/clean-system',
  USER_DELETE: '/user-delete',
  NOTIFICATION: '/notification',
  EVENT: '/events',
  EVENT_DETAIL: '/events/:id',
  SURVEY_MANAGEMENT: '/survey-management',
  CREATE_SURVEY: '/survey-management/create',
  SURVEY_DETAIL : '/survey-management/:id',
  WASHING_MACHINE: '/clean-system/status',
  SUSTAINABILITY_MANAGEMENT: '/sustainability-management',
  CREATE_SUSTAINABILITY: '/sustainability-management/create',
  SUSTAINABILITY_DETAIL : '/sustainability-management/:id',
  COUPON_MANAGEMENT: '/coupon-management',
  CREATE_AND_UPDATE_COUPON: '/coupon-management/create-update',
  CREATE_AND_UPDATE_COUPON_DETAIL : '/coupon-management/create-update/:id',
  EVENT_MANAGEMENT: '/event-management',
  CREATE_EVENT: '/event-management/create',
  ADMIN_EVENT_DETAIL : '/event-management/:id',
  NOTIFICATION_MANAGEMENT: '/notification-management',
  CREATE_NOTIFICATION: '/notification-management/create',
  NOTIFICATION_DETAIL : '/notification-management/:id',
  SETTING_SHOW : '/setting-show',
}

export const webUrl = process.env.REACT_APP_ENVIRONMENT === "development" ? process.env.REACT_APP_WEB_URL : window.__env?.REACT_APP_WEB_URL;
export const apiUrl = process.env.REACT_APP_ENVIRONMENT === "development" ? process.env.REACT_APP_API_URL : window.__env?.REACT_APP_API_URL 


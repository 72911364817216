import { AddCircleOutline } from '@mui/icons-material';
import { Autocomplete, Box, Button, Divider, FormControl, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from "@mui/material/styles";
import { IACreateSustainability } from 'interfaces';
import { useAdminGetListAttribute } from 'lib/api/admin-attribute';
import React, { ChangeEventHandler } from 'react';

interface CreateSustainabilityStep1Props {
  data: IACreateSustainability
  onSubmit?: Function
  onChange?: (e:ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, customValue?: number | string) => void
  onBack?: Function
  onChangeSelect?: (name: string, value: number) => void
  onAddContent?: Function
  isEdit: boolean
  isEditDetail?: boolean
}

const TitleTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
  whiteSpace: 'pre',
}));
const ContentTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
  fontSize: 24,
  whiteSpace: 'pre',
}));

const BoxItem = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: 10,
	marginBottom: 1,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
	width: 180,
	height: 60,
	fontSize: 20,
	borderRadius: 10,
	margin: "10px auto",
}));

interface renderInputProps {
  name: string
  placeholder: string
  value: string | number | undefined
  width?: number | string
  type?: React.HTMLInputTypeAttribute
}

const CreateSustainabilityStep1 = ({ onSubmit, onChange, data, onBack, onChangeSelect, onAddContent, isEdit, isEditDetail } : CreateSustainabilityStep1Props) => {
  const {data: attributes } = useAdminGetListAttribute()
  const theme = useTheme();
  const renderInput = ({name, placeholder, value, width, type }: renderInputProps) => {
    if(isEdit)
      return (
        <BoxItem>
          <TitleTypography>{placeholder}</TitleTypography>
          <TextField
            name={name}
            required
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              if(name === "mission.factor"){
                data?.sustainability_successes_attributes?.forEach((item, index) => {
                  if(item.achievement){
                    const customEvent = { ...e } as any
                    customEvent.target.name = `sustainability_successes_attributes.${index}.achievement`
                    onChange && onChange(customEvent, parseFloat((item.achievement / Number(data?.mission?.factor) * Number(e.target.value)).toFixed(3)))
                  }
                })
                const customEvent = { ...e } as any
                customEvent.target.name = name
                onChange && onChange(customEvent)
              } else
                onChange && onChange(e as any)
            }}
            sx={{ width }}
            type={type ?? "string"}
            InputProps={{
              inputProps: { 
                  step: "any"
              }
            }}
          />
        </BoxItem>
      )
    else return (
      <BoxItem> 
        <TitleTypography>{placeholder}</TitleTypography>
        <ContentTypography>{value}</ContentTypography>
      </BoxItem>
    )
  }
  const renderSelect = ({name, placeholder, value, width }: renderInputProps) => {
    if(isEdit)
      return (
        <BoxItem>
          <TitleTypography>{placeholder}</TitleTypography>
          <FormControl sx={{ width: width, minWidth: 300 }}>
            <Autocomplete
              options={attributes?.data ?? []}
              getOptionLabel={(option) => option.codeName}
              value={attributes?.data?.find(itemOption => value === itemOption?.id) || null as any}
              onChange={(event, newValue) => {
                onChangeSelect && onChangeSelect(name, newValue?.id || 0)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  InputLabelProps={{ required: false }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable
            />
            <input
              type="text"
              name={name}
              value={value}
              onChange={() => {}}
              required
              style={{
                position: 'absolute',
                opacity: 0,
                height: 0,
                width: 0,
                pointerEvents: 'none',
              }}
            />
          </FormControl>
        </BoxItem>
      )
    else return (
      <BoxItem> 
        <TitleTypography>{placeholder}</TitleTypography>
        <ContentTypography>{attributes?.data?.find(item => item.id === value)?.codeName}</ContentTypography>
      </BoxItem>
    )
  }

  const DividerComponent = () => {
    return (
      <Divider orientation={useMediaQuery(theme.breakpoints.down("md")) ? "horizontal" : "vertical"} flexItem />
    )
  }

  return (
    <Box
			display="flex"
			flexDirection="column"
			gap={3}
			component="form"
			onSubmit={(e) => {
				e.preventDefault();
        onSubmit && onSubmit()
			}}
		>
			<Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
        サステナビリティ目標{isEditDetail ? "編集" : "作成"}
			</Typography>
      <Box 
        display="flex"
        gap={3}
        sx={{
          flexDirection: {
            xs: "column",
            sm: "row",
          }
        }}
      >
        {renderInput({
          name: "code",
          placeholder: "認識Code",
          value: data.code
        })}
        {renderInput({
          name: "name",
          placeholder: "サステナビリティ目標名",
          value: data.name,
          width: 400,
        })}
      </Box>
      {
        renderSelect({
          name: "attribute_id",
          placeholder: "属性",
          width: "max-content",
          value: data.attribute_id,
        })
      }
      <Box 
        display="grid"
        gap={3}
        sx={{
          gridTemplateColumns: 'repeat(3, min-content)',
        }}
      >
        {renderInput({
          name: "mission.factor",
          placeholder: "属性利用1回に紐づく数値",
          value: data.mission.factor || undefined,
          type: "number"
        })}
        <DividerComponent />
        {renderInput({
          name: "mission.unit",
          placeholder: "単位",
          value: data.mission.unit,
          width: 100
        })}
        {
          data?.mission?.content.map((item, index) => (
            <React.Fragment key={index}>
              <Box sx={{ gridColumn: 'span 3' }}>
                <TitleTypography fontWeight="bold" fontSize={20}>コンテンツ{index + 1}</TitleTypography>
              </Box>
              {
              renderInput({
                name: `mission.content.${index}.title`,
                placeholder: "タイトル",
                value: item.title,
                width: 400
              })
            }
            <DividerComponent />
            {
              renderInput({
                name: `mission.content.${index}.unit`,
                placeholder: "単位",
                value: item.unit,
                width: 100
              })
            }
            </React.Fragment>
          ))
        }
      </Box>
      <Box>
        {
          isEdit && (
            <IconButton
              aria-label="add"
              size="large"
              onClick={() => onAddContent && onAddContent()}
            >
              <AddCircleOutline
                color="primary"
                sx={{ width: 30, height: 30 }}
              />
            </IconButton>
          )
        }
      </Box>
      {
        isEdit && (
          <Box display="flex" sx={{ width: "40%", margin: "0 auto" }}>
            <SubmitButton variant="outlined" onClick={() => {
              onBack && onBack()
            }}>
              戻る
            </SubmitButton>
            <SubmitButton variant="contained" type="submit">
              次へ
            </SubmitButton>
          </Box>
        )
      }
    </Box>
  )
}

export default CreateSustainabilityStep1

import {
	Box,
	Typography,
	Select,
	MenuItem,
	InputBase,
	Pagination,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
	SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { AppUrl } from "components/utils/constants/AppUrl";
import React, { useState } from "react";
import CircularProgressBox from "components/utils/CircularProgressBox";
import { useAdminGetListCoupon } from "lib/api/admin-coupon";

const BoxRowPerPage = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	gap: 5,
	width: "100%",
});

const Search = styled(Box)(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	width: "100%",
	border: "1px black solid",
	margin: "10px 0 0",
	display: "flex",
}));

const SearchIconWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	right: 10,
	cursor: "pointer",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	width: "100%",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(2)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
	},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	gap: 5,
	alignItems: "center",
	margin: "1rem auto 0 auto",
	width: "100%",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(even)": {
		backgroundColor: theme.palette.action.hover,
	},
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const CouponManagement = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const page: number = Number(searchParams.get("page")) || 1;
	const per: number = Number(searchParams.get("per")) || 25;
	const name: string = searchParams.get("name") || "";
	const [searchValue, setSearchValue] = useState(name);

	const navigate = useNavigate();

	const { data, isLoading } = useAdminGetListCoupon({
		page,
		per,
		name,
	});

	const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		searchParams.set("page", value.toString());
		setSearchParams(searchParams);
	};
	const handleChangePer = (e: SelectChangeEvent<number>) => {
		searchParams.set("per", e.target.value.toString());
		searchParams.set("page", "1");
		setSearchParams(searchParams);
	}

	return (
		<Box>
			<BoxRowPerPage>
				<Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
					クーポン一覧
				</Typography>
				<Select
					value={per}
					onChange={handleChangePer}
					sx={{ minWidth: 100 }}
				>
					<MenuItem value={25}>25</MenuItem>
					<MenuItem value={50}>50</MenuItem>
					<MenuItem value={100}>100</MenuItem>
				</Select>
			</BoxRowPerPage>
			<Search>
				<StyledInputBase
					placeholder="クーポンをNAMEで検索する"
					value={searchValue}
					onChange={(e) => {
						setSearchValue(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							searchParams.set("name", searchValue);
							searchParams.set("page", "1");
							setSearchParams(searchParams);
						}
					}}
				/>
				<SearchIconWrapper>
					<SearchIcon
						onClick={() => {
							searchParams.set("name", searchValue);
							searchParams.set("page", "1");
							setSearchParams(searchParams);
						}}
					/>
				</SearchIconWrapper>
			</Search>
			<BoxWrapper>
				{isLoading ? (
					<Box sx={{ width: "100%" }}>
						<CircularProgressBox />
					</Box>
				) : (
					<TableContainer component={Paper}>
						<Table aria-label="coupon data table" stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell align="center">ID</TableCell>
									<TableCell align="center">CODE</TableCell>
									<TableCell align="center">NAME</TableCell>
									<TableCell align="center">DESCRIPTION</TableCell>
									<TableCell align="center">DEADLINE_DAY</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{data?.data?.data?.map((row) => (
									<React.Fragment key={row.id}>
										<StyledTableRow
											sx={{ "td, &:last-child th": { border: 0 } }}
										>
											<TableCell align="center">{row.id}</TableCell>
											<TableCell align="center" sx={{ whiteSpace: 'pre'}}>{row.code}</TableCell>
											<TableCell
												align="center"
												sx={{ textDecoration: "underline", cursor: "pointer" }}
												onClick={() => {
													navigate(AppUrl.CREATE_AND_UPDATE_COUPON + "/" + row.id);
												}}
											>
												{row.name}
											</TableCell>
											<TableCell align="center">{row.description}</TableCell>
											<TableCell align="center">{row.deadlineDay}</TableCell>
										</StyledTableRow>
									</React.Fragment>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
				<Pagination
					page={Number(page)}
					count={data?.data?.totalPages}
					onChange={handleChangePage}
					siblingCount={1}
					boundaryCount={0}
				/>
			</BoxWrapper>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<Button
					sx={{
						textDecoration: "underline",
						cursor: "pointer",
						fontSize: 16,
						color: "black",
					}}
					variant="text"
					onClick={() => navigate(AppUrl.CREATE_AND_UPDATE_COUPON)}
				>
					クーポン作成
				</Button>
			</Box>
		</Box>
	);
};

export default CouponManagement;

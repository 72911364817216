import dayjs, { Dayjs } from 'dayjs'

export const formatUTCTime = (time: string) => {
    return dayjs(time).format('YYYY/MM/DD')
}

export const formatUTCDateTime = (time: string | Dayjs) => {
    return dayjs(time).format('YYYY/MM/DD HH:mm:ss')
}

export const formatUTCDateTimeNotSecond = (time: string | Dayjs) => {
    return dayjs(time).format('YYYY/MM/DD HH:mm')
}
import { axiosInstanceAuth } from "./client"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios";
import { BaseResponse, ErrorResponse, IListUser, IResponseRoles, SignUpParamsAdmin } from "interfaces"

export const useOwnerGetListUser = (params: {
    page: number
    per: number
}) => {
  return useQuery({
    queryKey: ['ADMIN_GET_LIST_USER', params.page, params.per],
    queryFn: async () => {
      const res = await axiosInstanceAuth.get(`/admin/users/filtering?page=${params.page}&per=${params.per}`);
      return res as BaseResponse<IListUser>;
    },
    enabled: !!params.page && !!params.per
  });
};

export const useDeleteUser = (onOk?: Function, onErr?: Function) => {
  return useMutation({
    mutationKey: ['ADMIN_DELETE_USER'],
    mutationFn: (id: number) =>  axiosInstanceAuth.delete(`/admin/users/${id}`),
    onSuccess: (res) => {
      onOk && onOk(res)
    },
    onError: (err) => {
        onErr && onErr(err)
    }
  })
}
export const useAdminSignUp = (onOk?: Function , onErr?: (err: AxiosError<ErrorResponse>) => void) => { 
  return useMutation({
    mutationKey: ['ADMIN_SIGN_UP_ACCOUNT'],
    mutationFn: (params: SignUpParamsAdmin) => axiosInstanceAuth.post(`/auth`, params),
    onSuccess: (res: any) => {
      onOk && onOk()
    },
    onError: (err: AxiosError<ErrorResponse>) => {
      onErr && onErr(err)
    }
})}

export const useGetDataAll = (onOk?: Function, onErr?: Function) => {
	return useMutation({
		mutationKey: ["GET DATA ALL USERS EXPORT"],
		mutationFn: ({
			startDate,
			endDate,
		}: {
			startDate: string;
			endDate: string;
		}) =>
			axiosInstanceAuth.get(
				`/admin/users/export?start_date=${startDate}&end_date=${endDate}`,
				{ responseType: "blob" }
			),
		onSuccess: (res) => {
			onOk && onOk(res);
		},
		onError: (err: AxiosError<ErrorResponse>) => {
			onErr && onErr(err);
		},
	});
};

export const useAdminGetRoles = () => {
  return useQuery({
    queryKey: ['ADMIN_GET_ROLES'],
    queryFn: async () => {
      const res = await axiosInstanceAuth.get("/admin/roles");
      return res as BaseResponse<IResponseRoles>;
    },
  });
};

export const useChangePasswordUser = (onOk?: Function, onErr?: Function) => {
  return useMutation({
    mutationKey: ['ADMIN_CHANGE_PASSWORD_USER'],
    mutationFn: (id: number) =>  axiosInstanceAuth.post(`/admin/users/${id}/reset_password`, {}),
    onSuccess: (res) => {
      onOk && onOk(res)
    },
    onError: (err) => {
        onErr && onErr(err)
    }
  })
}
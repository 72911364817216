import { axiosInstanceAuth } from "./client"
import { useMutation } from '@tanstack/react-query';
import { ErrorResponse, User, IResponseStatus, IRequestUpdateStatus } from "interfaces"
import { AxiosError } from "axios";
import dayjs from 'dayjs';

export const useGetUserByTag = (onOk?: Function , onErr?:Function) => {
    return useMutation<{data: User}, AxiosError<ErrorResponse>, string>({
        mutationKey: ['GET USER BY TAG'],
        mutationFn:async (code: string) => axiosInstanceAuth.post('/tags/handle_efc', JSON.stringify({
            "version": 2.0,
            "status": 1,
            "data": {
                "what": [{"eventName": "Came_Here", "confidence": "1.00", "eventValue": "1"}],
                "tag_id": code,
                "owner_cd": "MBL001",
                "device_cd": "99",
                "timestamp": dayjs().valueOf().toString(),
                "location_cd": "S001",
                "attribute_cd": "Reuse01",
                "detected_gateway_id": "WM0000000001"
            }
        })),
        onSuccess: (res,variables) => {
            onOk && onOk(res, variables)
        },
        onError: (err: AxiosError<ErrorResponse>) => {
            onErr && onErr(err)
        }
    })
}

export const useCheckStatus = (onOk?: Function , onErr?:Function) => {
    return useMutation<{data: IResponseStatus}, AxiosError<ErrorResponse>, string>({
        mutationKey: ['GET STATUS'],
        mutationFn: () => axiosInstanceAuth.get('/status?code=washing_machine01'),
        onSuccess: (_res) => {
            onOk && onOk(_res)

        },
        onError: (err: AxiosError<ErrorResponse>) => {
            onErr && onErr(err)
        },
        retry: false,
    })
}

export const useUpdateStatus = (onOk?: Function , onErr?:Function) => {
    return useMutation({
        mutationKey: ['UPDATE STATUS'],
        mutationFn: (body:IRequestUpdateStatus) => axiosInstanceAuth.put('/status/change_status?code=washing_machine01', body),
        onSuccess: () => {
            onOk && onOk()
        },
        onError: (err: AxiosError<ErrorResponse>) => {
            onErr && onErr(err)
        }
    })
}

import {
	Avatar,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Modal,
	Radio,
	TextField,
	Typography,
} from "@mui/material";
import AvatarDefault from "./AvatarDefault";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { ISurvey } from "interfaces";
import { useState } from "react";
import CleanSystemScreenShot from "components/pages/CleanSystem/CleanSystemScreenShot";

const BoxFeedbacks = styled(Box)({
	display: "flex",
	flexDirection: "column",
	gap: 15,
	marginTop: 10,
});

const ChooseButton = styled(Button)({
	padding: '8px 20px',
	alignSeft: 'center',
	flex: 1, 
	textTransform: 'none', 
	fontSize: 15, 
	fontKerning: 'none', 
});

const ComplainButton = styled(Button)({
	padding: "10px 20px",
	fontSize: 20,
	borderRadius: 15,
});

const FeedbackButton = styled(Button)({
	padding: "20px 36px",
	fontSize: 28,
	border: "1px solid black",
	borderRadius: 15,
});

const AvatarCamera = styled(Avatar)({
	height: 100,
	width: 100,
	"& .MuiAvatar-img": {
		objectFit: "contain",
	},
});

interface CleanSystemFeedbackComponentProps {
	feedbackValue: ISurvey;
}

const CleanSystemFeedbackComponent = ({
	feedbackValue,
}: CleanSystemFeedbackComponentProps) => {
	const [openModalScreenShot, setOpenModalScreenShot] = useState(false);
	const [listImage, setListImage] = useState<string[]>([]);

	return (
		<Box sx={{ marginTop: "80px" }}>
			<Box
				sx={{
					backgroundColor: "#0078bb",
					textAlign: "center",
					padding: "0.8rem 0",
				}}
			>
				<Typography sx={{ fontSize: 22, fontWeight: 800, color: "#fff100" }}>
					{feedbackValue.name}
				</Typography>
			</Box>
			<Box sx={{ textAlign: "center", padding: "0.5rem 0" }}>
				<Box
					display="flex"
					alignItems="center"
					sx={{ justifyContent: "center" }}
				>
					<AvatarDefault width={64} height={64} src={""} text={""} />
					<Box marginLeft={2}>
						<Typography variant="inherit" component="div">
							<Typography
								component="span"
								sx={{ fontSize: 23, fontWeight: "bold" }}
							>
								〇〇
							</Typography>
							<Typography
								component="span"
								sx={{ marginLeft: "0.8rem", fontSize: 19 }}
							>
								さん、ご利用ありがとうございます
							</Typography>
						</Typography>
					</Box>
				</Box>
				<Box sx={{ textAlign: "center" }}>
					<Typography sx={{ fontSize: 14 }}>
						違うニックネームが表示されているとき、
						<Link>こちらをタップ</Link>
						してQRコードを読み込ませてください
					</Typography>
				</Box>
			</Box>

			<BoxFeedbacks>
				{feedbackValue?.value?.map((data, index) => {
					const getDefaultValue = () => {
						const current = data?.answer?.find((data) => data.check)?.value;
						return current ?? "";
					};
					return (
						<Box key={data.question}>
							<Box
								sx={{
									backgroundColor: "#21b8ce",
									textAlign: "center",
									padding: "0.5rem 0",
									marginBottom: "0.8rem",
								}}
							>
								<Typography sx={{ fontSize: 18, color: "#ffffff" }}>
									{data?.question}
								</Typography>
							</Box>
							<FormGroup
								row
								defaultValue={getDefaultValue()}
								sx={{
									display: "flex",
									gap: "15px",
									flexWrap: "wrap",
									paddingTop: 1,
									justifyContent: "space-between",
									fontSize: 16,
								}}
							>
								{data?.answer?.map((item) => {
									if (item.type === "radio")
										return (
											<FormControlLabel
												value={item?.value}
												control={
													<Radio
														defaultChecked={item.check}
														sx={{
															"& .MuiSvgIcon-root": {
																fontSize: 28,
															},
														}}
													/>
												}
												label={item?.value}
												key={item?.value}
												sx={{
													"& .MuiTypography-root": {
														fontSize: 25,
													},
												}}
											/>
										);
									if (item.type === "checkbox")
										return (
											<FormControlLabel
												value={item?.value}
												control={
													<Checkbox
														defaultChecked={item.check}
														sx={{
															"& .MuiSvgIcon-root": {
																fontSize: 28,
															},
														}}
													/>
												}
												label={item?.value}
												key={item?.value}
												sx={{
													"& .MuiTypography-root": {
														fontSize: 25,
													},
												}}
											/>
										);
									else if (item.type === "button")
										return (
											<ChooseButton
												key={item?.value}
												variant={item.check ? "contained" : "outlined"}
											>
												{item.value}
											</ChooseButton>
										);
									else if (item.type === "text")
										return (
											<TextField
												variant="outlined"
												sx={{
													width: "100%",
												}}
												key={item?.value}
											/>
										);
									else return <></>;
								})}
							</FormGroup>
						</Box>
					);
				})}
			</BoxFeedbacks>
			<Box
				sx={{
					marginTop: 1,
					display: "flex",
					width: "100%",
					gap: 3,
					justifyContent: "center",
					flexWrap: "wrap",
				}}
			>
				<Box
					sx={{
						marginTop: 1,
						display: "flex",
						width: "100%",
						gap: 3,
						justifyContent: "center",
						flexWrap: "wrap",
					}}
				>
					{listImage?.map((img, index) => (
						<AvatarCamera
							alt={`Image Feedback ${index}`}
							variant="square"
							src={img}
							key={`Image Feedback ${index}`}
						/>
					))}
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<ComplainButton
							color="error"
							variant="outlined"
							onClick={(e) => {
								e.preventDefault();
								setOpenModalScreenShot(true);
							}}
						>
							撮影する
						</ComplainButton>
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					marginTop: 3,
					display: "flex",
					width: "100%",
					gap: 3,
					justifyContent: "center",
				}}
			>
				{
					<FeedbackButton
						variant="contained"
						onClick={(e) => {
							e.preventDefault();
						}}
					>
						回答する
					</FeedbackButton>
				}
			</Box>
			<Modal
				open={openModalScreenShot}
				onClose={() => {
					setOpenModalScreenShot(false);
				}}
			>
				<CleanSystemScreenShot
					onOk={() => {
						setOpenModalScreenShot(false);
					}}
					setListImage={(listImg) => {
						setListImage(listImg);
					}}
					listImage={listImage}
				/>
			</Modal>
		</Box>
	);
};

export default CleanSystemFeedbackComponent;

import React, { useContext, useEffect, useState } from "react"
import { useChangePassword, useSendRequestForgot } from "lib/api/auth"
import { AppUrl } from "components/utils/constants/AppUrl";
import { useLocation, useNavigate } from "react-router-dom";

import { styled, createTheme } from '@mui/material/styles';
import { TextField, CardContent, Button } from "@mui/material"
import { AlertContext } from "App";
import { queryStringToObject } from "lib/common/convert-data";
import { AxiosError } from "axios";
import { ErrorResponse } from "interfaces";

const theme = createTheme();

const SignSubmitButton = styled(Button)({
  marginTop: theme.spacing(2),
  flexGrow: 1,
  textTransform: "none",
});

// パスワードを忘れたページ
const ForgotPasswordEmail = () => {
  const [stepForgotPassword, setStepForgotPassword] = useState(1)
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const search = useLocation().search;
  const reset_password_token = new URLSearchParams(search).get('token');
  const { setModalAlert } = useContext(AlertContext)
  const navigate = useNavigate()

  const handleSendRequest = useSendRequestForgot((res) => {
    setModalAlert({ isOpen: true, message: res?.message, type: 'success' })
  }, (err: AxiosError<ErrorResponse>) => {
    setModalAlert({ isOpen: true, message: err?.response?.data?.errors || 'エラー', type: 'error' })
  })

  const handleChangePassword = useChangePassword((res) => {
    setModalAlert({ isOpen: true, message: res?.message, type: 'success' })
    navigate(AppUrl.SIGN_IN)
  }, (err: AxiosError<ErrorResponse>) => {
    setModalAlert({ isOpen: true, message: (err?.response?.data?.errors as any)?.fullMessages || 'エラー', type: 'error' })

  })

  useEffect(() => {
    if (reset_password_token) {
      setStepForgotPassword(2)
    }
  }, [reset_password_token])
  const handleSendRequestForgot = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    handleSendRequest.mutate(email)
  }
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    handleChangePassword.mutate({ password, password_confirmation: passwordConfirmation, config: queryStringToObject(search) })
  }

  return (
    <form noValidate autoComplete="off">
      {
        stepForgotPassword === 1 ? (
          <CardContent>
            <TextField
              sx={{ backgroundColor: "#ffffff",
                '& .MuiFormLabel-root': {
                  color: '#000000'
                }
              }}                
              variant="outlined"
              required
              fullWidth
              label="メールアドレス"
              value={email}
              margin="dense"
              onChange={event => setEmail(event.target.value)}
            />
            <SignSubmitButton
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={!email || handleSendRequest.isPending} // 空欄があった場合はボタンを押せないように
              onClick={handleSendRequestForgot}
            >
              再設定
            </SignSubmitButton>
          </CardContent>
        ) : (
          <CardContent>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="パスワード"
              type="password"
              value={password}
              margin="dense"
              autoComplete="current-password"
              onChange={event => setPassword(event.target.value)}
            />
            <TextField
              variant="outlined"
              required
              fullWidth
              label="パスワード確認"
              type="password"
              value={passwordConfirmation}
              margin="dense"
              autoComplete="off"
              onChange={event => setPasswordConfirmation(event.target.value)}
            />
            <SignSubmitButton
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={!password || !passwordConfirmation || handleChangePassword.isPending} // 空欄があった場合はボタンを押せないように
              onClick={handleSubmit}
            >
              再設定
            </SignSubmitButton>
          </CardContent>
        )
      }
    </form>
  )
}

export default ForgotPasswordEmail

import React from 'react';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";

const SafeAreaBox = styled(Box)(({ theme }) => ({
    padding: '1rem',
    [theme.breakpoints.up('sm')]: {
        padding: '0 2rem 2rem',

    },
    [theme.breakpoints.up('lg')]: {
        padding: '0 4rem 4rem',

    },
    [theme.breakpoints.up('xl')]: {
        padding: '0 6rem 6rem',
    },
}));

interface SafeAreaProps {
    children: React.ReactElement;
}

const SafeArea = ({ children }: SafeAreaProps) => {
    return (
        <SafeAreaBox>
            {children}
        </SafeAreaBox>
    );
}

export default SafeArea;

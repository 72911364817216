import React, {
	useState,
	useEffect,
	createContext,
	Dispatch,
	SetStateAction,
} from "react";

import {
	Route,
	Routes,
	RouteProps,
	useLocation,
	useNavigate,
	Navigate,
  useMatch
} from "react-router-dom";

import CommonLayout from "components/layouts/CommonLayout";
import CardLayout from "components/layouts/CardLayout";
import BlankLayout from "components/layouts/BlankLayout";
import Home from "components/pages/Home/Home";
import SignUp from "components/pages/SignUp";
import SignIn from "components/pages/SignIn";
import ForgotPassword from "components/pages/ForgotPassword";
import MyBottle from "components/pages/MyBottle/MyBottle";
import RegisterMyBottle from "components/pages/MyBottle/Register";
import RegisterMyBottleManually from "components/pages/MyBottle/RegisterManually";
import Coupon from "components/pages/Coupon/Coupon";
import CouponDetail from "components/pages/Coupon/Detail";
import Profile from "components/pages/Profile";
import CleanSystem from "components/pages/CleanSystem/CleanSystem";
import UserDelete from "components/pages/UserDelete";
import UserManagement from "components/pages/Admin/UserManagement/UserManagement";
import UserManagementSignUp from "components/pages/Admin/UserManagement/SignUp";
import Notification from "components/pages/Notification";
import CircularProgressBox from "components/utils/CircularProgressBox";
import { Box } from "@mui/material";

import { getCurrentUser } from "lib/api/auth";
import { IResponseCurrentUser } from "interfaces/index";
import { AppUrl } from "components/utils/constants/AppUrl";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import AlertMessage from "components/utils/AlertMessage";
import Dashboard from "components/pages/Admin/Dashboard";
import DataManagement from "components/pages/Admin/DataManagement";
import SurveyManagement from "components/pages/Admin/SurveyManagement";
import CreateSurvey from "components/pages/Admin/SurveyManagement/CreateSurvey/index";
import SurveyDetail from "components/pages/Admin/SurveyManagement/Detail";
import Event from "components/pages/Event";
import EventDetail from "components/pages/Event/Detail";
import WashingMachineStatus from "components/pages/WashingMachineStatus";
import { QueryKey } from "components/utils/constants/QueryKey";
import SustainabilityManagement from "components/pages/Admin/SustainabilityManagement";
import CreateSustainability from "components/pages/Admin/SustainabilityManagement/CreateSustainability";
import DetailSustainability from "components/pages/Admin/SustainabilityManagement/Detail";
import CouponManagement from "components/pages/Admin/CouponManagement";
import CreateAndUpdateCoupon from "components/pages/Admin/CouponManagement/CreateAndUpdate";
import CreateEvent from "components/pages/Admin/EventManagement/Create";
import EventManagement from "components/pages/Admin/EventManagement";
import AdminEventDetail from "components/pages/Admin/EventManagement/Detail";
import NotificationManagement from "components/pages/Admin/NotificationManagement";
import CreateAndUpdateNotification from "components/pages/Admin/NotificationManagement/CreateAndUpdate";
import SettingShow from "components/pages/Admin/SettingShow";
import NetworkStatus from "components/utils/NetworkStatus";

// グローバルで扱う変数・関数
export const AuthContext = createContext(
	{} as {
		isError: boolean;
		isLoading: boolean;
		isFetching: boolean;
		isSuccess: boolean;
		refetch: Function;
		user: IResponseCurrentUser | undefined;
	}
);

export const AlertContext = createContext(
	{} as {
		modalAlert: {
			isOpen: boolean;
			message: string | string[];
			type: "error" | "success" | "info" | "warning";
		};
		setModalAlert: Dispatch<
			SetStateAction<{
				isOpen: boolean;
				message: string | string[];
				type: "error" | "success" | "info" | "warning";
			}>
		>;
	}
);

const App: React.FC<RouteProps> = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const {
		data: user,
		isLoading,
		refetch,
		isSuccess,
		isError,
		isFetching,
	} = useQuery({
		queryKey: [QueryKey.GET_AUTH_USER],
		queryFn: async () => await getCurrentUser(),
		staleTime: Infinity,
	});
	const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({
		isOpen: false,
		message: "",
		type: "warning",
	});
  const queryClient = useQueryClient()
  const match = useMatch(AppUrl.SUSTAINABILITY_DETAIL)

	// ユーザーが認証済みかどうかでルーティングを決定
	// 未認証だった場合は「/signin」ページに促す
	useEffect(() => {
		const publicUrl = [AppUrl.SIGN_IN, AppUrl.SIGN_UP, AppUrl.FORGOT_PASSWORD];
		if (
			!isFetching &&
			!user?.isLogin &&
			!publicUrl.includes("/" + location.pathname.split("/")[1])
		) {
			navigate(AppUrl.SIGN_IN);
		} else if (
			user?.isLogin &&
			publicUrl.includes("/" + location.pathname.split("/")[1])
		) {
			navigate(AppUrl.HOME);
		}
    if(!match && queryClient.getQueryData([QueryKey.CACHE_CHANGED_DATA_SUSTAINABILITY])){
      queryClient.removeQueries({ queryKey: [QueryKey.CACHE_CHANGED_DATA_SUSTAINABILITY] })
    }
	}, [user, isFetching, location.pathname, navigate, queryClient, match]);

  return (
    <AlertContext.Provider value={{ modalAlert, setModalAlert }}>
      <AuthContext.Provider value={{ isLoading, refetch, user, isSuccess, isError, isFetching }}>
        {
          isFetching ? (
            <Box sx={{ height: '100vh' }}>
              <CircularProgressBox />
            </Box>
          ) : isSuccess ? (
            <Routes>
              {/* User Page */}
              {
                user?.data?.roles?.length && (
                <Route
                  path={AppUrl.HOME}
                  element={
                    user?.data?.roles?.includes("admin") ? (
                      <CommonLayout
                        noMarginTop
                        noPaddingTop
                        maxWidthSafezone="xl"
                        backgroundColor="#FBFBFB"
                      >
                        <Dashboard />
                      </CommonLayout>
                    ) : (
                      <CommonLayout noMarginTop>
                        <Home />
                      </CommonLayout>
                    )
                  }
                />
                )
              }
              {
                !user?.isLogin && (
                  <>
                    {
                      user?.isSignup === 'available' && (
                        <Route path={AppUrl.SIGN_UP} element={
                          <CardLayout title='ユーザー登録' center noHeader>
                            <SignUp />
                          </CardLayout>} />
                      )
                    }
                    <Route path={AppUrl.SIGN_IN} element={<BlankLayout><SignIn /></BlankLayout>} />
                    {
                      user?.forgotPasswordType !== 'not_available' && (
                        <Route path={AppUrl.FORGOT_PASSWORD} element={<CardLayout title='パスワード再設定' center noHeader>
                          <ForgotPassword />
                        </CardLayout>} />
                      )
                    }
                  </>
                )
              }
              {
                (user?.data?.roles?.includes('normal') || user?.data?.roles?.includes('admin')) && (
                  <>
                    <Route path={AppUrl.MY_BOTTLE} element={
                      <CardLayout title='あなたのマイボトル' center>
                        <MyBottle />
                      </CardLayout>
                    } />
                    {
                      user?.cleanSystemStatusShow === 'available' && (
                        <Route path={AppUrl.WASHING_MACHINE} element={
                          <CardLayout title='マイボトル洗浄機' center>
                            <WashingMachineStatus />
                          </CardLayout>
                        } />
                      )
                    }
                    <Route path={AppUrl.REGISTER_MY_BOTTLE} element={
                      <CardLayout title='コードを読み込んでください' center>
                        <RegisterMyBottle />
                      </CardLayout>
                    } />
                    <Route path={AppUrl.REGISTER_MANUALLY_MY_BOTTLE} element={
                      <CardLayout title='コードを入力してください' center>
                        <RegisterMyBottleManually />
                      </CardLayout>
                    } />
                    {
                      user?.couponShow === 'available' && (
                        <>
                          <Route path={AppUrl.COUPON} element={
                            <CardLayout title='クーポン一覧' center>
                              <Coupon />
                            </CardLayout>
                          } />
                          <Route path={AppUrl.COUPON_DETAIL} element={
                            <CardLayout title='クーポン' center>
                              <CouponDetail />
                            </CardLayout>
                          } />
                        </>
                      )
                    }
                    <Route path={AppUrl.PROFILE} element={
                      <CardLayout title='プロフィール'>
                        <Profile />
                      </CardLayout>
                    } />
                    <Route path={AppUrl.NOTIFICATION} element={
                      <CardLayout title='おしらせ'>
                        <Notification />
                      </CardLayout>
                    } />
                    <Route path={AppUrl.USER_DELETE} element={
                      <CardLayout title='退会' center>
                        <UserDelete />
                      </CardLayout>
                    } />
                    {
                      user?.eventShow === 'available' && (
                        <>
                          <Route path={AppUrl.EVENT} element={
                            <CardLayout title='イベント一覧'>
                              <Event />
                            </CardLayout>
                          } />
                          <Route path={AppUrl.EVENT_DETAIL} element={
                            <CardLayout title='開催中のイベント' center>
                              <EventDetail />
                            </CardLayout>
                          } />
                        </>
                      )
                    }
                  </>
                )
              }
              {/* Admin Page */}
              {
                user?.data?.roles?.includes('admin') && (
                  <>
                    <Route path={AppUrl.USER_MANAGEMENT} element={
                      <CommonLayout>
                        <UserManagement />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.USER_MANAGEMENT_SIGN_UP} element={
                      <CommonLayout>
                        <UserManagementSignUp />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.DATA_MANAGEMENT} element={
                      <CommonLayout>
                        <DataManagement />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.SURVEY_MANAGEMENT} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <SurveyManagement />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.CREATE_SURVEY} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <CreateSurvey />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.SURVEY_DETAIL} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <SurveyDetail />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.SUSTAINABILITY_MANAGEMENT} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <SustainabilityManagement />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.CREATE_SUSTAINABILITY} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <CreateSustainability />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.SUSTAINABILITY_DETAIL} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <DetailSustainability />
                        </CommonLayout>
                    } />
                    <Route path={AppUrl.COUPON_MANAGEMENT} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <CouponManagement />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.CREATE_AND_UPDATE_COUPON} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <CreateAndUpdateCoupon />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.CREATE_AND_UPDATE_COUPON_DETAIL} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <CreateAndUpdateCoupon />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.EVENT_MANAGEMENT} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <EventManagement />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.CREATE_EVENT} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <CreateEvent />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.ADMIN_EVENT_DETAIL} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <AdminEventDetail />
                        </CommonLayout>
                    } />
                    <Route path={AppUrl.NOTIFICATION_MANAGEMENT} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <NotificationManagement />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.CREATE_NOTIFICATION} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <CreateAndUpdateNotification />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.NOTIFICATION_DETAIL} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <CreateAndUpdateNotification />
                      </CommonLayout>
                    } />
                    <Route path={AppUrl.SETTING_SHOW} element={
                      <CommonLayout maxWidthSafezone='xl'>
                        <SettingShow />
                      </CommonLayout>
                    } />
                  </>
                )
              }
              {
                user?.data?.roles?.includes('wash_bottle') && (
                  <Route path={'/clean-system'} element={<CleanSystem />} />
                )
              }
              {
                user?.isLogin && (
                  <Route path="*" element={
                    <Navigate to={AppUrl.HOME} replace={true} />
                  } />
                )
              }
              {
                !user?.isLogin && (
                  <Route path="*" element={
                    <Navigate to={AppUrl.SIGN_IN} replace={true} />
                  } />
                )
              }
            </Routes>
          ) : isError && (
              <>
                <h1>Error</h1>
              </>
          )
        }
        <AlertMessage // エラーが発生した場合はアラートを表示
          open={modalAlert.isOpen}
          setOpen={(open: boolean) => setModalAlert({ isOpen: open, message: '', type: 'warning' })}
          severity={modalAlert.type}
          message={modalAlert.message}
        />
        <NetworkStatus/>
				</AuthContext.Provider>
		</AlertContext.Provider>
	)
}

export default App;

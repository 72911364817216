import { AxiosError } from 'axios';
import { BaseResponse, ErrorResponse, IResponseStatus, User } from 'interfaces';
import { useCheckStatus } from 'lib/api/clean-system';
import { useCallback, useEffect, useRef } from 'react'

interface LogicComponentProps {
  step: 0 | 1 | 2 | 3 | 4
  handleOpenModalCheckTag: (e: boolean) => void
  handleChangeStep: (e: 0 | 1 | 2 | 3 | 4) => void
  handleResetStatus: Function
  currentUser: User | undefined
	handleShowErrMessage: (e: string) => void
	handleChangecurrentUser: (user: User) => void
	handleClickButton: Function
	handleErrorTag: Function
	openModalCheckTag: boolean
}

const useEffectLogic = ({step, handleOpenModalCheckTag, handleResetStatus, handleChangeStep, currentUser, handleShowErrMessage, handleChangecurrentUser, openModalCheckTag, handleClickButton, handleErrorTag} : LogicComponentProps) => {
	const totalTime = 45000;
	const timeThanks = 2000;
	const timeUserThanks = 5000;
	const timeRecallCheckStatus = 3000;
	const timeRecallCheckStatusNfc = 1000;

	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const intervalStep1Ref = useRef<NodeJS.Timeout | null>(null);
	const intervalStep2Ref = useRef<NodeJS.Timeout | null>(null);
	const intervalStep4Ref = useRef<NodeJS.Timeout | null>(null);
	const intervalStep1NfcRef = useRef<NodeJS.Timeout | null>(null);
	const intervalNfcRef = useRef<NodeJS.Timeout | null>(null);

  const handleCheckStatus = useCheckStatus(
    useCallback(
      (res: BaseResponse<IResponseStatus>) => {
				if (res?.data?.status === 'not_set' &&
					res?.data?.value?.type === 'NFCN' &&
					res?.data?.value?.errorCode === 'ERR_1125400112'
				) {
					handleErrorTag()
				} else
					if (
						res?.data?.status === 'initial_state' &&
						res?.data?.value?.type === 'NFCN' &&
						res?.data?.value?.user?.id &&
						!currentUser &&
						step === 0
					) {
						handleChangecurrentUser(res?.data?.value?.user);
					} else
						if (
							res?.data?.status === 'initial_state' &&
							res?.data?.value?.user?.id &&
							!currentUser &&
							step === 1
						) {
							handleChangecurrentUser(res?.data?.value?.user);
							handleChangeStep(2);
							handleOpenModalCheckTag(false);
						}
      },
      [currentUser, step, handleChangecurrentUser, handleChangeStep, handleOpenModalCheckTag]
    ),
    useCallback(
      (err: AxiosError<ErrorResponse>) => {
        if (step === 1) handleShowErrMessage(err?.response?.data?.message || '');
      },
      [step, handleShowErrMessage]
    )
  );

	useEffect(() => {
		if (step === 0 && handleCheckStatus.data?.data?.value?.type === "NFCN" && handleCheckStatus?.data?.data?.status === 'initial_state' ){
			handleClickButton()
		}
		if (step === 1 && !handleCheckStatus.isSuccess) {
			intervalStep1Ref.current = setTimeout( async () => {
				const promise = await handleCheckStatus.mutateAsync("");
				if ((promise.data.id && step === 1) && (promise?.data?.status === "complete" || promise?.data?.status === "not_set")){
					handleOpenModalCheckTag(true);
				}
			}, totalTime);
		}
		if (
			step === 1 &&
			handleCheckStatus.isSuccess &&
			handleCheckStatus.data.data.status !== "initial_state" &&
			openModalCheckTag
		) {
			intervalRef.current = setInterval(() => {
				handleCheckStatus.mutate("");
			}, timeRecallCheckStatus);
		}
		if (step === 2) {
			intervalStep2Ref.current = setTimeout(() => {
				handleChangeStep(3);
				}, timeUserThanks);
				}
		if (step === 4) {
			intervalStep4Ref.current = setTimeout(() => {
				handleResetStatus();
			}, timeThanks);
		}
		if(step === 0) {
			if (!(handleCheckStatus?.data?.data?.status === 'not_set' && handleCheckStatus?.data?.data?.value?.errorCode === 'ERR_1125400112')){
				intervalNfcRef.current = setInterval(() => {
					handleCheckStatus.mutate("");
				}, timeRecallCheckStatusNfc);
			}
		}
    if (step === 1 && handleCheckStatus.isSuccess && handleCheckStatus.data?.data?.value?.type === 'NFCN') {
			intervalStep1NfcRef.current = setTimeout( async () => {
				handleChangeStep(2)
			}, totalTime);
		}

		return () => {
			clearInterval(intervalRef.current as NodeJS.Timeout);
			clearInterval(intervalStep1Ref.current as NodeJS.Timeout);
			clearInterval(intervalStep2Ref.current as NodeJS.Timeout);
			clearInterval(intervalStep4Ref.current as NodeJS.Timeout);
			clearInterval(intervalNfcRef.current as NodeJS.Timeout);
			clearInterval(intervalStep1NfcRef.current as NodeJS.Timeout);
		};
	}, [handleChangeStep, handleCheckStatus, handleClickButton, handleOpenModalCheckTag, handleResetStatus, openModalCheckTag, step]);
  return { statusData: handleCheckStatus.data?.data, reset: handleCheckStatus.reset };
}


export default useEffectLogic

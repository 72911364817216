import { Box, Button, TextField, Typography } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { styled } from "@mui/material/styles";
import { IACreateAndUpdateCoupon } from 'interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdminCreateCoupon, useAdminGetDetailCoupon, useAdminUpdateCoupon } from 'lib/api/admin-coupon';
import { AppUrl } from 'components/utils/constants/AppUrl';
import CircularProgressBox from 'components/utils/CircularProgressBox';
import AlertMessage from 'components/utils/AlertMessage';
import { AlertContext } from 'App';


interface renderInputProps {
  name: string
  placeholder: string
  value: string | number
  width?: number | string
  type?: React.HTMLInputTypeAttribute
}

const TitleTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
  whiteSpace: 'pre',
}));
const ContentTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
  fontSize: 24,
  whiteSpace: 'pre',
}));

const BoxItem = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: 10,
	marginBottom: 1,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
	width: 180,
	height: 60,
	fontSize: 20,
	borderRadius: 10,
	margin: "10px auto",
}));

const CreateAndUpdateCoupon = () => {
	const { id } = useParams();
	const { data, isLoading, isSuccess } = useAdminGetDetailCoupon(Number(id));
  const isEditCoupon = !!id
  const { setModalAlert:setModalAlertGlobal } = useContext(AlertContext)

  const [dataCreate, setDataCreateAndUpdate] = useState<IACreateAndUpdateCoupon>({
    code: "",
    deadline_day: 0,
    description: "",
    name: "",
  })
  const [isConfirmed, setIsConfirmed] = useState(false)
  const navigate = useNavigate()
  const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({ isOpen: false, message: "", type: "success" });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDataCreateAndUpdate(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleCreateCoupon = useAdminCreateCoupon(
		(res) => {
			setModalAlertGlobal({ isOpen: true, message: res?.message, type: "success" });
			navigate(AppUrl.COUPON_MANAGEMENT);
		},
		(err) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.error?.messages || "エラー",
				type: "error",
			});
		}
	);
  const handleUpdateCoupon = useAdminUpdateCoupon(
		(res) => {
			setModalAlertGlobal({ isOpen: true, message: res?.message, type: "success" });
			navigate(AppUrl.COUPON_MANAGEMENT);
		},
		(err) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.error?.messages || "エラー",
				type: "error",
			});
		}
	);

  const renderInput = ({name, placeholder, value, width, type }: renderInputProps) => {
    if(!isConfirmed){
      return (
        <BoxItem>
          <TitleTypography>{placeholder}</TitleTypography>
          <TextField
            name={name}
            required
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            disabled={isEditCoupon && name === "code"}
            sx={{ width }}
            type={type ?? "string"}
            InputProps={{
              inputProps: { 
                  min: 0
              }
            }}

          />
        </BoxItem>
      )
    } else {
      return (
        <BoxItem> 
          <TitleTypography>{placeholder}</TitleTypography>
          <ContentTypography>{value}</ContentTypography>
        </BoxItem>
      )
    }
  }

  const handleSubmit = () => {
    if(isEditCoupon){
      handleUpdateCoupon.mutate({id: Number(id), body:dataCreate})
    } else if (isConfirmed) {
      handleCreateCoupon.mutate(dataCreate)
    } else {
      setIsConfirmed(true)
    }
  }

  useEffect(() => {
    if(isSuccess){
      setDataCreateAndUpdate({
        code: data?.data?.code || "",
        name: data?.data?.name || "",
        description: data?.data?.description || "",
        deadline_day: data?.data?.deadlineDay || 0,
      })
    }
  }, [data?.data, isSuccess])

  return (
		<Box
			display="flex"
			flexDirection="column"
			gap={3}
			component="form"
			onSubmit={(e) => {
				e.preventDefault();
        handleSubmit()
			}}
		>
			<Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
        クーポン{isEditCoupon ? '編集' : '作成'}
			</Typography>
      {
        isLoading && isEditCoupon ? (
          <CircularProgressBox />
        ) : (
          <>
            <Box display="flex" gap={3} flexDirection={isConfirmed ? "column" : "row"}>
              {renderInput({
                name: "code",
                placeholder: "認識Code",
                value: dataCreate.code
              })}
              {renderInput({
                name: "name",
                placeholder: "クーポン名",
                value: dataCreate.name,
                width: 400
              })}
            </Box>
            {renderInput({
              name: "description",
              placeholder: "クーポンの説明",
              value: dataCreate.description,
              width: "100%"
            })}
            {renderInput({
              name: "deadline_day",
              placeholder: "クーポンの有効期限（日）​",
              value: dataCreate.deadline_day,
              width: 100,
              type: "number"
            })}
            <Box display="flex" sx={{ width: "40%", margin: "0 auto" }}>
              {
                isConfirmed && (
                  <SubmitButton variant="outlined" onClick={() => {
                    setIsConfirmed(false)
                  }}>
                    戻る
                  </SubmitButton>
                )
              }
              <SubmitButton variant="contained" type="submit" disabled={handleCreateCoupon.isPending || handleUpdateCoupon.isPending}>
                {isConfirmed ? '送信' : '確認'}
              </SubmitButton>
            </Box>
          </>
        )
      }
			<AlertMessage
				open={modalAlert.isOpen}
				setOpen={(open: boolean) =>
					setModalAlert({ isOpen: open, message: "", type: "warning" })
				}
				severity={modalAlert.type}
				message={modalAlert.message}
			/>
		</Box>
  )
}

export default CreateAndUpdateCoupon

import { axiosInstanceAuth } from "./client"
import { useMutation, useQuery } from "@tanstack/react-query"
import { BaseResponse,IListNotification } from "interfaces"

export const useGetListNotification = (params: {
    page: number
    per: number
}) => {
  return useQuery({
    queryKey: ['GET_LIST_NOTIFICATION', params.page, params.per],
    queryFn: async () => {
      const res = await axiosInstanceAuth.get(`/messages/filtering?page=${params.page}&per=${params.per}`);
      return res as BaseResponse<IListNotification>;
    },
    enabled: !!params.page && !!params.per
  });
};

import { useQuery } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import { BaseResponse, IMachine, IMachineStatus } from "interfaces";

export const useGetMachineStatus = (statusCode: string) => {
	return useQuery({
		queryKey: ["GET MACHINE STATUS", statusCode],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				`/status/clean_system?status_code=${statusCode}`
			);
			return res as BaseResponse<IMachineStatus>;
		},
		enabled: !!statusCode,
	});
};

export const useGetListMachine = () => {
	return useQuery({
		queryKey: ["GET LIST MACHINE"],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get("/statuses");
			return res as BaseResponse<IMachine[]>;
		},
	});
};

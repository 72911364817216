import { AxiosError } from "axios";
import { axiosInstanceAuth } from "./client"
import { useQuery, useMutation } from '@tanstack/react-query';
import { BaseResponse, ErrorResponse, IACoupon, ICouponDetail, IListCoupon } from "interfaces";


export const useGetListCoupon = (params: {
    page: number
    per: number
}) => {
    return useQuery({
        queryKey: ['GET LIST COUPON', params.page, params.per],
        queryFn: async () =>{
            const res = await axiosInstanceAuth.get(`/users_coupons/filtering?page=${params.page}&per=${params.per}`)
            return res as BaseResponse<IListCoupon>
        },
        enabled: !!params.page && !!params.per
    })
}

export const useGetDetailCoupon = (id: number) => {
    return useQuery({
        queryKey: ['GET DETAIL COUPON', id],
        queryFn: async () =>{
            const res = await axiosInstanceAuth.get(`/users_coupons/${id}`)
            return res as BaseResponse<ICouponDetail>
        },
        enabled: !!id,
    })
}

export const useUseCouponMutation = (onOk?: Function , onErr?:Function) => {
    return useMutation({
        mutationKey: ['USE COUPON'],
        mutationFn: (id: number) => axiosInstanceAuth.put(`/users_coupons/${id}/use`, {}),
        onSuccess: (res) => {
            onOk && onOk(res)
        },
        onError: (err: AxiosError<ErrorResponse>) => {
            onErr && onErr(err)
        }
    })
}

export const useAdminGetListCoupon = () => {
    return useQuery({
        queryKey: ['ADMIN GET LIST COUPON'],
        queryFn: async () =>{
            const res = await axiosInstanceAuth.get("/admin/coupons")
            return res as BaseResponse<IACoupon[]>
        },
    })
}
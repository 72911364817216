import { useNavigate } from "react-router-dom"
import { AppUrl } from 'components/utils/constants/AppUrl';

import { Box, Typography, Button, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useGetUserTag, useGetWashCount } from '../../../lib/api/my-bottle';
import CircularProgressBox from 'components/utils/CircularProgressBox'
import HintIcon from "components/icon/HintIcon";
import { formatNumber } from 'lib/common/string.helper'

const TextTitle = styled(Typography)({
    marginTop: '2rem',
    fontWeight: 400,
    fontSize: '1.2rem'
});
const TextTitle2 = styled(Typography)({
    marginTop: '1rem',
    fontWeight: 400,
    fontSize: '1.2rem'
});
const TextDescription = styled(Typography)({
    marginTop: '0',
    fontWeight: 700,
    fontSize: '2.5rem',
});

const MyBottle = () => {
    const navigate = useNavigate()
    const { data, isLoading, isError } = useGetUserTag()
    const { data: countWashed, isLoading: isLoadingWashCount, isError: isErrorWashCount } = useGetWashCount()

    return (
        <>
            {
                (isLoading || isLoadingWashCount) ? (
                    <CircularProgressBox />
                ) : (!isError && !isErrorWashCount) ? (
                    <>
                        <TextTitle>利用回数</TextTitle>
                        <TextDescription>{formatNumber(countWashed?.data || 0)}回</TextDescription>
                        <TextTitle2>タグ情報</TextTitle2>
                        <Typography sx={{ marginTop: "1rem" }}>{data?.data?.code}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: "2rem 0 1rem 0"}}>
                            <Button sx={{ marginLeft: data?.data?.code ? '41px' : 0}} variant="outlined" onClick={() => navigate(AppUrl.REGISTER_MY_BOTTLE)}>{data?.data?.code ? '再登録' : 'タグを登録する'}</Button>
                            {
                                data?.data?.code && (
                                    <Tooltip
                                        title="タグの破損による交換や、コードの入力ミスが発生したときは、再登録を行ってください"
                                        enterTouchDelay={0}
                                        leaveTouchDelay={3000}
                                        arrow={true}
                                        PopperProps={{
                                            sx: {
                                                '& .MuiTooltip-tooltip': {
                                                  marginTop: "2px!important",
                                                  backgroundColor: '#185889!important',
                                                  paddingRight: '0px!important',
                                                },
                                                '& .MuiTooltip-arrow': {
                                                    "&:before": {
                                                        backgroundColor: '#185889!important'
                                                    },
                                                }
                                            }
                                          }}
                                    >
                                        <IconButton>
                                            <HintIcon />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        </Box>
                    </>
                ) :
                    <>
                        <h1>Error</h1>
                    </>
            }
        </>
    )
}

export default MyBottle
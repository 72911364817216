import React, { useEffect, useState, useCallback, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Box, Snackbar, Alert, Button } from "@mui/material";
import {
	useUpdateStatus,
} from "../../../lib/api/clean-system";
import { User } from "interfaces";
import CleanSystemFeedback from "./CleanSystemFeedback";
import CleanSystemWelcome from "./CleanSystemWelcome";
import CleanSystemSetting from "./CleanSystemSetting";
import BackIcon from "components/icon/BackIcon";
import { keyframes } from "@mui/system";
import CleanSystemCheckTag from "./CleanSystemCheckTag";
import NextIcon from "components/icon/NextIcon";
import useEffectLogic from "../../hooks/useEffectLogic";
import ModalSignUpTag from "components/utils/ModalSignUpTag";
import { webUrl } from "components/utils/constants/AppUrl";

const BoxWrapper = styled(Box)({
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	width: `${window.innerWidth}px`,
	gap: 5,
	alignItems: "center",
	height: `${window.innerHeight}px`,
	position: "relative",
});
const BoxBackHome = styled(Box)({
	position: "absolute",
	backgroundColor: "#1976D2",
	height: "70px",
	width: "100%",
	zIndex: 10000,
	top: 0,
	left: 0,
	right: 0,
	display: "flex",
	alignItems: "center",
});

const BoxButtonCenter = styled(Box)({
	position: "relative",
	height: "50%",
	width: "60%",
});

const BoxAvatar = styled(Box)({
	position: "absolute",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	height: "100%",
	width: "100%",
	backgroundImage: "url(/clean_system/home.jpg)",
	backgroundSize: "cover",
	backgroundPosition: "center",
});

const BoxThank = styled(Box)({
	height: "100%",
	width: "100%",
	marginTop: "70px",
	backgroundImage: "url(/clean_system/thanks.png)",
	backgroundSize: "cover",
	backgroundPosition: "center",
});

const scaling = keyframes`
  0% {
      transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const CleanSystem: React.FC = () => {
	//setting time of screen show image step
	const timeWait = 5000;
	const timeZoomInZoomOut = 500;
	const timeFirstImage = 6000;
	const timeSecondImage = 7000;
	const timeThirdImage = 16000;
	const timeFouthImage = 11000;

	const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
	const [step, setStep] = useState<0 | 1 | 2 | 3 | 4>(0);
	const [openModalCheckTag, setOpenModalCheckTag] = useState(false);
	const [showMessage, setShowMessage] = useState<{
		open: boolean;
		message: string;
		type: "success" | "info" | "warning" | "error";
	}>({
		open: false,
		message: "",
		type: "error",
	});
  const buttonStartRef = useRef<HTMLButtonElement>(null);
	const [openModalSignUpTag, setOpenModalSignUpTag] = useState<boolean>(false)

	const handleUpdateStatus = useUpdateStatus(() => {
		setStep(3);
	});
	
	const handleUpdateStatusSuccess = useUpdateStatus();

	const handleCheckTag = useCallback(
		async (user: User) => {
			if (user && !handleUpdateStatus.isPending) setCurrentUser(user);
		},
		[handleUpdateStatus]
	);

	//preload image to cache
	const cacheImages = async (arrayImages: string[]) => {
		const promises = await arrayImages.map((src) => {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.src = src;
				img.onload = (load) => {
					resolve(load);
				};
				img.onerror = (err) => {
					reject(err);
				};
			});
		});
		await Promise.all(promises);
	};

	useEffect(() => {
		const listImages = [
			webUrl + "/clean_system/D23-00069-7A-14Ayoko-01.png",
			webUrl + "/clean_system/D23-00069-7A-14Ayoko-02.png",
			webUrl + "/clean_system/D23-00069-7A-14Ayoko-03.png",
			webUrl + "/clean_system/D23-00069-7A-14Ayoko-04.png",
			webUrl + "/clean_system/thanks.png",
		];
		cacheImages(listImages);
	}, []);

	const handleShowErrMessage = useCallback((msg: string) => {
		setShowMessage({
			open: true,
			message: msg || "エラー",
			type: "error",
		});
	}, [])
	const handleChangecurrentUser = useCallback((user: User) => {
		setCurrentUser(user)
	}, [])
	const handleChangeStep = useCallback((step: 0 | 1 | 2 | 3 | 4) => {
		setStep(step)
	}, [])
	const handleOpenModalCheckTag = useCallback((open: boolean) => {
		setOpenModalCheckTag(open)
	}, [])
	const handleClickButton = useCallback(() => {
		buttonStartRef.current?.click()
	}, [])

	const handleResetStatus = useCallback(() => {
		setOpenModalCheckTag(false);
		(customHooksEffecLogic?.statusData?.value?.type === "NFCN" || step !== 1) && 
			handleUpdateStatusSuccess.mutate({
				status: "complete",
				value: {
					user_id: Number(currentUser?.id) || null,
					type: null,
					error_code: null
				},
			});
		setCurrentUser(undefined);
		setShowMessage({
			open: false,
			message: "",
			type: "error",
		});
		setStep(0);
		customHooksEffecLogic.reset()
		setOpenModalSignUpTag(false)
	}, [currentUser?.id, step, handleUpdateStatusSuccess]);

	const handleErrorTag = useCallback(() => {
		setOpenModalSignUpTag(true)
	}, [])


	const customHooksEffecLogic = useEffectLogic({ 
		handleChangecurrentUser:handleChangecurrentUser,
		handleResetStatus:handleResetStatus,
		handleOpenModalCheckTag:handleOpenModalCheckTag,
		handleChangeStep:handleChangeStep,
		step:step,
		currentUser:currentUser,
		handleShowErrMessage: handleShowErrMessage,
		openModalCheckTag: openModalCheckTag && !(step === 1 && openModalSignUpTag),
		handleClickButton: handleClickButton,
		handleErrorTag: handleErrorTag
	})

	const handleResetStatusWhenErrorTag = useCallback(() => {
		setOpenModalCheckTag(false);
		handleUpdateStatusSuccess.mutate({
			status: "complete",
			value: {
				user_id: null,
				type: null,
				error_code: null
			},
		});
		setCurrentUser(undefined);
		setStep(0);
		customHooksEffecLogic.reset()
		setOpenModalSignUpTag(false)
	}, [handleUpdateStatusSuccess, customHooksEffecLogic]);

 	const handleCheckSuccess = useCallback(
		(user: User) => {
			handleCheckTag(user);
			user?.id &&
				handleUpdateStatus.mutateAsync({
					status: "initial_state",
					value: {
						user_id: user?.id,
						type: null,
						error_code: null
					},
				});
			setOpenModalCheckTag(false);
		},
		[handleCheckTag, handleUpdateStatus]
	);
  const handleCloseCheckTag = useCallback(() => {
    setOpenModalCheckTag(false)
    if(step === 1){
      setStep(0)
			customHooksEffecLogic.reset()
    }
  }, [customHooksEffecLogic, step])

	return (
		<BoxWrapper>
			{step !== 0 && (
				<BoxBackHome>
					<Box display="flex" justifyContent="space-between" width="100%">
						<BackIcon
							style={{ marginLeft: 20 }}
							onClick={() => {
								handleResetStatus()
							}}
						/>
						{
							customHooksEffecLogic?.statusData?.value?.type === 'NFCN' && step === 1 && !customHooksEffecLogic?.statusData?.value?.errorCode && (
								<NextIcon
									style={{ marginRight: 20 }}
									onClick={() => {
										setStep(3)
									}}
								/>
							)
						}
					</Box>
				</BoxBackHome>
			)}
			{step === 0 && (
				<Box
          sx={{ position: "relative", width: "100%", display: 'flex', justifyContent: "center", alignItems: "center", height: "100%" }}
				>
					<BoxAvatar />
          <BoxButtonCenter sx={{ animation: `${scaling} 2s ease-in-out infinite alternate` }}>
						<Button
							id="use-clean-system-button"
							sx={{
								width: "100%",
								height: "100%",
								backgroundImage: "url(/clean_system/wash_button.png)",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								borderRadius: 15,
								padding: 0,
							}}
							onClick={(e) => {
								e.preventDefault();
								setStep(1);
								if(customHooksEffecLogic?.statusData?.value?.type !== 'NFCN'){
									customHooksEffecLogic.reset()
								}
							}}
							ref={buttonStartRef}
						/>
					</BoxButtonCenter>
				</Box>
			)}
			{step === 1 && (
				<CleanSystemSetting
					timeWait={timeWait}
					timeZoomInZoomOut={timeZoomInZoomOut}
					timeFirstImage={timeFirstImage}
					timeSecondImage={timeSecondImage}
					timeThirdImage={timeThirdImage}
					timeFouthImage={timeFouthImage}
				/>
			)}
			{step === 2 && <CleanSystemWelcome currentUser={currentUser} />}
			{step === 3 && (
				<CleanSystemFeedback
					setStep={setStep}
					currentUser={currentUser}
					onOk={(res: string) => {
						setShowMessage({
							message: res,
							open: true,
							type: "success",
						});
					}}
					setOpenModalCheckTag={setOpenModalCheckTag}
					onErr={(res: string) => {
						setShowMessage({
							message: res,
							open: true,
							type: "error",
						});
					}}
				/>
			)}
			{step === 4 && (
				<Box
					sx={{ position: "relative", width: "100%", height: "100%" }}
					className="animation-bigger"
				>
					<BoxThank />
				</Box>
			)}
			{showMessage.open && (
				<Snackbar
					open={showMessage.open}
					autoHideDuration={showMessage.type === "error" ? 2000 : 6000}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					onClose={() =>
						setShowMessage({
							message: "",
							open: false,
							type: "error",
						})
					}
				>
					<Alert
						onClose={() =>
							setShowMessage({
								message: "",
								open: false,
								type: "error",
							})
						}
						severity={showMessage.type}
						sx={{
							"& .MuiAlert-icon": {
								margin: "auto",
								marginRight: 1,
							},
						}}
					>
						<p>{showMessage.message}</p>
					</Alert>
				</Snackbar>
			)}

			<CleanSystemCheckTag
				isOpen={openModalCheckTag && !(openModalSignUpTag)}
				step={step}
				onClose={handleCloseCheckTag}
				onOk={handleCheckSuccess}
				isLoading={Boolean(showMessage.open)}
				setOpenModalSignUpTag={setOpenModalSignUpTag}
			/>

			<ModalSignUpTag
				open={openModalSignUpTag}
				onClose={() => {
					handleResetStatusWhenErrorTag()
				}}
			/>

		</BoxWrapper>
	);
};

export default CleanSystem;

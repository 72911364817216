import { useState, SyntheticEvent, useEffect, useCallback, useContext } from 'react';
import { useGetEvents } from 'lib/api/event';
import { useNavigate } from 'react-router-dom'
import { AuthContext } from "App"
import TagManager from 'react-gtm-module'

import { Box, Snackbar, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { IEventHome } from 'interfaces'
import { Alert } from '@mui/material'
import CircularProgressBox from 'components/utils/CircularProgressBox'
import { formatNumber } from 'lib/common/string.helper'
import { gtmId } from 'components/utils/constants/AppEnv';

const tagManagerArgs = {
    dataLayer: {
        sustSuccessCo2IndStage: 1,
    },
    dataLayerName: 'sustSuccessCo2IndStage'
}

const TextTitle = styled(Typography)({
    fontWeight: 400,
    fontSize: '1.2rem'
});

const TextDescription = styled(Typography)({
    marginTop: '0',
    fontWeight: 700,
    fontSize: '2.5rem',
});

export default function UserHome() {
    const [value, setValue] = useState('1');
    const { user } = useContext(AuthContext)
    const { data, isLoading } = useGetEvents(user?.data?.id)
    const [showMessage, setShowMessage] = useState(false)
    const navigate = useNavigate()
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight)
    // 1.8274111675126903は基準となる縦横比
    const adjustmentAspect = 1.8274111675126903 / (viewportHeight / viewportWidth)
    // backgroundSizeの拡大率を決定する
    const backgroundSizeEnlargementRate = 2 - adjustmentAspect
    const backgroundSizeRate = backgroundSizeEnlargementRate >= 1.1 ? 1.1 : backgroundSizeEnlargementRate <= 1.0 ? 1.0 : backgroundSizeEnlargementRate
    const paddingBottomPlus = Math.abs(backgroundSizeEnlargementRate - 1)
    const paddingBottomTmp = ((backgroundSizeEnlargementRate >= 1.0 ? 1.0 : backgroundSizeEnlargementRate) - 0.2) + paddingBottomPlus
    const paddingBottomRate = ((paddingBottomTmp <= 0.8 ? 0.8 : paddingBottomTmp)) >= 1.0 ? 1.0 : ((paddingBottomTmp <= 0.8 ? 0.8 : paddingBottomTmp))

    useEffect(() => {
        if (data?.data && data?.data[0]?.coupon) {
            setShowMessage(true)
        }

        if (gtmId) {
            const currentData = data?.data?.find(e => e.code.includes('first'))
            if (currentData?.id) {
                tagManagerArgs.dataLayer.sustSuccessCo2IndStage = currentData?.stage;
                TagManager.dataLayer(tagManagerArgs)
            }
        }

    }, [data?.data])

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        };

        // ウィンドウのリサイズ時にイベントリスナーを設定
        window.addEventListener('resize', handleResize);

        // コンポーネントがアンマウントされたときにイベントリスナーをクリーンアップ
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // 空の依存リストを渡すことで、初回のみ実行される

    const currentEvent: () => IEventHome | null = useCallback(() => {
        if (value === '1') {
            const currentData = data?.data?.find(e => e.code.includes('first'))
            if (currentData?.id) {
                return currentData
            }
        } else {
            const currentData = data?.data?.find(e => e.code.includes('all'))
            if (currentData?.id) {
                return currentData
            }
        }
        return null
    }, [value, data?.data])

    const BoxAvatar = styled(Box)({
        width: '100%',
        paddingBottom: String(paddingBottomRate * 100) + "%",
        backgroundImage: `url(${currentEvent()?.image.value})`,
        backgroundSize: String(backgroundSizeRate * 100) + "%",
        backgroundPosition: 'center',
    });

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const showContent = useCallback(() => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {
                    currentEvent()?.content?.map((content, index) => (
                        <Box key={`${content}-${index}`}>
                            <TextTitle paddingTop={index === 0 ? '2rem' : '1rem'} align='center'>{content.title}</TextTitle>
                            <TextDescription align='center'>{formatNumber(content.value)}{content.unit}</TextDescription>
                        </Box>
                    ))
                }
            </Box>
        );
    }, [currentEvent]);

    return (
        <>
            {
                isLoading ? (
                    <CircularProgressBox />
                ) : !!data?.data?.length && (
                    <Box sx={{
                        maxWidth: { sm: '500px', md: '900px', lg: '1200px' },
                        typography: 'body1',
                        margin: '0 auto'
                    }}>
                        <TabContext value={value}>
                            <Box sx={{ margin: '0 auto', padding: '0' }}>
                                <TabList onChange={handleChange} aria-label="Tab list" variant='fullWidth'>
                                    <Tab label="個人" value="1" sx={{ width: '50%', textTransform: 'none' }} />
                                    <Tab label="全員" value="2" sx={{ width: '50%', textTransform: 'none' }} />
                                </TabList>
                                <Box sx={{ border: '1px solid #1976D2' }}></Box>
                            </Box>
                            <TabPanel value="1" sx={{ padding: '10px 0' }}>
                                <BoxAvatar id="sust-success-co2-ind-stage" />
                                {showContent()}
                            </TabPanel>
                            <TabPanel value="2" sx={{ padding: '10px 0' }}>
                                <BoxAvatar />
                                {showContent()}
                            </TabPanel>
                        </TabContext>
                        <Snackbar
                            open={showMessage}
                            autoHideDuration={6000}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            onClose={() => setShowMessage(false)}
                        >
                            <Alert onClose={() => setShowMessage(false)} severity='info' sx={{
                                '& .MuiAlert-icon': {
                                    margin: 'auto', marginRight: 1
                                }
                            }}>
                                <p style={{
                                    textDecoration: 'underline'
                                }} onClick={() => navigate('/coupon')}>クーポンが発行されました</p>
                            </Alert>
                        </Snackbar>
                    </Box>
                )
            }
        </>
    );
}
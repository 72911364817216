import { useState, useEffect, useCallback } from 'react'
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { Box, Typography, Button, Modal, FormControlLabel, Radio, CircularProgress, Avatar, Checkbox, FormGroup, TextField } from "@mui/material"
import { useGetFeedbacks, useCreateFeedback } from '../../../lib/api/feedback';
import { IRequestCreateFeedback, User, BaseResponse, ErrorResponse } from 'interfaces';
import CleanSystemScreenShot from './CleanSystemScreenShot';
import { AxiosError } from 'axios';
import AvatarDefault from 'components/utils/AvatarDefault';
import { useLocation } from 'react-router-dom';

const BoxWrapper = styled(Box)({
    padding: '0 2rem 1rem',
    width: '100%',
    height: '100%',
    maxWidth: '900px',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    gap: 3,
    justifyContent: 'space-between',
});

const FeedbackButton = styled(Button)({
    padding: '20px 36px',
    fontSize: 28,
    border: '1px solid black',
    borderRadius: 15,
});
const ComplainButton = styled(Button)({
    padding: '10px 20px',
    fontSize: 20,
    borderRadius: 15,
});
const BoxFeedbacks = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    marginTop: 10,
});

const ChooseButton = styled(Button)({
	padding: '8px 20px',
    alignSeft: 'center',
    flex: 1, 
    textTransform: 'none', 
    fontSize: 15, 
    fontKerning: 'none', 
});

const AvatarCamera = styled(Avatar)({
    height: 100,
    width: 100,
    '& .MuiAvatar-img': {
        objectFit: 'contain',
    }
});

interface CleanSystemFeedbackProps {
    setStep: (e: 0 | 1 | 2 | 3 | 4) => void
    currentUser?: User
    onOk: (e: string) => void
    onErr: (e: string) => void
    setOpenModalCheckTag: (e:boolean) => void
}

const CleanSystemFeedback = ({
    setStep,
    currentUser,
    onOk,
    onErr,
    setOpenModalCheckTag,
}: CleanSystemFeedbackProps) => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const feedback_type_code = queryParams.get('feedback_type_code') || ''
    
    const { data: feedbacks , isFetchedAfterMount } = useGetFeedbacks(feedback_type_code)
    const [openModalScreenShot, setOpenModalScreenShot] = useState(false)
    const [dataCreateFeedback, setDataCreateFeedback] = useState<IRequestCreateFeedback>({
        feedback_id: 0,
        user_id: 0,
        value: [],
    })

    const getDataImage = useCallback((): string[] => {
        if(dataCreateFeedback.value.find(data => data.question === '汚れが気になる')?.answer?.length){
            return dataCreateFeedback.value.find(data => data.question === '汚れが気になる')?.answer as string[]
        } else {
            return []
        }
    }, [dataCreateFeedback.value])



    useEffect(() => {
        if (feedbacks?.data) {
            if (currentUser?.id && feedbacks?.data[0]?.id) {
                // value data of list answer when first question have 'check' is true
                const currentValueTrue: IRequestCreateFeedback['value'] = feedbacks?.data[0]?.value.map(item => {
                    const answerTrue = item.answer.find(itemTrue => itemTrue.check)?.value || ''
                    return ({
                        question: item.question,
                        answer: item?.answer[0]?.type !== 'checkbox' ?  answerTrue : [answerTrue]
                    })
                })
                setDataCreateFeedback({
                    feedback_id: feedbacks?.data[0]?.id,
                    user_id: currentUser?.id,
                    value: currentValueTrue
                })
            }
        }
        if(isFetchedAfterMount && !feedbacks?.data?.length) {
            setStep(4)
        }
    }, [feedbacks, currentUser?.id, isFetchedAfterMount, setStep])

    const handleCreateFeedback = useCreateFeedback((res: BaseResponse<any>) => {
        onOk(res?.message)
        setStep(4)
    }, (err: AxiosError<ErrorResponse>) => {
        onErr(err?.response?.data?.error?.messages || 'エラー')
    })

    return (
        <BoxWrapper>
            {
                (feedbacks?.data?.length && isFetchedAfterMount) ? (
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <Box sx={{ marginTop: '80px' }}>
                            <Box sx={{ backgroundColor: "#0078bb", textAlign: "center", padding: "0.8rem 0" }}>
                                <Typography sx={{ fontSize: 22, fontWeight: 800, color: "#fff100" }}>
                                    {feedbacks?.data?.[0]?.name}
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: "center", padding: "0.5rem 0" }}>
                                <Box display="flex" alignItems="center" sx={{ justifyContent: 'center' }}>
                                    <AvatarDefault width={64} height={64} src={currentUser?.image} text={currentUser?.nickname?.charAt(0)} />
                                    <Box marginLeft={2}>
                                        <Typography variant='inherit' component="div">
                                            <Typography component="span" sx={{ fontSize: 23, fontWeight: "bold" }}>{currentUser?.nickname}</Typography>
                                            <Typography component="span" sx={{ marginLeft: "0.8rem", fontSize: 19 }}>さん、ご利用ありがとうございます</Typography>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ textAlign: "center" }}>
                                    <Typography sx={{ fontSize: 14 }}>違うニックネームが表示されているとき、
                                        <Link href="#" onClick={(e) => {
                                            e.preventDefault()
                                            setOpenModalCheckTag(true)
                                        }}>
                                            こちらをタップ
                                        </Link>
                                        してQRコードを読み込ませてください</Typography>
                                </Box>
                            </Box>

                            <BoxFeedbacks>
                                {
                                    feedbacks?.data?.[0].value?.map((data, index) => {
                                        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                                            const updateAnswerIndex = dataCreateFeedback.value?.findIndex(oldData => oldData.question === data.question)
                                            const newData = [...dataCreateFeedback.value]

                                            if(data?.answer[0]?.type === 'radio' || data?.answer[0]?.type === 'text'){
                                                newData[updateAnswerIndex] = {
                                                    question: data.question,
                                                    answer: event.target.value
                                                }
                                            } else if (data?.answer[0]?.type === 'checkbox') {
                                                newData[updateAnswerIndex] = {
                                                    question: data.question,
                                                    answer: event.target.checked ? [...(newData[updateAnswerIndex].answer) as string[],event.target.value] :  (newData[updateAnswerIndex].answer as string[]).filter((item:string) => item !== event.target.value)
                                                }
                                            }

                                            setDataCreateFeedback({
                                                ...dataCreateFeedback,
                                                value: newData
                                            })
                                        };
                                        const getDefaultValue = () => {
                                            const current = data?.answer?.find(data => data.check)?.value
                                            return current ?? ''
                                        }
                                        return (
                                            <Box key={data.question}>
                                                <Box sx={{ backgroundColor: "#21b8ce", textAlign: "center", padding: "0.5rem 0", marginBottom: "0.8rem" }}>
                                                    <Typography sx={{ fontSize: 18, color: "#ffffff" }}>
                                                        {data?.question}
                                                    </Typography>
                                                </Box>
                                                <FormGroup row defaultValue={getDefaultValue()} sx={{ display: 'flex', gap: '15px', flexWrap: 'wrap', paddingTop: 1, justifyContent: 'space-between' }} onChange={handleChange}>
                                                {
                                                    data?.answer?.map((item, answerIndex) => {
                                                        const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                            event.preventDefault()
                                                            const updateAnswerIndex = dataCreateFeedback.value?.findIndex(oldData => oldData.question === data.question)
                                                            const newData = [...dataCreateFeedback.value]
                                                            newData[updateAnswerIndex] = {
                                                                question: data.question,
                                                                answer: item?.value
                                                            }
                                                            setDataCreateFeedback({
                                                                ...dataCreateFeedback,
                                                                value: newData
                                                            })
                                                        };
                                                        if (item.type === 'radio')
                                                            return (
                                                                <FormControlLabel
                                                                    value={item?.value}
                                                                    checked={item.value === dataCreateFeedback?.value[index]?.answer}
                                                                    control={
                                                                        <Radio
                                                                            defaultChecked={item.check}
                                                                            sx={{
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 28,
                                                                            }
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={item?.value}
                                                                    key={answerIndex + '-' + item?.value}
                                                                    sx={{
                                                                        '& .MuiTypography-root': {
                                                                            fontSize: 25,
                                                                        },
                                                                    }}
                                                                />
                                                            )
                                                        if (item.type === 'checkbox')
                                                            return (
                                                                <FormControlLabel
                                                                    value={item?.value}
                                                                    control={
                                                                        <Checkbox
                                                                            defaultChecked={item.check}
                                                                            sx={{
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 28,
                                                                            }
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={item?.value}
                                                                    key={answerIndex + '-' + item?.value}
                                                                    sx={{
                                                                        '& .MuiTypography-root': {
                                                                            fontSize: 25,
                                                                        },
                                                                    }}
                                                                />
                                                            )
                                                        else if (item.type === 'button')
                                                            return (
                                                                <ChooseButton
                                                                    key={answerIndex + '-' + item?.value}
                                                                    variant={dataCreateFeedback.value[index]?.answer === item?.value ? 'contained' : 'outlined'} 
                                                                    onClick={handleClick}
                                                                >
                                                                        {item?.value}
                                                                </ChooseButton>
                                                            )
                                                        else if (item.type === 'text')
                                                            return (
                                                                <TextField
                                                                    variant='outlined' 
                                                                    sx={{ width: '100%' }} 
                                                                    key={answerIndex + '-' + item?.value}
                                                                />
                                                            )
                                                        else return <></>
                                                    })
                                                }
                                                </FormGroup>
                                            </Box>
                                        )
                                    })
                                }
                            </BoxFeedbacks>
                            <Box sx={{ marginTop: 1, display: 'flex', width: '100%', gap: 3, justifyContent: 'center', flexWrap: 'wrap' }}>
                                {
                                    getDataImage()?.map((img, index) => (
                                        <AvatarCamera
                                            alt={`Image Feedback ${index}`}
                                            variant="square"
                                            src={img}
                                            key={`Image Feedback ${index}`}
                                        />
                                    ))
                                }
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <ComplainButton color="error" variant="outlined" onClick={(e) => {
                                        e.preventDefault()
                                        setOpenModalScreenShot(true)
                                    }}
                                        disabled={handleCreateFeedback.isPending}
                                    >
                                        撮影する
                                    </ComplainButton>
                                </Box>
                            </Box>

                            <Box sx={{ marginTop: 3, display: 'flex', width: '100%', gap: 3, justifyContent: 'center' }}>
                                {
                                    <FeedbackButton
                                        variant='contained'
                                        onClick={(e) => {
                                        e.preventDefault();
                                        let clearDataValue: IRequestCreateFeedback["value"] = [];
                                        dataCreateFeedback.value.forEach((item) => {
                                            if (!!item.answer.length) clearDataValue.push(item);
                                        });
                                        handleCreateFeedback.mutate({
                                            feedback_id: dataCreateFeedback.feedback_id,
                                            user_id: dataCreateFeedback.user_id,
                                            value: clearDataValue,
                                        });
                                        }}
                                        disabled={handleCreateFeedback.isPending}

                                    >
                                        回答する
                                    </FeedbackButton>
                                }
                            </Box>
                                </Box>
                        </Box>
                ) : (
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <CircularProgress />
                    </Box>
                )
            }
            <Modal
                open={openModalScreenShot}
                onClose={() => {
                    setOpenModalScreenShot(false)
                }}
            >
                <CleanSystemScreenShot
                    onOk={() => {
                        setOpenModalScreenShot(false)
                    }}
                    setListImage={(listImg) => {
                        const indexListImage = dataCreateFeedback.value.findIndex(data => data.question === '汚れが気になる')
                        if (indexListImage === -1) {
                            setDataCreateFeedback({
                                ...dataCreateFeedback, value: [...dataCreateFeedback.value, {
                                    question: "汚れが気になる",
                                    answer: listImg
                                }]
                            })
                        } else {
                            const newValue = [...dataCreateFeedback.value]
                            newValue[indexListImage] = {
                                question: '汚れが気になる',
                                answer: listImg
                            }
                            setDataCreateFeedback({ ...dataCreateFeedback, value: newValue })
                        }
                    }}
                    listImage={dataCreateFeedback.value.find(data => data.question === '汚れが気になる')?.answer as string[] || []}
                />
            </Modal>
        </BoxWrapper>
    )
}

export default CleanSystemFeedback
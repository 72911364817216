import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosInstanceAuth } from "./client";
import { BaseResponse, ErrorResponse, IACreateNotification, IANotificationDetail, IAListNotifications,  } from "interfaces";
import { AxiosError } from "axios";

export const useAdminGetListNotifications = (params: {
	page: number;
	per: number;
	name: string;
}) => {
	return useQuery({
		queryKey: ["ADMIN_GET_LIST_NOTIFICATIONS", params.page, params.per, params.name],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(
				`/admin/messages/filtering?page=${params.page}&per=${params.per}&value=${params.name}`
			);
			return res as BaseResponse<IAListNotifications>;
		},
		enabled: !!params.page && !!params.per,
	});
};

export const useAdminGetDetailNotification = (id: number) => {
	return useQuery({
		queryKey: ["ADMIN_GET_DETAIL_NOTIFICATION", id],
		queryFn: async () => {
			const res = await axiosInstanceAuth.get(`/admin/messages/${id}`);
			return res as BaseResponse<IANotificationDetail>;
		},
		enabled: !!id,
	});
};

export const useAdminCreateNotification = (
	onOk?: (res: BaseResponse) => void,
	onErr?: (err: AxiosError<ErrorResponse>) => void
) => {
	return useMutation({
		mutationKey: ["ADMIN_CREATE_NOTIFICATION"],
		mutationFn: ( body : IACreateNotification) =>
			axiosInstanceAuth.post("/admin/messages", body),
		onSuccess: (res) => {
			onOk && onOk(res as BaseResponse);
		},
		onError: (err) => {
			onErr && onErr(err as AxiosError<ErrorResponse>);
		},
	});
};

export const useAdminUpdateNotification = (
	onOk?: (res: BaseResponse) => void,
	onErr?: (err: AxiosError<ErrorResponse>) => void
) => {
	return useMutation({
		mutationKey: ["ADMIN_UPDATE_NOTIFICATION"],
		mutationFn: ({ id, body }: { id : number, body : IACreateNotification }) =>
			axiosInstanceAuth.patch(`/admin/messages/${id}`, body),
		onSuccess: (res) => {
			onOk && onOk(res as BaseResponse);
		},
		onError: (err) => {
			onErr && onErr(err as AxiosError<ErrorResponse>);
		},
	});
};
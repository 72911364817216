import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";

interface CardLayoutComponentProps {
	children: React.ReactElement;
	title: string;
	secondary?: boolean;
}

const CardLayoutComponent = ({
	children,
	title,
	secondary,
}: CardLayoutComponentProps) => {
	return (
		<Card
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				borderRadius: 2,
			}}
		>
			<CardHeader
				titleTypographyProps={{
					component: Typography,
					variant: "inherit",
				}}
				title={title}
				sx={{
					backgroundColor: secondary ? "#21BECE" : "primary",
				}}
			/>
			<CardContent
				sx={{
					textAlign: "center",
					backgroundColor: secondary ? "#E1F4FC" : "primary",
					flex: 1,
				}}
			>
				{children}
			</CardContent>
		</Card>
	);
};

export default CardLayoutComponent;

import React from 'react'
import { Box, Typography } from "@mui/material"
import { styled } from '@mui/material/styles';
import { User } from 'interfaces';
import AvatarDefault from 'components/utils/AvatarDefault'

const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    gap: 5,
    alignItems: 'center'
});

interface CleanSystemWelcomeProps {
    currentUser?: User
}
const CleanSystemWelcome = ({ currentUser }: CleanSystemWelcomeProps) => {
    return (
        <Box sx={{ display: 'flex', gap: 1, margin: 'auto', justifyContent: 'left', alignItems: 'center' }}>
            <BoxWrapper>
                <Box display="flex" alignItems="center" sx={{ margin: '2rem 0 2rem 0' }} >
                    <AvatarDefault width={150} height={150} src={currentUser?.image} text={currentUser?.nickname?.charAt(0)} />
                    <Box marginLeft={4}>
                        <Typography variant='inherit' component="div">
                            <Typography component="span" sx={{ fontSize: '2.5rem', fontWeight: 800, color: "#0a97d9" }}>{currentUser?.nickname}</Typography>
                            <Typography component="span" sx={{ marginLeft: "0.8rem", fontSize: '1.8rem', color: "#0a97d9" }}>さん</Typography>
                            <Typography sx={{ fontSize: '2.5rem', fontWeight: 800, color: "#0a97d9" }}>ありがとう！</Typography>
                        </Typography>
                    </Box>
                </Box>
            </BoxWrapper>
        </Box>
    )
}

export default CleanSystemWelcome

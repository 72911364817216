import { useCallback, useContext } from 'react';
import { useGetEvents } from 'lib/api/event';
import { AuthContext } from "App"

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IEventHome } from 'interfaces'
import CircularProgressBox from 'components/utils/CircularProgressBox'
import { formatNumber } from 'lib/common/string.helper'

const TextTitle = styled(Typography)({
    fontWeight: 400,
    fontSize: '1.8rem'
});

const TextDescription = styled(Typography)({
    marginTop: '0',
    fontWeight: 700,
    fontSize: '3.3rem',
});

export default function UserWashBottle() {
    const { user } = useContext(AuthContext)
    const { data, isLoading } = useGetEvents(user?.data?.id, 10)

    const currentEvent: () => IEventHome | null = useCallback(() => {
        const currentData = data?.data?.find(e => e.code.includes('all'))
        if (currentData?.id) {
            return currentData
        } else
            return null
    }, [data?.data])

    const BoxAvatar = styled(Box)(() => ({
        width: '100%',
        paddingBottom: '100%',
        backgroundImage: `url(${currentEvent()?.image.value})`,
        backgroundSize: '100%',
        backgroundPosition: 'center',
        marginBottom: '2rem'
    }));

    return (
        <>
            {
                isLoading ? (
                    <CircularProgressBox />
                ) : !!data?.data?.length && (
                    <Box sx={{
                        typography: 'body1',
                        margin: '0 auto',
                        paddingTop: '3rem'
                    }}>
                        <BoxAvatar />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {
                                currentEvent()?.content?.map((content, index) => (
                                    <Box key={`${content}-${index}`}>
                                        <TextTitle paddingTop={index === 0 ? '2rem' : '1rem'} align='center'>{content.title}</TextTitle>
                                        <TextDescription align='center'>{formatNumber(content.value)}{content.unit}</TextDescription>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                )
            }
        </>
    );
}
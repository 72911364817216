import client, { axiosInstanceAuth } from "./client"
import Cookies from "js-cookie"
import { SignUpParams, SignInParams, IResponseCurrentUser, BaseResponse, ErrorResponse, User, IForgotPasswordLoginId } from "interfaces"
import { useMutation } from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"
import { useContext } from "react"
import { AlertContext } from "App"
import dayjs from 'dayjs';
import { webUrl } from "components/utils/constants/AppUrl"

// サインアップ（新規アカウント作成）
export const signUp = (params: SignUpParams) => {
  return client.post("auth", params)
}

// サインイン（ログイン）

export const useSignIn = (onOk?: (data:User) => void , onErr?:Function) => {
  const { setModalAlert } = useContext(AlertContext)

  return useMutation({
      mutationKey: ['SIGN IN'],
      mutationFn: (params: SignInParams) => client.post("auth/sign_in", params),
      onSuccess: (res: AxiosResponse<{data : User}>) => {
          const now = dayjs();
          const futureTime = dayjs.unix(Number(res.headers["expiry"]));
          const dayExpires = futureTime.diff(now, 'day', true)
          Cookies.set("_access_token", res.headers["access-token"], {
            expires: dayExpires
          })
          Cookies.set("_client", res.headers["client"], {
            expires: dayExpires
          })
          Cookies.set("_uid", res.headers["uid"], {
            expires: dayExpires
          })
          onOk && onOk(res?.data?.data)
      },
      onError: (err: AxiosError<ErrorResponse>) => {
        setModalAlert({ isOpen: true, message: err.response?.data?.errors || '', type: 'error' });
        onErr && onErr()
      }
  })
}
export const useSignUp = (onOk?: Function , onErr?: (err: AxiosError<ErrorResponse>) => void) => {
  const { setModalAlert } = useContext(AlertContext)
  return useMutation({
  mutationKey: ['SIGN_UP_ACCOUNT'],
  mutationFn: (params: SignUpParams) => signUp(params),
  onSuccess: (res: any) => {
    setModalAlert({ isOpen: true, message: 'アカウント登録が成功しました', type: 'success' })
    Cookies.set("_access_token", res.headers["access-token"])
    Cookies.set("_client", res.headers["client"])
    Cookies.set("_uid", res.headers["uid"])
    onOk && onOk()
  },
  onError: (err: AxiosError<ErrorResponse>) => {
    onErr && onErr(err)
  }})
}

// サインアウト（ログアウト）
export const signOut = () => {
  return client.delete("auth/sign_out", { headers: {
    "access-token": Cookies.get("_access_token"),
    "client": Cookies.get("_client"),
    "uid": Cookies.get("_uid")
  }})
}
export const useSignOut = (onOk?: Function , onErr?:Function) => {
  const { setModalAlert } = useContext(AlertContext)
  return useMutation({
  mutationKey: ['SIGN_OUT_ACCOUNT'],
  mutationFn: () => signOut(),
  onSuccess: (res: any) => {
    onOk && onOk()
  },
  onError: (err: AxiosError<ErrorResponse>) => {
    setModalAlert({ isOpen: true, message: (err?.response?.data?.errors as any)?.message || 'エラー', type: 'error' })
    onErr && onErr()
  }})
}

// 認証済みのユーザーを取得
export const getCurrentUser = ():Promise<IResponseCurrentUser> => {
  return axiosInstanceAuth.get("/auth/sessions")
}
export const sendRequestForgot = (body :{email: string, redirect_url: string}):Promise<any> => {
  return axiosInstanceAuth.post("/auth/password" , body)
}
export const useSendRequestForgot = (onOk?: (res: BaseResponse) => void, onErr?: (err: AxiosError<ErrorResponse>) => void) => {
  return useMutation({
    mutationKey: ['SEND_REQUEST_MUTATION'],
    mutationFn: (email: string) => sendRequestForgot({ email, redirect_url: webUrl + '/forgot-password/' }),
    onSuccess: (res: BaseResponse) => {
      onOk && onOk(res)
    },
    onError: (err:AxiosError<ErrorResponse>) => {
        onErr && onErr(err)
    }
  })
}

export const checkCusIdTag = (body :{customer_id: string, code: string}):Promise<any> => {
  return axiosInstanceAuth.get(`/user_assets/exists?customer_id=${body.customer_id}&code=${body.code}`)
}
export const useCheckCustomerIdTag = (onOk?: (res: BaseResponse) => void, onErr?: (err: AxiosError<ErrorResponse>) => void) => {
  return useMutation({
    mutationKey: ['CHECK_CUSTOMER_ID_AND_TAG_MUTATION'],
    mutationFn: (body: {customer_id: string, code: string}) => checkCusIdTag(body),
    onSuccess: (res: BaseResponse) => {
      onOk && onOk(res)
    },
    onError: (err: AxiosError<ErrorResponse>) => {
        onErr && onErr(err)
    }
  })
}
export const changePassword = (body :{password: string, password_confirmation: string, config: any}):Promise<any> => {
  return axiosInstanceAuth.put("/auth/password" , {password: body.password, password_confirmation: body.password_confirmation}, {
    headers: body.config
  })
}
export const useChangePassword = (onOk?: (res: BaseResponse) => void, onErr?: (err: AxiosError<ErrorResponse>) => void) => {
  return useMutation({
    mutationKey: ['CHANGE_PASSWORD_MUTATION'],
    mutationFn: ({ password, password_confirmation, config }: { password: string, password_confirmation: string, config: any }) => changePassword({ password, password_confirmation, config }),
    onSuccess: (res: BaseResponse) => {
      onOk && onOk(res)
    },
    onError: (err: AxiosError<ErrorResponse>) => {
        onErr && onErr(err)
    }
  })
}

export const resetPassword = (body :IForgotPasswordLoginId):Promise<any> => {
  return axiosInstanceAuth.patch("users/forgot_password" , body)
}
export const useResetPassword = (onOk?: (res: BaseResponse) => void, onErr?: (err: AxiosError<ErrorResponse>) => void) => {
  return useMutation({
    mutationKey: ['RESET_PASSWORD_MUTATION'],
    mutationFn: (body: IForgotPasswordLoginId) => resetPassword(body),
    onSuccess: (res: BaseResponse) => {
      onOk && onOk(res)
    },
    onError: (err: AxiosError<ErrorResponse>) => {
        onErr && onErr(err)
    }
  })
}


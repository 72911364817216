import { useQuery, useMutation } from '@tanstack/react-query';
import { BaseResponse, ErrorResponse, IFeedback,IRequestCreateFeedback } from "interfaces"
import { axiosInstanceAuth } from "./client"
import { AxiosError } from "axios";

export const useGetFeedbacks = (feedbackTypeCode: string) => {
    return useQuery({
        queryKey: ['GET FEEDBACKS'],
        queryFn: async () =>{
            const res = await axiosInstanceAuth.get(`/feedbacks?feedback_type_code=${feedbackTypeCode}`)
            return res as BaseResponse<IFeedback[]>
        },
    })
}

export const useCreateFeedback = (onOk?: Function , onErr?:Function) => {
    return useMutation({
        mutationKey: ['CREATE FEEDBACK'],
        mutationFn: (data: IRequestCreateFeedback) => axiosInstanceAuth.post(`/users_feedbacks`, data),
        onSuccess: (res) => {
            onOk && onOk(res)
        },
        onError: (err: AxiosError<ErrorResponse>) => {
            onErr && onErr(err)
        }
    })
}

export const useGetDataFeedbacks = (onOk?: Function , onErr?:Function) => {
    return useMutation({
        mutationKey: ['GET DATA FEEDBACKS'],
        mutationFn: ({startDate, endDate} : {startDate: string, endDate: string}) => 
            axiosInstanceAuth.get(`/users_feedbacks/export?start_date=${startDate}&end_date=${endDate}`, { responseType: 'blob' }),
        onSuccess: (res) => {
            onOk && onOk(res)
        },
        onError: (err: AxiosError<ErrorResponse>) => {
            onErr && onErr(err)
        }
    })
}
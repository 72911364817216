import React from "react";
import { Container, Grid, Box } from "@mui/material";

import { Breakpoint, styled } from "@mui/material/styles";
import Header from "./Header";
import Footer from "./Footer";

interface CommonLayoutProps {
	children: React.ReactElement;
	noMarginTop?: boolean;
	noPaddingTop?: boolean;
	maxWidthSafezone?: Breakpoint | false;
	backgroundColor?: string;
}

const CommonLayout = ({
	children,
	noMarginTop,
	maxWidthSafezone,
	backgroundColor,
	noPaddingTop,
}: CommonLayoutProps) => {
	const StyledContainer = styled(Container)({
		padding: 0,
		marginTop: noMarginTop ? 0 : "1rem",
	});
	const MainBox = styled(Box)(({ theme }) => ({
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: "0%",
		display: "grid",
		flexDirection: "column",
		padding: "0.5rem 6px",
		height: "100%",
		[theme.breakpoints.up("sm")]: {
			padding: "0 2rem 0 2rem",
		},
		[theme.breakpoints.up("md")]: {
			padding: "2rem 4rem 2rem 4rem",
		},
		[theme.breakpoints.up("lg")]: {
			padding: "3rem 6rem 3rem 6rem",
			paddingTop: noPaddingTop ? 0 : "3rem",
		},
	})) as typeof Box;
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					minHeight: "100vh",
					backgroundColor: backgroundColor ?? undefined,
				}}
			>
				<Header />
				<MainBox component="main">
					<StyledContainer maxWidth={maxWidthSafezone ?? "lg"}>
						<Grid container justifyContent="center" height="100%">
							<Grid item width="100%">
								{children}
							</Grid>
						</Grid>
					</StyledContainer>
				</MainBox>
				<Footer />
			</Box>
		</>
	);
};

export default CommonLayout;

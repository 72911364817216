import { useContext, useState } from 'react'
import CreateSustainabilityStep1 from './Step1';
import { IACreateSustainability } from 'interfaces';
import { handleChangeStateImage, handleChangeStateInput, handleChangeStateSelect } from 'lib/common/handle-change-state';
import { useNavigate } from 'react-router-dom';
import { AppUrl } from 'components/utils/constants/AppUrl';
import CreateSustainabilityStep2 from './Step2';
import CreateSustainabilityStep3 from './Step3';
import AlertMessage from 'components/utils/AlertMessage';
import { useAdminCreateSustainability } from 'lib/api/admin-sustainability';
import { AlertContext } from 'App';

const CreateSustainability = () => {
  const [step, setStep] = useState<1 | 2 | 3>(1)
	const navigate = useNavigate();
  const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({ isOpen: false, message: "", type: "success" });
  const { setModalAlert:setModalAlertGlobal } = useContext(AlertContext)

  const [dataCreate, setDataCreate] = useState<IACreateSustainability>({
    attribute_id: 0,
    code: "",
    name: "",
    mission: {
      factor: 0,
      unit: "",
      content: [
        {
          title: "",
          unit: "",
        },
        {
          title: "",
          unit: "",
        },
      ],
    },
    sustainability_successes_attributes: [
      {
        achievement: 0,
        stage: 0,
        image_attributes: {
          is_login: true,
          mime: "",
          name: "",
          value: "",
        },
        sustainability_success_coupons_attributes: [],
      }
    ],
  })

  const handleAdminCreateSustainability = useAdminCreateSustainability(
    (res) => {
			setModalAlertGlobal({ isOpen: true, message: res?.message, type: "success" });
			navigate(AppUrl.SUSTAINABILITY_MANAGEMENT);
		},
		(err) => {
			setModalAlert({
				isOpen: true,
				message: err?.response?.data?.error?.messages || "エラー",
				type: "error",
			});
    }
  )

  const handleSubmit = () => {
    if(step !== 3){
      setStep(prevState => (prevState + 1) as 1 | 2 | 3 )
    } else {
      handleAdminCreateSustainability.mutate({...dataCreate, mission: {
        ...dataCreate.mission,
        factor: Number(dataCreate.mission.factor)
      }})
    }
  }
  const handleBack = () => {
    if(step === 1){
      navigate(AppUrl.SUSTAINABILITY_MANAGEMENT)
    } else setStep(prevState => (prevState - 1) as 1 | 2 )
  }
  const handleAddContent = () => {
    setDataCreate(prevState => ({
      ...prevState,
      mission: {
        ...prevState.mission,
        content: [
          ...prevState.mission.content,
          {
            title: "",
            unit: "",
          }
        ]
      }
    }))
  }
  const handleAddAchievement = () => {
    setDataCreate(prevState => ({
      ...prevState,
      sustainability_successes_attributes: [
        ...prevState.sustainability_successes_attributes,
        {
          achievement: undefined as any,
          stage: prevState.sustainability_successes_attributes.length,
          image_attributes: {
            is_login: true,
            mime: "",
            name: "",
            value: "",
          },
          sustainability_success_coupons_attributes: [],
        }
      ]
    }))
  }

  return (
    <>
      {
        step === 1 ? (
          <CreateSustainabilityStep1
            onSubmit={handleSubmit}
            onChange={(e, customValue) => handleChangeStateInput(setDataCreate)(e as any, customValue)}
            onChangeSelect={handleChangeStateSelect(setDataCreate)}
            data={dataCreate}
            onBack={handleBack}
            onAddContent={handleAddContent}
            isEdit={true}
          />
        ) : step === 2 ? (
          <CreateSustainabilityStep2
            data={dataCreate}
            onBack={handleBack}
            onChange={(e, customValue) => handleChangeStateInput(setDataCreate)(e as any, customValue)}
            onChangeSelect={handleChangeStateSelect(setDataCreate)}
            onChangeImage={handleChangeStateImage(setDataCreate)}
            onAddAchievement={handleAddAchievement}
            onSubmit={handleSubmit}
            isEdit={true}
            setModalAlert={setModalAlert}
          />
        ) : step === 3 ? (
          <CreateSustainabilityStep3
            onSubmit={handleSubmit}
            data={dataCreate}
            onBack={handleBack}
            isLoading={handleAdminCreateSustainability.isPending}
          />
        ) : <></>
      }
      <AlertMessage
				open={modalAlert.isOpen}
				setOpen={(open: boolean) =>
					setModalAlert({ isOpen: open, message: "", type: "warning" })
				}
				severity={modalAlert.type}
				message={modalAlert.message}
			/>
    </>
  )
}

export default CreateSustainability

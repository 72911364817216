import {
	Box,
	Button,
	IconButton,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { ICreateSurvey, IFeedbackType, ISurvey } from "interfaces";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";

const TitleTypography = styled(Typography)(({ theme }) => ({
	textAlign: "left",
}));

const BoxItem = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: 10,
	marginBottom: 1,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
	width: 180,
	height: 60,
	fontSize: 20,
	borderRadius: 2,
	margin: "10px auto",
}));

interface ICreateSurveyStep2Props {
	onSubmit: (surveyData: ISurvey) => void;
	surveyData?: ISurvey;
	feedbackType?: IFeedbackType;
}

const CreateSurveyStep2 = ({
	onSubmit,
	surveyData,
	feedbackType,
}: ICreateSurveyStep2Props) => {
	const [code, setCode] = useState("");
	const [name, setName] = useState("");
	const [startDate, setStartDate] = useState<Dayjs | null>(null);
	const [endDate, setEndDate] = useState<Dayjs | null>(null);
	const [dataCreate, setDataCreate] = useState<ICreateSurvey[]>(
		feedbackType?.value?.map((item) => ({
			question: "",
			answer: [
				{
					type: item.type,
					value: "",
				},
			],
			answer_limit: item.answerLimit || 0,
		})) || []
	);
	const [isTimeError, setIsTimeError] = useState({
		startDate: false,
		endDate: false,
	});
	useEffect(() => {
		if (surveyData?.code) {
			setDataCreate([...surveyData.value]);
			setName(surveyData.name);
			setCode(surveyData.code);
			setStartDate(dayjs(surveyData.feedback_status_attributes.start));
			setEndDate(dayjs(surveyData.feedback_status_attributes.end));
		}
	}, [surveyData]);
	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={1}
			component="form"
			onSubmit={(e) => {
				e.preventDefault();
				if (!startDate) {
					setIsTimeError({
						endDate: isTimeError.endDate,
						startDate: true,
					});
				} else if (!endDate) {
					setIsTimeError({
						startDate: isTimeError.startDate,
						endDate: true,
					});
				} else if (feedbackType?.id) {
					onSubmit({
						name,
						code,
						value: dataCreate,
						feedback_status_attributes: {
							start: dayjs(startDate).format("YYYY-MM-DD"),
							end: dayjs(endDate).format("YYYY-MM-DD"),
							feedback_type_id: feedbackType.id,
						},
					});
				}
			}}
		>
			<Typography sx={{ fontSize: 20, color: "#1976D2", fontWeight: "bold" }}>
				「{surveyData?.name}」作成
			</Typography>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoContainer components={["DatePicker"]}>
					<Box display="flex" gap={3}>
						<BoxItem>
							<TitleTypography>認識Code</TitleTypography>
							<TextField
								name="code"
								required
								placeholder="アンケートのコード"
								value={code}
								onChange={(e) => setCode(e.target.value)}
							/>
						</BoxItem>
						<BoxItem>
							<TitleTypography>アンケート名</TitleTypography>
							<TextField
								name="name"
								required
								placeholder="アンケート名"
								sx={{ width: 400 }}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</BoxItem>
						<BoxItem>
							<TitleTypography>開始日</TitleTypography>
							<DatePicker
								name="startDate"
								value={startDate}
								onChange={(e: Dayjs | null) => {
									setStartDate(e);
								}}
								disablePast={true}
								slotProps={{
									textField: {
										error: isTimeError.startDate,
										helperText:
											isTimeError.startDate && "開始日を入力してください",
									},
								}}
							/>
						</BoxItem>
						<BoxItem>
							<TitleTypography>終了日</TitleTypography>
							<DatePicker
								name="endDate"
								value={endDate}
								onChange={(e: Dayjs | null) => {
									setEndDate(e);
								}}
								disablePast={true}
								slotProps={{
									textField: {
										error: isTimeError.endDate,
										helperText:
											isTimeError.endDate && "終了日を入力してください",
									},
								}}
							/>
						</BoxItem>
					</Box>
				</DemoContainer>
			</LocalizationProvider>
			{dataCreate?.map((item, index) => {
				const valueCheckTrue = (item: ICreateSurvey) => {
					return item.answer.find((c) => c?.check)
						? item.answer.find((c) => c.check)?.value
						: "";
				};
				return (
					<Box
						key={`Question Key ${index}`}
						display="flex"
						flexDirection="column"
						gap={1}
					>
						<TitleTypography>設問{index + 1}</TitleTypography>
						<TextField
							name={`Question ${index}`}
							required
							fullWidth
							placeholder={`設問${index + 1}を入力してください`}
							value={item.question}
							onChange={(e) => {
								let cloneDataCreate = [...dataCreate];
								cloneDataCreate[index].question = e.target.value;
								setDataCreate(cloneDataCreate);
							}}
						/>
						<Box
							display={
								dataCreate.length - 1 === index ||
								(feedbackType?.value[index].type &&
									feedbackType?.value[index].type === "text")
									? "none"
									: "flex"
							}
							flexWrap="wrap"
							gap={2}
						>
							{item.answer?.map((answerItem, answerItemIndex) => (
								<TextField
									name={`Answer ${index}-${answerItemIndex}`}
									key={`Answer Key ${item.question}-${index}-${answerItemIndex}`}
									placeholder={`答え${answerItemIndex + 1}を入力してください`}
									required={answerItem.type && answerItem.type !== "text"}
									value={answerItem.value}
									inputProps={{
										maxLength: feedbackType?.value[index]?.limitText,
									}}
									InputProps={{
										endAdornment: (
											<IconButton
												aria-label="minus"
												onClick={() => {
													let cloneDataCreate = [...dataCreate];
													cloneDataCreate[index].answer.splice(
														answerItemIndex,
														1
													);
													setDataCreate(cloneDataCreate);
												}}
											>
												<RemoveCircleOutline
													sx={{ width: 25, height: 25, color: "red" }}
												/>
											</IconButton>
										),
									}}
									onChange={(e) => {
										let cloneDataCreate = [...dataCreate];
										cloneDataCreate[index].answer[answerItemIndex].value =
											e.target.value;
										setDataCreate(cloneDataCreate);
									}}
								/>
							))}
							<IconButton
								aria-label="add"
								size="large"
								onClick={() => {
									let cloneDataCreate = [...dataCreate];
									cloneDataCreate[index].answer.push({
										type: feedbackType?.value[index].type || "",
										value: "",
									});
									setDataCreate(cloneDataCreate);
								}}
							>
								<AddCircleOutline
									color="primary"
									sx={{ width: 30, height: 30 }}
								/>
							</IconButton>
							<Box display="flex" alignItems="center" gap={2}>
								<Typography>デフォルトチェック</Typography>
								<Select
									name={`Select check question ${index}`}
									value={valueCheckTrue(item) ?? ""}
									sx={{ width: 200 }}
									size="small"
									required={
										dataCreate.length - 1 !== index &&
										feedbackType?.value[index].type &&
										feedbackType?.value[index].type !== "text"
									}
								>
									{item.answer.map((answerItem, indexSelect) => (
										<MenuItem
											value={answerItem.value}
											key={`Select Key ${index}-${indexSelect}`}
											onClick={(e) => {
												let cloneDataCreate = [...dataCreate];
												const indexKeyFind = cloneDataCreate[
													index
												].answer.findIndex(
													(item) => item.value === answerItem.value
												);
												let customDataAnswer = cloneDataCreate[
													index
												].answer?.map((dataChild, childIndex) => {
													if (childIndex !== indexKeyFind)
														return {
															type: dataChild.type,
															value: dataChild.value,
														};
													else {
														return {
															...dataChild,
															check: true,
														};
													}
												});
												cloneDataCreate[index].answer = customDataAnswer;
												setDataCreate(cloneDataCreate);
											}}
										>
											{answerItem.value}
										</MenuItem>
									))}
								</Select>
							</Box>
						</Box>
					</Box>
				);
			})}
			<SubmitButton variant="contained" type="submit">
				プレビュー
			</SubmitButton>
		</Box>
	);
};

export default CreateSurveyStep2;

import React from 'react'
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

interface AvatarDefaultProps {
    height?: number
    width?: number
    src?: string
    text?: string
}

const AvatarDefault = ({ height = 100, width = 100, src, text }: AvatarDefaultProps) => {
    const AvatarComponent = styled(Avatar)({
        height,
        width,
        '& .MuiAvatar-img': {
            objectFit: 'cover',
        }
    });
    return (
        <AvatarComponent src={src || ''}>
            {text || ''}
        </AvatarComponent>
    )
}

export default AvatarDefault

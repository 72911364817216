export function fileToBase64(file:File): Promise<string|null> {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
    reader.onload = () => { 
      const base64String = reader.result as string
      resolve(base64String)
    };
    reader.onerror = () => {
      reject(new Error('エラー：ファイルの読み取り'))
    };
    reader.readAsDataURL(file);
    })
 }
interface Params {
  [key: string]: string | null;
}

export function queryStringToObject(queryString: string): Params {
  const params: Params = {};

  const queryStringWithoutQMark = queryString.indexOf('?') !== -1 ? queryString.split('?')[1] : queryString;

  queryStringWithoutQMark.split('&').forEach(function (pair) {
    const keyValue = pair.split('=');
    const key = decodeURIComponent(keyValue[0]);
    const value = keyValue.length > 1 ? decodeURIComponent(keyValue[1]) : null;
    params[key] = value;
  });

  return params;
}
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { useGetListNotification } from 'lib/api/notification';
import { formatUTCTime } from 'lib/common/date.helper'

import { INotification } from 'interfaces'
import { Box, Typography, Grid, FormControl, Select, InputAdornment, MenuItem, Pagination } from "@mui/material"
import CircularProgressBox from 'components/utils/CircularProgressBox'
import { styled } from '@mui/material/styles';

const BoxWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center',
    margin: '1rem auto 0 auto',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
        width: 500,
    },
}));

const BoxUsers = styled(Box)({
    display: "flex",
    flexDirection: 'column',
    gap: 5,
    width: '100%',
    margin: '10px 0px',
    backgroundColor: '#c1e2f6',
    padding: '1rem 1rem 0.4rem 1rem',
});

const GridUser = styled(Grid)({
    width: '100%',
});
const Notification = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page: number = Number(searchParams.get('page')) || 1;
    const per: number = Number(searchParams.get('per')) || 25;
    const { data, isLoading, isError } = useGetListNotification({ page, per })

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString());
        setSearchParams(searchParams);
    };

    return (
        <>
            {
                isLoading ? (
                    <CircularProgressBox />
                ) : !isError ? (
                    <>
                        {
                            data?.data?.data?.length ? (
                                <>
                                    <Box display="flex" justifyContent="flex-end">
                                        <FormControl sx={{ width: "140px", marginLeft: "auto", backgroundColor: '#ffffff' }}>
                                            <Select
                                                value={per}
                                                onChange={(e) => {
                                                    searchParams.set("per", e.target.value.toString());
                                                    searchParams.set("page", '1');
                                                    setSearchParams(searchParams);
                                                }}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Typography variant="inherit" style={{ fontSize: '0.8rem' }}>
                                                            表示件数
                                                        </Typography>

                                                    </InputAdornment>
                                                }
                                            >
                                                <MenuItem value={25}>25</MenuItem>
                                                <MenuItem value={50}>50</MenuItem>
                                                <MenuItem value={100}>100</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <BoxWrapper>
                                        <BoxUsers>
                                            {data?.data?.data?.length ? data?.data?.data?.map((item: INotification) => (
                                                <GridUser container spacing={0.5} key={item?.id}>
                                                    <Grid item xs={3} sx={{ marginBottom: '1rem' }}>
                                                        <Typography sx={{ fontSize: "0.8rem" }}>{formatUTCTime(item.publishDate)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={9} sx={{ marginBottom: '1rem' }}><Typography sx={{ textDecoration: item.link ? 'underline' : 'none' }} onClick={() => {
                                                        if (item.link) {
                                                            window.open(item.link, '_blank')
                                                        }
                                                    }}>{item.value}</Typography></Grid>
                                                </GridUser>
                                            )) : (
                                                <Box sx={{ margin: '50px 0px' }}>
                                                    <Typography sx={{ textAlign: 'center', marginBottom: '1rem' }}>新しいおしらせはありません​</Typography>
                                                </Box>
                                            )
                                            }
                                        </BoxUsers>
                                        <Pagination page={Number(page)} count={data?.data?.totalPages} onChange={handleChangePage} siblingCount={1} boundaryCount={0}/>
                                    </BoxWrapper>
                                </>
                            ) : (
                                <Box sx={{ margin: '50px 0px' }}>
                                    <Typography sx={{ textAlign: 'center' }}>新しいおしらせはありません</Typography>
                                </Box>
                            )
                        }
                    </>

                ) :
                    <>
                        <h1>Error</h1>
                    </>
            }
        </>
    )
}

export default Notification